import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import HomeOwnerImg from "../../Images/Home/home-owner.png";

const PropertyListFind = () => {
  const [userId, setuserId] = useState();

  // Get Local Storage Data
  useEffect(() => {
    setuserId(localStorage.getItem("UserId"));
  }, []);

  return (
    <section className="Property-type-boxes px-3 pt-5">
      {/* <Container className="pt-4"> */}
      <Container
        maxWidth="xxl"
        className="p-lg-4 p-md-2"
        style={{ backgroundColor: "#02C988", borderRadius: "6px" }}
      >
        <Grid container className="justify-content-between" spacing={2}>
          <Grid item className="Property-type-boxesboxWrapper" sm={5.8} xs={12}>
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <CardContent className="pb-0">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                  className="text-center text-white"
                >
                  Find Property
                </Typography>
                <Typography
                  variant="body1"
                  component="h6"
                  className="text-center text-white"
                  style={{ fontsize: "16px" }}
                >
                  Select from thousand options, without brokerage
                </Typography>
                <Box className="Property-type-boxes-btn-wrapper mt-4 text-center">
                  <Link to="/properties/list/all">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1F4069",
                        color: "#FFF",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                      className="px-4 py-2"
                    >
                      Find Now
                    </Button>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item className="d-md-block d-none">
            <div className="Property-list-find-verticle-line"></div>
          </Grid>
          <Grid item className="Property-type-boxesboxWrapper" sm={5.8} xs={12}>
            <Card
              className="mx-auto"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <CardContent className="">
                <Typography
                  variant="subtitle1"
                  component="h5"
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                  className="text-center text-white"
                >
                  List Your Property
                </Typography>
                <Typography
                  variant="body1"
                  component="h6"
                  className="text-center text-white"
                  style={{ fontsize: "16px" }}
                >
                  For Free, without any brokerage
                </Typography>
                <Box className="Property-type-boxes-btn-wrapper mt-4 text-center">
                  <Link
                    to={
                      userId !== undefined && userId !== null
                        ? "/add-property"
                        : "/signup-login"
                    }
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1F4069",
                        color: "#FFF",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                      className="px-4 py-2"
                    >
                      Free Posting
                    </Button>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PropertyListFind;
