import { Button, Card, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { postApihandler } from "../../Apihandler";
import styles from "../../Css/contact.module.css";
export default function ContactForm() {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (value) => {
    console.log("values-->", value);
    const response = await postApihandler("/contactInfo", value);
    console.log("response-->", response);
    if (response.status === 200) {
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Contact Details Sent Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
    } else {
      swal("Sorry!", `${response.error.response.data.message}`, "error").then(
        (value) => {}
      );
    }
  };
  return (
    <div style={{}} className={styles.form_outerdiv}>
      <Card className={styles.form_card}>
        <div>
          <Typography className={styles.form_heading}>Contact Now!</Typography>
          <Typography className={styles.form_text}>
            We will get in touch with you, Please submit the form!!
          </Typography>
          <form className={styles.from} onSubmit={handleSubmit(onSubmit)}>
            <Typography>Full Name</Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Name"
              variant="outlined"
              sx={{ paddingBottom: "1.5rem", paddingTop: "0.3rem" }}
              name="fullName"
              {...register("fullName")}
            />
            <Typography>Email</Typography>
            <TextField
              fullWidth
              type="email"
              id="outlined-basic"
              placeholder="Email Address"
              variant="outlined"
              sx={{ paddingBottom: "1.5rem", paddingTop: "0.3rem" }}
              name="email"
              {...register("email")}
            />
            <Typography>Mobile Number</Typography>
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              placeholder="Mobile"
              variant="outlined"
              sx={{ paddingBottom: "1.5rem", paddingTop: "0.3rem" }}
              name="mo_number"
              {...register("mo_number")}
            />
            <Typography>Message</Typography>
            <TextField
              fullWidth
              type="text"
              id="outlined-basic"
              placeholder="Name"
              variant="outlined"
              sx={{ paddingBottom: "1.5rem", paddingTop: "0.3rem" }}
              name="message"
              {...register("message")}
            />
            <Button fullWidth className={styles.form_button} type="submit">
              Submit
            </Button>
          </form>
        </div>
      </Card>
    </div>
  );
}
