import React from "react";
import Banner from "../../Components/Userprofile/banner";
import ChangePasswordComponent from "../../Components/Userprofile/changepassword";
import ProfileDetail from "../../Components/Userprofile/profiledetail";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";

export default function UserProfileChangePassword() {
  return (
    <Layout>
      <Banner />
      <ChangePasswordComponent />
      <PartnersList />
    </Layout>
  );
}
