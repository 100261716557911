import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  
  useEffect(() => {
    localStorage.setItem("activatedPage", "privacy-policy");
  }, []);

  return (
    <Layout>
      <div className="py-md-5 my-5 px-md-5 px-4">
        <Typography variant="h4" className="fw-semibold pt-5">
          Privacy Policy
        </Typography>
        <br />
        <Typography variant="h6" className="fw-semibold">
          Objective, Scope and Applicability of the Policy
        </Typography>
        <Typography variant="p" className="text-start">
          The privacy policy (“Policy”) is applicable to the online interfaces
          of Gharaaj Technologies Pvt Ltd. (“Gharaaj”, “us”, “we” or “our”)
          including www.Gharaaj.com, the mobile site/applications (including but
          not limited to the Gharaaj, Milo, or any other app) for iOS, Android,
          or any other windows operating system (collectively referred to as
          “Site”). The purpose of this Policy is to describe how Gharaaj
          collects, uses, maintains and shares information about you (“user” or
          “you” or “your”) on account of you accessing and using the Site. This
          information may include but is not limited to any information you
          upload, emails that you exchange with Gharaaj and other users of the
          Site and any information submitted/ provided by you to Gharaaj.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          We recognize the importance of your privacy and we are committed to
          ensuring that you are aware of how your information is being used.
          Please read this Policy carefully to understand how we collect and use
          your information. Your use of the Site is also governed by our Terms
          of Use. You are free to use the Site only if you agree with our
          policies and practices encapsulated in this Policy read in conjunction
          with the Terms of Use.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          By using the Site and/or by providing your information to us, you
          consent to the collection and use of the information you disclose on
          the Site in accordance with this Policy. If you wish to contact
          Gharaaj in connection with any matter relating to this Policy or
          privacy related issues you may send an email to
          data.privacy@Gharaaj.com. Alternatively, you may raise your concern
          using your Account (as defined below).
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          By browsing, visiting, accessing and/or using the services on the
          Site, you consent and agree to the terms of Gharaaj’s Policy as
          detailed herein.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          You also agree that the information furnished by you is lawful, true
          and correct and does not violate or infringe any laws. In case of any
          violations, infringement, furnishing of wrongful or unauthorized
          information, Gharaaj shall not be liable to you or to any third party
          for the same.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          We use data collection devices such as "cookies" on certain pages of
          the Site to help analyse our web page flow, measure promotional
          effectiveness, and promote trust and safety. The term "cookies" means
          small files placed on your hard drive that assist us in providing our
          services. We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. You are always free to
          decline our cookies if your browser permits, although in that case you
          may not be able to use certain features on the Site. Additionally, you
          may encounter "cookies" or other similar devices on certain pages of
          the Site that are placed by third parties. Gharaaj does not control
          the use of cookies by third parties.
        </Typography>
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          Objective, Scope and Applicability of the Policy
        </Typography>
        <Typography variant="p" className="text-start">
          There are three (3) general categories of information we collect.{" "}
          <br />
          A.Information you provide us, to enable your use of the Account/Site{" "}
          <br />
          B.Information you choose to give us <br />
          C. Information that is necessary forthe payment related services{" "}
          <br />
          Further to each of the aforementioned categories set out in Section
          II: Sub-Section A, B and C above we collect the following types of
          information from you (collectively referred to as “Information”)
          <br />
          “Personally Identifiable Information” - information that could be used
          to identify you such as, your name, email address, age, gender, IP
          addresses and other similar information; “Sensitive Personal
          Information” such as passwords in encrypted form and secured
          financial/ payment related information; <br />
          “Non-Personal Information” - information that cannot be used to
          identify you such as the webpages that you have viewed and your usage
          patterns; and (iv) “Device Information”- information which is
          automatically collected about the device from which the Site is used.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          Information you provide Gharaaj to enable your use of the Account/Site
        </Typography>
        <Typography variant="p" className="text-start ">
          We ask for and collect the following Personally Identifiable
          Information and Sensitive Personal Information about you when you use
          the Site. This information is necessary for performance of the
          agreement between you and us and to allow us to comply with our legal
          obligations. Without it, we may not be able to provide you with all
          the requested services. Account Information. In order to use certain
          features on the Site, Gharaaj may require you to create a user account
          (“Account”) which shall include your user profile (“Profile”). When
          you opt to create such an Account and sign /click on “I Accept” in
          respect of the creation of an Account, we require you to provide
          certain Personally Identifiable information such as your first name,
          last name, email address, date of birth, place of birth, employer
          name, phone number, interests, and depending on the nature of services
          you avail, information pertaining to your gender and marital status.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          Profile and Listing Information. In addition to (i) above, in order to
          use certain features on the Site (such as booking or creating a
          listing), we may ask you to provide certain additional Personally
          Identifiable Information, which may include your address, phone
          number, and a profile picture. Identity Verification Information. To
          help create and maintain a trusted environment, we may collect certain
          Personally Identifiable Information in order to verify your identity,
          such as images of specified government issued identification,
          including passport, national ID card, or driving license, or any other
          information as may be required to verify your identity . Payment
          Information. In addition to the foregoing, in order to use certain
          other features on the Site (such as booking or creating a listing), we
          may require you to provide certain financial information (as more
          particularly described in sub section C below) in order to facilitate
          the processing of payments.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          Communications with Gharaaj and its affiliates and authorised service
          providers/agents or other users. When you communicate with Gharaaj or
          use the Site to communicate with Gharaaj’s affiliates and authorised
          service providers/agents or when you communicate with other users of
          Gharaaj, we collect all such correspondence, information about your
          communication and any additional information you choose to provide.
        </Typography>
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          Information you choose to give us
        </Typography>
        <Typography variant="p" className="text-start ">
          You may choose to provide us with additional Personally Identifiable
          Information in order to obtain a better user experience when using
          Gharaaj.
        </Typography>
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          <span className="fw-medium">Additional Profile Information.</span>You
          may choose to provide additional Personally Identifiable Information
          as part of your Profile (preferred language(s), city, and a personal
          description). Some of this information as indicated in your account
          settings is part of your public profile page and will be publicly
          visible to others.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          <span className="fw-medium">Address Book Contact Information.</span>
          You may choose to import your address book contacts or enter your
          contacts’ information manually to access certain features of the
          Gharaaj. Other Information. You may otherwise choose to provide us
          Information when you fill in a form, conduct a search, update or add
          information to your Account, respond to surveys, post to community
          forums, participate in promotions, or use other features/
          functionalities of the Site.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          Information that is necessary for the payment related services
        </Typography>
        <Typography variant="p" className="text-start ">
          Gharaaj needs to collect the following Sensitive Personal Information,
          as it is necessary for the adequate performance of its agreements with
          you and to comply with applicable law (such as anti-money laundering
          regulations). Without it, you will not be able to use payment services
          offered by Gharaaj on the Site:
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          <span className="fw-medium">Payment Information.</span>
          When you use any payment services on the Site, certain financial
          information may be required to facilitate the processing of such
          payments. You are to note that Gharaaj engages with third party
          payment service providers in order to enable you to process your
          payments in connection with the use of Gharaaj’s services. You will be
          required by such third-party payment service providers to provide
          certain Sensitive Personal Information. Please note that to this
          extent, you will be subject to the terms of use and privacy policy
          that is maintained by such third-party service provider.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          <span className="fw-medium">
            Identity Verification and Other Information.
          </span>
          In connection with the collection of payment information and
          processing your payments we may also require certain information using
          which we can verify your identity (such as images of your specified
          government issued identification including passport, national ID card,
          or driving license) or other authentication information/KYC documents,
          your date of birth, your address, e-mail address, phone number and
          other information (such as your invoice amount, e-mail id and contact
          number) in order to verify your identity, provide the payment services
          to you, and to comply with applicable law.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          <span className="fw-medium">
            How long do we keep the information:
          </span>
          The information provided by you on our website/app is stored in our
          systems until you delete it. After such time, we will either delete or
          anonymize your information or, if this is not possible (for example,
          because the information has been stored in backup archives), then we
          will securely store your information and isolate it from any further
          use until deletion is possible. Unless any government/legal agency
          specifically required us to release the aforesaid information to them
          vide a written notice or order. Delete Your Personal Data: You may
          delete or request that We assist in deleting the Personal Data We have
          collected about You. We may allow you to delete certain information
          about You from within the Service. You may update, amend, or delete
          Your information at any time by signing in to Your Account if you have
          one, and visiting the account settings section that allows you to
          manage Your personal information. You may also contact Us to request
          access to, correct, or delete any personal information you provided.
          Please note, however, that We may need to retain certain information
          in case we have a legal obligation or lawful basis to do so.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          Sharing your information:
        </Typography>
        <Typography variant="p" className="text-start ">
          We share your Information within Gharaaj’s group companies and with
          other persons, service providers and entities who help us provide
          services to you (e.g. accommodation provider, services partner, social
          community partner etc). We only share your information in accordance
          with the applicable law. In the interest of clarity, we share your
          Information for the purposes set out in this Policy, with the
          following categories of recipients:
          <br /> Within Gharaaj’s group of companies in relation to its business
          purposes; <br />
          With accommodation managers/landlords of property(ies) or with
          tenants/ prospective tenants or occupants as the case may be to enable
          providing the services you request;
          <br /> We use a variety of third-party service providers and vendors
          to help us provide products and services related to Gharaaj, including
          curated community apps, utilities and services related to living
          (water, laundry, mobility etc.), and payment services. For example,
          service providers which assist us to: (i) verify your identity or
          authenticate your identification documents; (ii) verify information
          against that which is available on public databases; (iii) conduct
          background or police checks, fraud prevention, and risk assessment;
          (iv) perform product development, software and website maintenance and
          debugging; (v) allow the provision of Gharaaj services through third
          party platforms and software tools (e.g. through the integration with
          our API’s); or (vi) provide customer service, advertising, or payments
          services. These providers have access to your information to the
          extent required to perform these tasks on our behalf and are
          contractually bound to protect and to use it only for the purposes for
          which it was disclosed and consistent with this Policy.
        </Typography>{" "}
        <Typography variant="p" className="text-start ">
          With persons/entities that own/ manage other applications or websites
          that integrate with our API or services, or those with an API or
          service with which we are integrated or choose to integrate in future;
          <br />
          Other members of curated networks of Gharaaj tenants, users and
          employees if you submit content in such a forum/network, such as blog
          comments, social media posts, pictures or other content which are
          viewable by members of such curated networks. <br />
          The general public, if you submit content in a public forum, such as
          blog comments, social media posts, or other features of our services
          that are obviously viewable by the general public. For example, you
          may choose to use social sharing features and related tools which let
          you share details pertaining to your Gharaaj experience with other
          applications, sites, or media, and vice versa. Your use of these
          features enables the sharing of Information with your friends or the
          public, depending on your social sharing service settings. Please
          refer to the privacy policies of those social sharing services for
          more information about how they handle the information you provide to
          or share through them;
          <br /> Third parties where we have a duty to or are permitted to
          disclose your Information by law (e.g. law enforcement officials,
          government authorities); <br />
          With the police in the exercise of their functions or with others as
          required by a court order; Third parties where we or any Gharaaj group
          company, sell or buy any business or assets, in which case we may
          disclose your Information to the prospective seller or buyer of such
          business or assets;
          <br /> Third parties in connection with any merger, sale of company
          assets, consolidation or restructuring, financing, or acquisition of
          all or a portion of Gharaaj or any of the Gharaaj group company’s
          business, in which case Information held by it you may be one of the
          transferred assets.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          Note where we share your Sensitive Personal Information or Personally
          Identifiable Information, appropriate protections will be in place to
          protect such information as required by applicable data protection
          laws. Sometimes, we may share Information in an aggregated,
          pseudonymised and/or anonymised form which cannot reasonably be used
          to identify an individual. Purposes for which we use your Information:
          <br />
          Correspondence/communication with you pursuant to you registering an
          Account or your usage of the Site; <br /> Verification of your
          Account;
          <br />
          Ensuring that the content on the Site is presented in the most
          effective manner for you and for your device; <br /> To provide you
          with newsletters, information, products or services that you request
          from us which we feel may interest you, where you have consented to be
          contacted for such purposes; <br />
          To notify you about changes to the Site, this Policy or the Terms of
          Use; <br />
          For the purpose of studying your usage patterns; For analytical
          purposes, including but not limited to assessing usage data, usage
          patterns, estimate audience sizes and other similar activities; <br />
          In any other manner we may describe when you provide the Information;
          <br />
          Detection and prevention of fraud, spam, abuse, security incidents,
          and other harmful activity; <br />
          Conducting security investigations and risk assessments;
          <br /> Verification or authentication of the information or
          identifications provided by you (such as to verify your accommodation
          address or compare your identification photo to another photo you
          provide);
          <br /> Conducting checks against databases and other information
          sources, including background or police checks, to the extent
          permitted by applicable laws and with your consent where required;
          Compliance with our legal obligations and applicable laws;
          <br /> Compliance and monitoring of our contractual obligations
          towards you; <br />
          Resolution of any disputes with you or any of our users s and enforce
          our agreements with third parties.
          <br /> In connection with the activities above, we may conduct
          profiling based on your interactions with Gharaaj, your Profile
          information and other content you submit, and information obtained
          from third parties. In limited cases, automated processes may restrict
          or suspend access to Site, if such processes detect an activity that
          we think poses a safety or other risk to Gharaaj, other users, or
          third parties.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          By accepting this policy you agree to receive communications via
          WhatsApp.
        </Typography>{" "}
        <br /> <br />
        <Typography variant="h6" className="fw-semibold">
          DATA SECURITY
        </Typography>
        <Typography variant="p" className="text-start ">
          The Information that you provide, subject to disclosure in accordance
          with this Policy and Terms of Use, shall be maintained in a safe and
          secure manner. Gharaaj’s databases and Information are stored on
          secure servers with appropriate firewalls owned by Gharaaj or by third
          parties. Gharaaj gives the user right to withdraw his consent by
          sending an email at <Link>data.privacy@Gharaaj.com</Link> <br /> Given
          the nature of internet transactions, Gharaaj does not take any
          responsibility for the transmission of Information including the
          Information you provide to Gharaaj. Any transmission of your
          Information on the internet is done at your risk. Gharaaj does not
          take any responsibility for you or any third party circumventing the
          privacy settings or security measures contained on the Site.
          <br /> While Gharaaj will use all reasonable efforts to ensure that
          your Information is safe and secure, it offers no representation,
          warranties or other assurances that the security measures are
          adequate, safe, fool proof or impenetrable. <br />
          You acknowledge that as a registered user you are responsible for
          maintaining the security of your Account such as your Account login
          credentials and passwords, and that you should not provide these
          credentials to any third party. If it comes to your knowledge that or
          if you have reason to believe that your Account credentials have been
          stolen or been made known to others, you must contact us immediately
          at <Link>data.privacy@Gharaaj.com</Link> You acknowledge that you are
          responsible for all acts done, using your Account login credentials.
          Gharaaj is not responsible if someone else accesses your Account on
          account of your failure to maintain security of your Account
          credentials.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h" className="fw-semibold">
          Your Rights:
        </Typography>
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          Managing Your Information
        </Typography>
        <Typography variant="p" className="text-start ">
          You may access and update your Personally Identifiable or Sensitive
          Personal Information through your Account settings. Please note that
          you do not have the option to connect to third party applications
          using your Account. Do note however, Gharaaj reserves the rights to
          save any Non-Personal, Device Information and usage Information and
          you are not entitled to seek the deletion of the same.
          <br />
          <br />
          Gharaaj at its sole discretion may permit or deny the change of any
          Information, if it is believed such Information is required to comply
          with applicable laws. <br />
          <br />
          Rectification of Inaccurate or Incomplete Information You have the
          right to ask us to correct inaccurate or incomplete Personally
          Identifiable Information or Sensitive Personal Information concerning
          you (and which you cannot update yourself within your Account) by
          contacting data.privacy@Gharaaj.com.
          <br />
          <br />
          <span className="fw-medium">Data Retention and Erasure</span>
          <br />
          <br />
          We retain your Information as long as it is required for it discharge
          its obligations towards you and/or as may be required under law.
          However, there may be circumstances that mean we must retain your
          personal information for longer (e.g. in the event of a dispute or if
          you make a claim in relation to a booking). We may retain some of your
          Personally Identifiable information as necessary for our legitimate
          business interests, such as fraud detection and prevention and
          enhancing safety. Information you have shared with others (e.g.
          reviews, forum postings) may continue to be publicly visible on the
          Site, even after your Account is cancelled.
          <br />
          <br />
          <span className="fw-medium">International Privacy Rights</span>
          <br />
          <br />
          Gharaajs’s Sites and/or databases are operated and managed on servers
          that may be located and operated in different jurisdictions. By using
          and accessing the Site, you consent to the transfer to and processing
          of your Information including the Sensitive Personal Information and
          the Personally Identifiable Information, on servers that may be
          located in a jurisdiction different from where you are located, and
          that the protection of such Information may be different than required
          under the laws of your residence or location.
          <br />
          <br />
          <span className="fw-medium">Age restrictions</span>
          <br />
          <br />
          The Site is only intended for users who are competent to contract
          under applicable law. If you are not of the requisite age, you are not
          to provide any Personally Identifiable Information or Sensitive
          Personal Information. If it comes to Gharaaj’s attention that any such
          information pertains to an individual who is not competent to contract
          under applicable law, such Information may be deleted without notice
          to you.
          <br />
          <br />
          <span className="fw-medium">Amendments to the Policy</span>
          <br />
          <br />
          This Policy is subject to change at Gharaaj’s sole discretion. Any
          changes to the Policy will be notified by way of a notice on the home
          page. Terms of Use
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="h6" className="fw-semibold">
          This Policy shall form a part of the Terms of Use.
        </Typography>{" "}
        <br />
        <br />
        <Typography variant="p" className="text-start ">
          <span className="fw-medium">Data protection contacts</span>
          <br />
          <br />
          If you have any questions, comments, complaints or suggestions in
          relation to this Policy, or any other concerns about the way in which
          we process information about you, please contact our Grievance
          Redressal Officer (detail mentioned below) at
          data.privacy@Gharaaj.com. We shall endeavour to address all
          requests/complaints within thirty (30) days from the date of such a
          request/complaint being made.
          <br />
          <br />
          <br />
          <br />
          Harsha Sen <br />
          Redressal Officer <br />
          email: <Link>data.privacy@Gharaaj.com</Link>
        </Typography>
      </div>
    </Layout>
  );
}
