import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "../../Css/about.module.css";
import Acres from "../../Images/About/Partners/99acres.svg";
import Startupindia from "../../Images/About/Partners/startupindia.svg";
import Magicbricks from "../../Images/About/Partners/magicbricks.svg";
import NITI from "../../Images/About/Partners/NITI.svg";
import BOB from "../../Images/About/Partners/bob.svg";
export default function PartnersList() {
  const Partners = [Acres, Startupindia, Magicbricks, NITI];
  return (
    <div>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          borderTop: "1px solid #D2D2D2",
        }}
      >
        <Grid
          container
          lg={10.8}
          xs={11}
          className="justify-content-lg-between justify-content-center align-items-center "
        >
          {Partners.map((val, ind) => {
            return (
              <Grid xs={3} lg="auto" key={ind} className="">
                <div className="text-center">
                  <img src={val} alt="images" className={styles.partner_img} />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
