import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "../../Css/about.module.css";
import { Typography } from "@mui/material";
export default function Banner() {
  const [userName, setUserName] = useState();

  useEffect(() => {
    setUserName(localStorage.getItem("UserName"));
  }, []);
  return (
    <div className={styles.outer_div_banner}>
      <div className={styles.upper_text_outerdiv_banner}>
        <Typography className={styles.upper_text_banner}>
          Home
          <ChevronRightIcon />
          View Profile
        </Typography>
      </div>
      <div className={styles.heading_text_outerdiv_banner}>
        {/* <Typography className={styles.heading_text_banner}>
          Hi, Rahul Singh
        </Typography> */}
        <Typography component="h1" className="text-white">
          Hi, {userName}
        </Typography>
        <br />
        <br />
      </div>
    </div>
  );
}
