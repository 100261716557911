import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import userimg from "../../Images/Universal/user.png";
import styles from "../../Css/userprofile.module.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import swal from "sweetalert";

const tabs = [
  { name: "Profile Detail", path: "/user-profile" },
  { name: "Change Password", path: "/change-password", color: `#02C988` },

  // { name: "Watchlist", path: "/wishlist" },
  {
    name: "Recommended Properties",
    path: "/recommended-properties",
  },
  {
    name: "My Properties",
    path: "/my-properties",
  },
  { name: "My Visits", path: "/my-visits" },
];
const rows = [
  {
    payment: "#0098-Sep 2023",
    amount: "₹ 50,000/-",
    date: "01 Sep 2023",
    Status: "Success",
  },
  {
    payment: "#0098-Sep 2023",
    amount: "₹ 50,000/-",
    date: "01 Sep 2023",
    Status: "Success",
  },
  {
    payment: "#0098-Sep 2023",
    amount: "₹ 50,000/-",
    date: "01 Sep 2023",
    Status: "Success",
  },
  {
    payment: "#0098-Sep 2023",
    amount: "₹ 50,000/-",
    date: "01 Sep 2023",
    Status: "Success",
  },
];

export default function PaymentHistoryComponent() {
  const Navigate = useNavigate();

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly" }}
        className="flex-md-row flex-column"
      >
        <Box
          sx={{ borderRight: "solid 1px #D2D2D2" }}
          className="ps-lg-3 ps-1 userProfile_TabSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            {/* <Avatar
              alt="Remy Sharp"
              src={userimg}
              sx={{ width: 100, height: 100 }}
            /> */}

            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={
                    {
                      // borderBottom: "1px solid #E7E7E7",
                      // marginBottom: "20px",
                    }
                  }
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          <div className={styles.payment_upper_section_outerdiv}>
            <div>
              <Typography className={styles.profiledetail_heading}>
                Payment History
              </Typography>
              <Typography className={styles.profiledetail_text_paragraph}>
                See history of your payment plan invoice.
              </Typography>
            </div>
            <div className="mt-md-0 mt-4">
              <Button className={styles.payment_btn}>Payment</Button>
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "1rem", boxShadow: "none" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Payment Invoice</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.payment}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell sx={{ color: "rgba(0, 192, 251, 1)" }}>
                      <FileDownloadIcon />
                      Download
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
