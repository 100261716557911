import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyARSnKNGXf1-KfBVvBfW-bcECNCmaJL2Bg",
  authDomain: "gharaaj-abef7.firebaseapp.com",
  projectId: "gharaaj-abef7",
  storageBucket: "gharaaj-abef7.appspot.com",
  messagingSenderId: "136271646849",
  appId: "1:136271646849:web:23326bf2f093d615effd80",
  measurementId: "G-2FCWV1W9XY",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
