import React, { useEffect, useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import yourVideoFile from "../../Images/Home/dummy-video.mp4";
import yourVideoPoster from "../../Images/Home/home-banner.jpg";
import PropertyImg1 from "../../Images/Login/login-propertyImg.png";
// import Author1 from "../../Images/Home/Testimonial/testimonial-author.jpg";
// import Author2 from "../../Images/Home/Testimonial/testimonial-author1.png";
// import Author3 from "../../Images/Home/Testimonial/testimonial-author2.png";
// import Author4 from "../../Images/Home/Testimonial/testimonial-author3.png";
// import Author5 from "../../Images/Home/Testimonial/testimonial-author4.png";
// import Author6 from "../../Images/Home/Testimonial/testimonial-author5.png";
import User1 from "../../Images/Home/Testimonial/New/user1.jpg";
import User2 from "../../Images/Home/Testimonial/New/user2.jpg";
import User3 from "../../Images/Home/Testimonial/New/user3.jpg";
import User4 from "../../Images/Home/Testimonial/New/user4.jpg";
import User5 from "../../Images/Home/Testimonial/New/user5.jpg";
import User6 from "../../Images/Home/Testimonial/New/user6.jpg";
import User7 from "../../Images/Home/Testimonial/New/user7.jpg";

import Rating from "../../Images/Home/Testimonial/testimonial-rating.png";

const CustomerLoves = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    "Slide 1",
    "Slide 2",
    "Slide 3",
    "Slide 4",
    "Slide 5",
    // Add more slides as needed
  ];
  const totalSlides = slides.length;
  const slidesToShow = 2;

  // Auto-slide configuration
  const autoSlideInterval = 3000; // 3 seconds (adjust as needed)
  let autoSlideTimer;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  // Function to start auto-sliding
  const startAutoSlide = () => {
    autoSlideTimer = setInterval(nextSlide, autoSlideInterval);
  };

  // Function to stop auto-sliding
  const stopAutoSlide = () => {
    clearInterval(autoSlideTimer);
  };

  // Start auto-sliding when the component mounts
  useEffect(() => {
    startAutoSlide();

    // Stop auto-sliding when the component unmounts
    return () => {
      stopAutoSlide();
    };
  }, []);

  const desktopTestimonial = [
    {
      text1:
        "Gharaaj.com ne meri madad ki, aur maine apna dream home unke saath khojne mein maze liye. Unki mehnat aur patience ne mujhe support kiya, aur main unhe appreciate karta hoon.",
      username1: "Amrit Sharma",
      userPosition1: "@Sales Executive, Tata Motors",
      profile1: User1,
      text2:
        "Mujhe ek reliable real estate partner ki talash thi, aur Gharaaj.com ne mujhe sahi direction mein guide kiya. Unki team ne mere questions ka jawab diya aur meri property search ko straightforward banaya",
      username2: "Aarti Deshpande",
      userPosition2: "@IT Analyst, Infosys",
      profile2: User2,
    },
    {
      text1:
        "Gharaaj.com ek achha option hai real estate solutions ke liye. Main unke saath property sale ke liye kaam kiya, aur woh mere expectations ke mutabik kaam kiya. Hone wale clients ke liye main unhe recommend karunga.",
      username1: "Pranav Kumar",
      userPosition1: "@Business Development Manager, HDFC Bank",
      profile1: User3,

      text2:
        "Mere paas property purchase aur sale mein kisi experience ki kami thi, lekin Gharaaj.com ne mujhe support kiya. Unhone mere requirements ko samjha, aur mere liye thik ghar dhoondha.",
      username2: "Divya Rastogi",
      userPosition2: "@Software Engineer, TCS",
      profile2: User4,
    },
    {
      text1:
        "Gharaaj.com ne meri property search mein madad ki, aur unka approach professional tha. Woh meri preferences ko understand kiya, mujhe options dikhaye, aur achi deal final karne mein help ki.",
      username1: "Ravi Verma",
      userPosition1: "@Finance Manager, Deloitte",
      profile1: User5,

      text2:
        "Main Gharaaj.com ke services se khush hoon, kyunki woh mere property sale ke liye sahi approach laaye. Unhone mujhe sahi raasta dikhaya aur market conditions ko samjha kar mere liye behtar deal banayi.",
      username2: "Neha Gupta",
      userPosition2: "@Marketing Manager, Amazon India",
      profile2: User6,
    },
    {
      text1:
        "Gharaaj.com ne mere property purchase experience ko aasan banaya. Unhone mere requirements ko seriously liya aur mujhe ek quality property provide ki. Main unhe bharosa karta hoon aur doosron ko unki services recommend karunga.",
      username1: "Arjun Singh",
      userPosition1: "@CEO, Realpro Industries",
      profile1: User7,
    },
  ];
  const mobileTestimonial = [
    {
      text: "Gharaaj.com ne meri madad ki, aur maine apna dream home unke saath khojne mein maze liye. Unki mehnat aur patience ne mujhe support kiya, aur main unhe appreciate karta hoon.",
      usernam1: "Amrit Sharma",
      userPosition: "@Sales Executive, Tata Motors",
      profile: User1,
    },
    {
      text: "Mujhe ek reliable real estate partner ki talash thi, aur Gharaaj.com ne mujhe sahi direction mein guide kiya. Unki team ne mere questions ka jawab diya aur meri property search ko straightforward banaya",
      username: "Aarti Deshpande",
      userPosition: "@IT Analyst, Infosys",
      profile: User2,
    },
    {
      text: "Gharaaj.com ek achha option hai real estate solutions ke liye. Main unke saath property sale ke liye kaam kiya, aur woh mere expectations ke mutabik kaam kiya. Hone wale clients ke liye main unhe recommend karunga.",
      username: "Pranav Kumar",
      userPosition: "@Business Development Manager, HDFC Bank",
      profile: User3,
    },
    {
      text: "Mere paas property purchase aur sale mein kisi experience ki kami thi, lekin Gharaaj.com ne mujhe support kiya. Unhone mere requirements ko samjha, aur mere liye thik ghar dhoondha.",
      username: "Divya Rastogi",
      userPosition: "@Software Engineer, TCS",
      profile: User4,
    },
    {
      text: "Gharaaj.com ne meri property search mein madad ki, aur unka approach professional tha. Woh meri preferences ko understand kiya, mujhe options dikhaye, aur achi deal final karne mein help ki.",
      username: "Ravi Verma",
      userPosition: "@Finance Manager, Deloitte",
      profile: User5,
    },
    {
      text: "Main Gharaaj.com ke services se khush hoon, kyunki woh mere property sale ke liye sahi approach laaye. Unhone mujhe sahi raasta dikhaya aur market conditions ko samjha kar mere liye behtar deal banayi.",
      username: "Neha Gupta",
      userPosition: "@Marketing Manager, Amazon India",
      profile: User6,
    },
    {
      text: "Gharaaj.com ne mere property purchase experience ko aasan banaya. Unhone mere requirements ko seriously liya aur mujhe ek quality property provide ki. Main unhe bharosa karta hoon aur doosron ko unki services recommend karunga.",
      username: "Arjun Singh",
      userPosition: "@CEO, Realpro Industries",
      profile: User7,
    },
  ];
  return (
    <section className="customer-loves py-md-5 py-3 px-md-0 px-2">
      <Container maxWidth="xxl" className="pt-2 pb-5">
        <Typography
          variant="h5"
          component="h4"
          className="smallHeading_TwoHeadings text-center text-white mb-lg-5 mb-md-0 mb-4"
        >
          Our Customer Loves Us
        </Typography>
        <Grid
          container
          spacing={2}
          className="justify-content-between flex-xl-row flex-column flex-lg-nowrap pt-lg-4 pt-md-5 pt-3 pb-lg-0 pb-md-5 pb-3"
        >
          <Grid item lg={5.5} xs={12} className="mb-xl-0 mb-md-4 mb-3 py-0">
            <div className="customer-loves-video">
              <video
                controls
                autoPlay
                src={yourVideoFile}
                width="50%"
                height="auto"
                
                style={{ borderRadius: "4px" ,marginLeft:"3rem"}}
              />
            </div>
          </Grid>
          <Grid
            item
            className="py-lg-0 py-2 px-lg-3 home-ownerboxWrapper ms-xl-5"
            lg={6.5}
            xs={12}
          >
            {/* Dektop Carousel ---------------------------------------- */}
            <Carousel
              interval={5000}
              pause={false}
              className=" d-md-block d-none"
            >
              {desktopTestimonial.map((val, ind) => {
                return (
                  <Carousel.Item key={ind}>
                    <div
                      className={`d-flex justify-content-xl-start justify-content-center`}
                    >
                      <div className={`carousel-slide me-2`}>
                        <div className="home-testimonial">
                          <p className="testimonial__text">{val.text1}</p>
                          <address className="testimonial__author">
                            <div className="testimonial-img-wrapper">
                              <img
                                src={val.profile1}
                                alt=""
                                className="testimonial__photo"
                              />
                            </div>
                            <div className="testimonial-details-wrapper">
                              <h6 className="testimonial__name">
                                {val.username1}
                              </h6>
                              <p className="testimonial__date">
                                {val.userPosition1}
                              </p>
                              <div className="ratings-wrapper">
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                              </div>
                            </div>
                          </address>
                        </div>
                      </div>
                      <div
                        className={`carousel-slide ms-2 d-${
                          val.text2 ? "block" : "none"
                        } `}
                      >
                        <div className="home-testimonial">
                          <p className="testimonial__text">{val.text2}</p>
                          <address className="testimonial__author">
                            <div className="testimonial-img-wrapper">
                              <img
                                src={val.profile2}
                                alt=""
                                className="testimonial__photo"
                              />
                            </div>
                            <div className="testimonial-details-wrapper">
                              <h6 className="testimonial__name">
                                {val.username2}
                              </h6>
                              <p className="testimonial__date">
                                {val.userPosition2}
                              </p>
                              <div className="ratings-wrapper">
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                              </div>
                            </div>
                          </address>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
            {/* Mobile Carousel ---------------------------------------- */}
            <Carousel interval={5000} pause={false} className="d-md-none">
              {mobileTestimonial.map((val, ind) => {
                return (
                  <Carousel.Item key={ind}>
                    <div
                      className={`d-flex justify-content-xl-start justify-content-center`}
                    >
                      <div className={`carousel-slide me-2`}>
                        <div className="home-testimonial">
                          <p className="testimonial__text">{val.text}</p>
                          <address className="testimonial__author">
                            <div className="testimonial-img-wrapper">
                              <img
                                src={val.profile}
                                alt=""
                                className="testimonial__photo"
                              />
                            </div>
                            <div className="testimonial-details-wrapper">
                              <h6 className="testimonial__name">
                                {val.username}
                              </h6>
                              <p className="testimonial__date">
                                {val.userPosition}
                              </p>
                              <div className="ratings-wrapper">
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                                <img src={Rating} alt="" />
                              </div>
                            </div>
                          </address>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default CustomerLoves;
