import React from "react";
import Layout from "../../LayoutWrapper/AdminLayout";
import { useEffect } from "react";

const Page404 = () => {
  useEffect(() => {
    localStorage.setItem("activatedPage", "404");
  }, []);
  return (
    <Layout>
      <div className="text-center font-bold my-5 not-found-text">
        Sorry! Page Not Found
      </div>
      ;
    </Layout>
  );
};

export default Page404;
