/** @format */

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../Pages/Home/home.css";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HomeFamilies from "../../Images/Home/home-families.png";
import HomeFriends from "../../Images/Home/home-friends.png";
import HomeSingle from "../../Images/Home/home-single.png";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postApihandler, putApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import swal from "sweetalert";
import WhatsApp from "../../Images/Home/whatsapp.png";
import Gmail from "../../Images/Home/gmail.png";

const LocalityData = [
  {
    name: "Nipania",
    city: "Indore",
  },
  {
    name: "Bhanwarkuan",
    city: "Indore",
  },

  {
    name: "Vijay nagar",
    city: "Indore",
  },
  {
    name: "Mr 10",
    city: "Indore",
  },
  {
    name: "Mr 9",
    city: "Indore",
  },
  {
    name: "AB BypassRoad",
    city: "Indore",
  },
  {
    name: "Mahalakshmi Nagar",
    city: "Indore",
  },
  {
    name: "Bicholi Mardana",
    city: "Indore",
  },
  {
    name: "Rau",
    city: "Indore",
  },
  {
    name: "Mhow",
    city: "Indore",
  },
  {
    name: "Khandwa Road",
    city: "Indore",
  },
  {
    name: "Bengali Square",
    city: "Indore",
  },
  {
    name: "Kanadia Road",
    city: "Indore",
  },
  {
    name: "Talawali Chanda",
    city: "Indore",
  },
  {
    name: "Rajendra Nagar",
    city: "Indore",
  },
  {
    name: "Silicon City",
    city: "Indore",
  },
  {
    name: "Pipliyahana",
    city: "Indore",
  },
  {
    name: "Kanadiya",
    city: "Indore",
  },
  {
    name: "Scheme No 54",
    city: "Indore",
  },
  {
    name: "Saket Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 114",
    city: "Indore",
  },
  {
    name: "Jhalaria",
    city: "Indore",
  },
  {
    name: "AB Road indore",
    city: "Indore",
  },
  {
    name: "Ida Scheme No 134",
    city: "Indore",
  },
  {
    name: "Devguradia",
    city: "Indore",
  },
  {
    name: "Tilak Nagar",
    city: "Indore",
  },
  {
    name: "Bijalpur",
    city: "Indore",
  },
  {
    name: "Bhawrasla",
    city: "Indore",
  },
  {
    name: "Bada Bangarda",
    city: "Indore",
  },
  {
    name: "Niranjanpur",
    city: "Indore",
  },
  {
    name: "Scheme No 78",
    city: "Indore",
  },
  {
    name: "Race Course Road",
    city: "Indore",
  },
  {
    name: "Old Palasia",
    city: "Indore",
  },
  {
    name: "MR 11",
    city: "Indore",
  },
  {
    name: "Nainod",
    city: "Indore",
  },
  {
    name: "Bicholi Hapsi",
    city: "Indore",
  },
  {
    name: "Kesar Bagh",
    city: "Indore",
  },
  {
    name: "Tejaji Nagar",
    city: "Indore",
  },
  {
    name: "Palakhedi",
    city: "Indore",
  },
  {
    name: "Scheme No 71",
    city: "Indore",
  },
  {
    name: "Sai Kripa Colony",
    city: "Indore",
  },
  {
    name: "Manorama Ganj",
    city: "Indore",
  },
  {
    name: "Scheme No 103",
    city: "Indore",
  },
  {
    name: "Gulab Bagh",
    city: "Indore",
  },
  {
    name: "Navlakha",
    city: "Indore",
  },
  {
    name: "Rau Pitampur Road",
    city: "Indore",
  },
  {
    name: "Rangwasa",
    city: "Indore",
  },
  {
    name: "Tigaria Badshah",
    city: "Indore",
  },
  {
    name: "Pipliya Kumar",
    city: "Indore",
  },
  {
    name: "LIG Colony",
    city: "Indore",
  },
  {
    name: "Luv Kush Square",
    city: "Indore",
  },
  {
    name: "Kalani Nagar",
    city: "Indore",
  },
  {
    name: "Magarkheda",
    city: "Indore",
  },
  {
    name: "Gumasta Nagar",
    city: "Indore",
  },
  {
    name: "South Tukoganj",
    city: "Indore",
  },
  {
    name: "Nanda Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 94",
    city: "Indore",
  },
  {
    name: "Sangam Nagar",
    city: "Indore",
  },

  {
    name: "Alok Nagar",
    city: "Indore",
  },
  {
    name: "Anurag Nagar",
    city: "Indore",
  },
  {
    name: "Snehlataganj",
    city: "Indore",
  },
  {
    name: "Sapna Sangeeta Road",
    city: "Indore",
  },
  {
    name: "Vasant Vihar",
    city: "Indore",
  },
  {
    name: "Mangaliya Sadak",
    city: "Indore",
  },
  {
    name: "Paliya",
    city: "Indore",
  },
  {
    name: "Anoop Nagar",
    city: "Indore",
  },
  {
    name: "Bangali Square",
    city: "Indore",
  },
  {
    name: "Arandia",
    city: "Indore",
  },
  {
    name: "Balya Kheda",
    city: "Indore",
  },
  {
    name: "Geeta Bhavan",
    city: "Indore",
  },
  {
    name: "Musakhedi",
    city: "Indore",
  },
  {
    name: "Mig Colony",
    city: "Indore",
  },
  {
    name: "Ring Road",
    city: "Indore",
  },
  {
    name: "Jakhiya",
    city: "Indore",
  },
  {
    name: "Khatiwala Tank",
    city: "Indore",
  },
  {
    name: "Palsikar Colony",
    city: "Indore",
  },
  {
    name: "Rajwada",
    city: "Indore",
  },
  {
    name: "Kordia Barda",
    city: "Indore",
  },
  {
    name: "Mundla Nayta",
    city: "Indore",
  },
  {
    name: "Pithampur Road",
    city: "Indore",
  },
  {
    name: "Bakhtawar Ram Nagar",
    city: "Indore",
  },
  {
    name: "Pigdambar",
    city: "Indore",
  },
  {
    name: "Rambag",
    city: "Indore",
  },
  {
    name: "Vishnupuri Colony",
    city: "Indore",
  },
  {
    name: "Shri Nagar Extension",
    city: "Indore",
  },
  {
    name: "Annapurna Nagar",
    city: "Indore",
  },
  {
    name: "Palasia",
    city: "Indore",
  },
  {
    name: "Budhaniya",
    city: "Indore",
  },
  {
    name: "Gokul Nagar",
    city: "Indore",
  },
  {
    name: "Morod",
    city: "Indore",
  },
  {
    name: "Sanchar Nagar Extention",
    city: "Indore",
  },
  {
    name: "NH3 Agra Mumbai Highway",
    city: "Indore",
  },
  {
    name: "Vallabh Nagar",
    city: "Indore",
  },
  {
    name: "Indrapuri Colony",
    city: "Indore",
  },
  {
    name: "Vaishali Nagar",
    city: "Indore",
  },
  {
    name: "Banganga",
    city: "Indore",
  },
  {
    name: "Shiv Dham Colony",
    city: "Indore",
  },
  {
    name: "Panda",
    city: "Indore",
  },
  {
    name: "Vandana Nagar",
    city: "Indore",
  },
  {
    name: "Sanawadia",
    city: "Indore",
  },
  {
    name: "Dhanvantri Nagar",
    city: "Indore",
  },
  {
    name: "Panchderiya",
    city: "Indore",
  },
  {
    name: "Sainath Colony",
    city: "Indore",
  },
  {
    name: "Tejpur Gadbadi",
    city: "Indore",
  },
  {
    name: "Sawer",
    city: "Indore",
  },
  {
    name: "Malviya Nagar",
    city: "Indore",
  },
  {
    name: "Malharganj",
    city: "Indore",
  },
  {
    name: "Chandan Nagar",
    city: "Indore",
  },
  {
    name: "Mahaveer Nagar",
    city: "Indore",
  },
  {
    name: "Barodiya Ema",
    city: "Indore",
  },
  {
    name: "Usha Nagar Extension",
    city: "Indore",
  },
  {
    name: "Nehru Nagar",
    city: "Indore",
  },
  {
    name: "Baliyakhedi",
    city: "Indore",
  },
  {
    name: "Sirpur",
    city: "Indore",
  },
  {
    name: "Choral",
    city: "Indore",
  },
  {
    name: "Green Park Colony",
    city: "Indore",
  },
  {
    name: "Scheme 54 PU4",
    city: "Indore",
  },
  {
    name: "Sindhi Colony",
    city: "Indore",
  },
  {
    name: "Karolbagh",
    city: "Indore",
  },
  {
    name: "Scheme no 53",
    city: "Indore",
  },
  {
    name: "New palasia",
    city: "Indore",
  },
  {
    name: "Scheme no 74",
    city: "Indore",
  },
  {
    name: "Chikitsak nagar",
    city: "Indore",
  },
  {
    name: "Veena nagar",
    city: "Indore",
  },
  {
    name: "Mansorawar nagar",
    city: "Indore",
  },
  {
    name: "Bapat square",
    city: "Indore",
  },
  {
    name: "Sukliya",
    city: "Indore",
  },
  {
    name: "Royal banglow",
    city: "Indore",
  },
  {
    name: "Dewas Naka",
    city: "Indore",
  },
  {
    name: "Industrial house",
    city: "Indore",
  },
  {
    name: "Khajrana",
    city: "Indore",
  },
  {
    name: "Atal dwar",
    city: "Indore",
  },
  {
    name: "Anand bazar",
    city: "Indore",
  },
  {
    name: "Super corridor",
    city: "Indore",
  },
  {
    name: "Lasudia mori",
    city: "Indore",
  },
  {
    name: "Scheme no 136",
    city: "Indore",
  },
  {
    name: "Scheme no 140",
    city: "Indore",
  },
  {
    name: "Hira nagar",
    city: "Indore",
  },
];
const SelectInterest = () => {
  const { register, handleSubmit, reset } = useForm();
  const [propertyType, setPropertyType] = useState("");
  const [userId, setUserId] = useState();

  // Locality Fields Code----------------
  const [localitiesData, setLocalitiesData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // console.log("suggestions->", suggestions);
  const [showSuggestionsBox, setShowSuggestionsBox] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // console.log("showSuggestions - ", showSuggestions);

  // Handle input changes
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowSuggestionsBox(true);
  };

  // Filter suggestions based on input
  const handleFilterData = () => {
    const filteredSuggestions = LocalityData.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  // Add selected suggestion to localitiesData
  const handleSuggestionClick = (suggestion) => {
    if (!localitiesData.includes(suggestion.name)) {
      setLocalitiesData([...localitiesData, suggestion.name]);
    }
    setInputValue("");
    setShowSuggestionsBox(false);
  };

  // Remove selected locality
  const handleRemoveLocality = (localityToRemove) => {
    setLocalitiesData(
      localitiesData.filter((locality) => locality !== localityToRemove)
    );
  };

  // Trigger filtering when inputValue changes
  useEffect(() => {
    if (inputValue !== "" && showSuggestionsBox) {
      handleFilterData();
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
      setShowSuggestionsBox(false); // Hide suggestions box if input is empty
    }
  }, [inputValue]);

  // Submit function
  const onsubmit = async (value) => {
    const { bhk, budget, userEmail, mobile_no } = value;
    console.log("value is ------>", value);

    const interestData = {
      bhk,
      address: localitiesData, // Send localitiesData as "addresses"
      budget,
      userEmail,
      propertyType,
      mobile_no,
    };
    // console.log("interested data", interestData);
    const response = await postApihandler(`/addUserIntrest`, interestData);
    // console.log("api response is __", response);
    if (response.status === 200) {
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Your Interest Successfully Submitted!",
        showConfirmButton: false,
        timer: 2000,
      });
      setLocalitiesData([]);
      // setPropertyType("");
      reset();
    } else {
      swal("Sorry!", `${response.error.response.data.message}`, "error");
    }
  };

  // /Locality Fields Code----------------

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);
  // Page Refresh
  function refreshPage() {
    window.location.reload();
  }
  // Handle Page Refresh-------------
  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 200);
  };

  return (
    <section
      className="select-interest-wrapper pt-md-5 pb-md-4 pt-3 pb-4"
      id="user-intrest"
    >
      {/* <Container fluid className="pt-4">
       */}
      <Container maxWidth="xxl" className="py-lg-5 py-0">
        <Grid container spacing={2} className="justify-content-center">
          <Grid
            item
            lg={4}
            sm={5.5}
            xs={10}
            style={{
              // backgroundColor: "rgba(2, 201, 136, 1)",
              borderRadius: "12px",
            }}
            className="py-0 d-flex flex-column justify-content-start align-items-start  mt-lg-0 mt-3  "
          >
            <Box className="">
              {/* <Typography
                variant="h5"
                component="h4"
                className="smallHeading_TwoHeadings"
                style={{ color: "rgb(31, 64, 105)" }}
              >
                Rs. 799 Free Daily Property alert sand 11:00Am
              </Typography> */}
              <Typography
                variant="h4"
                component="h3"
                // className="font-bold position-relative largeHeading_TwoHeadings underline-text skyblueUnderline-text text-md-start text-center"
                className="font-bold position-relative largeHeading_TwoHeadings  text-md-start text-center"
                style={{ color: "rgb(31, 64, 105)" }}
              >
                <span className="cross-price position-relative">Rs.799</span>{" "}
                Free Daily Property Alerts At 11:00AM
              </Typography>
              <Box className="icons-wrapper d-flex align-items-center mt-md-5 mt-3 justify-content-md-start justify-content-center">
                <div className="icon">
                  <img src={WhatsApp} alt="" />
                </div>
                <div className="icon ms-5">
                  <img src={Gmail} alt="" />
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 px-0 "
            lg={8}
            sm={6}
            xs={10}
          >
            <form onSubmit={handleSubmit(onsubmit)}>
              <Grid
                container
                sm={{
                  gridRowGap: "20px",
                }}
                className="justify-content-md-start justify-content-center"
              >
                <Grid lg={6} xs={12}>
                  <div className="form-fields-wrapper text-md-start text-center">
                    <Typography
                      variant="h6"
                      component="p"
                      className="text-md-start text-center"
                      style={{ color: "rgb(31, 64, 105)" }}
                    >
                      Please Select Your Interest
                    </Typography>
                    {/* BHK */}
                    <FormControl variant="standard" className="w-75 mt-md-3 ">
                      <InputLabel id="demo-simple-select-standard-label ">
                        Select BHK*
                      </InputLabel>
                      <Select
                        // sx={{ mt: 2, minWidth: { lg: 356, xs: 435 } }}
                        sx={{ mt: 2, minWidth: "100%" }}
                        variant="standard"
                        id="demo-simple-select-standard"
                        {...register("bhk")}
                      >
                        <MenuItem value="">
                          <em>Select BHK...</em>
                        </MenuItem>
                        <MenuItem value="1 BHK">1 BHK</MenuItem>
                        <MenuItem value="2 BHK">2 BHK</MenuItem>
                        <MenuItem value="3 BHK">3 BHK</MenuItem>
                        <MenuItem value="4 BHK">4 BHK</MenuItem>
                        <MenuItem value="5 BHK">5 BHK</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="address-interst mt-md-4 mt-2">
                      <Typography className="mb-2">Locality*</Typography>
                      <div className="searchData-field-wrapper ">
                        <div className="chip-container ">
                          {localitiesData.map((locality, index) => (
                            <Chip
                              key={index}
                              label={locality}
                              onDelete={() => handleRemoveLocality(locality)}
                              className="chip me-2 mt-2"
                            />
                          ))}
                          <TextField
                            variant="outlined"
                            type="text"
                            value={inputValue} // Bind to inputValue
                            onChange={handleInputChange}
                            placeholder="Locality*"
                            className="mt-3 w-75"
                            size="small"
                            id="outlined-basic"
                            // sx={{ marginTop: "30px" }}
                          />
                        </div>
                        {showSuggestionsBox && (
                          <ul className="searchData-wrapper">
                            {suggestions.length > 0 ? (
                              suggestions.map((suggestion, index) => (
                                <li
                                  key={index}
                                  style={{
                                    textAlign: "start",
                                    listStyle: "none",
                                  }}
                                  onClick={() =>
                                    handleSuggestionClick(suggestion)
                                  }
                                >
                                  {suggestion.name}
                                </li>
                              ))
                            ) : (
                              <li
                                style={{
                                  textAlign: "start",
                                  listStyle: "none",
                                }}
                              >
                                No Result Found
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="budget-interst mt-md-4 mt-2">
                      <Typography>Budget*</Typography>
                      <TextField
                        size="small"
                        className="mt-2 w-75"
                        id="outlined-basic"
                        placeholder="Budget*"
                        variant="outlined"
                        name="interest-email"
                        type="number"
                        {...register("budget")}
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    </div>
                  </div>
                </Grid>
                {/* Email */}
                <Grid lg={6} xs={12}>
                  <div className="interest-mail mt-md-0 mt-2 text-md-start text-center  ">
                    <Typography
                      variant="h6"
                      component="p"
                      className="pb-md-3 pb-2"
                      style={{ color: "rgb(31, 64, 105)" }}
                    >
                      Please Enter Your Email
                    </Typography>
                    <TextField
                      size="small"
                      className="mt-md-4 w-75"
                      id="outlined-basic"
                      placeholder="Email*"
                      variant="outlined"
                      name="userEmail"
                      type="email"
                      {...register("userEmail")}
                    />
                  </div>
                  <div className="budget-interst mt-md-4 mt-2 responsive-budget-interest">
                    <Typography>Contact*</Typography>
                    <TextField
                      size="small"
                      className="mt-2 w-75"
                      id="outlined-basic"
                      placeholder="Contact*"
                      variant="outlined"
                      name="mobile_no"
                      type="number"
                      {...register("mobile_no")}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </div>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue={gender}
                    name="propertyType"
                    // {...register("propertyType")}
                  >
                    <div className="property-type-interset mt-md-4 text-md-start text-center">
                      <FormControlLabel
                        value="Rent"
                        control={
                          <Radio
                            sx={{
                              color: "rgba(34, 34, 34, 1)",
                              "&.Mui-checked": {
                                color: "rgba(2, 201, 136, 1)",
                              },
                            }}
                            onClick={() => {
                              setPropertyType("Rent");
                            }}
                          />
                        }
                        label="Rent"
                      />
                      <FormControlLabel
                        value="Sell"
                        control={
                          <Radio
                            sx={{
                              color: "rgba(34, 34, 34, 1)",
                              "&.Mui-checked": {
                                color: "rgba(2, 201, 136, 1)",
                              },
                            }}
                            onClick={() => {
                              setPropertyType("Sell");
                            }}
                          />
                        }
                        label="Buy"
                      />
                    </div>
                  </RadioGroup>
                  <div className="text-md-start text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        backgroundColor: "#004274",
                        fontWeight: "700",
                        padding: "12px 16px",
                      }}
                      className="w-75 mt-md-3 mb-md-2 mt-2"
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default SelectInterest;
