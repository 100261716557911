import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "../../Css/about.module.css";
import { Typography } from "@mui/material";
export default function Banner() {
  return (
    <div className={`${styles.outer_div_banner} pb-md-5 pb-3`}>
      <div className={styles.upper_text_outerdiv_banner}>
        <Typography className={styles.upper_text_banner}>
          Home
          <ChevronRightIcon />
          Property
        </Typography>
      </div>
      <div className={styles.heading_text_outerdiv_banner}>
        {/* <Typography className={styles.heading_text_banner}>
          We Update Them Everyday, For You!!
        </Typography> */}
        <Typography component="h1" className="text-white">
          We Update Them Everyday, For You!!
        </Typography>
      </div>
    </div>
  );
}
