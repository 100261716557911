import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import GharaajLogo from "../../Images/Universal/gharaaj-logo.svg";

import { Box, FormControl, IconButton, MenuItem, Select } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, createContext, useContext } from "react";
import swal from "sweetalert";
import styles from "../../Css/nav.module.css";
// import { Avatar } from "@mui/material";
// import userimg from "../../Images/Universal/user.png";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Toggle from "../../Images/Universal/toggle.webp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// DatePicker----------------------------------------------------------------

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import Swal from "sweetalert2";

function Header() {
  const location = useLocation();
  const [userId, setuserId] = useState();
  const [activatedPage, setActivatedPage] = useState();
  const [userName, setuserName] = useState();
  const [cityName, setCityName] = useState("Indore");
  const Navigate = useNavigate();
  const citiesData = ["Indore", "Bhopal", "Khargone"];
  // Get Local Storage Data
  useEffect(() => {
    setuserId(localStorage.getItem("UserId"));
    setuserName(localStorage.getItem("UserName"));
    setActivatedPage(localStorage.getItem("activatedPage"));
  }, []);

  // Page Refresh
  function refreshPage() {
    window.location.reload();
  }

  // Visit Popup
  const visitPopupBg = {
    background: "#00000061",
  };

  const visitPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle Page Refresh-------------
  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 200);
  };

  // Use Context

  const handleCityName = (e) => {
    setCityName(e.target.value);
  };
  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-tertiary pt-lg-4 pb-lg-3 px-md-5 sticky-top my-navbar"
        >
          <Container fluid>
            <Navbar.Brand
              href="/"
              // style={{ width: "20%" }}
              className="navbar-logo"
            >
              <img src={GharaajLogo} className={styles.navlogo} alt="" />
            </Navbar.Brand>
            <Nav className="d-lg-flex flex-row align-items-center d-none">
              <Link to="/zero-brokerage">
                <Button
                  variant="contained"
                  className={`px-3 ${
                    location.pathname === "/zero-brokerage" ? "active" : ""
                  } me-4`}
                  style={{
                    backgroundColor: "#02C988",
                    color: "#FFF",
                    // fontSize: "14px",
                    // fontWeight: 500,
                  }}
                >
                  How Zero Brokerage Works?
                </Button>
              </Link>
              <Nav.Link
                href="/#/properties/list/all"
                // className={`px-3 `}
                className={`px-3 ${
                  activatedPage === "properties" ? "active" : ""
                }`}
                onClick={() => {
                  handleRefresh();
                }}
              >
                Properties
              </Nav.Link>
              <Nav.Link
                href={
                  userId !== undefined && userId !== null
                    ? "/#/my-visits"
                    : "/#/signup-login"
                }
                className={`px-3 ${
                  location.pathname === "/my-visits" ? "active" : ""
                }`}
              >
                Scheduled Visits
              </Nav.Link>
              <Nav.Link
                href="/#/about"
                className={`px-3 ${
                  location.pathname === "/about" ? "active" : ""
                }`}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="/#/contact"
                className={`px-3 ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact Us
              </Nav.Link>
              <Box className="d-flex justify-content-between align-items-center ps-3">
                {userId !== undefined && userId !== null && (
                  <div className="">
                    <Link
                      to="/user-profile"
                      style={{
                        color: "#222222",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton className="p-0 pe-1">
                        <AccountCircleIcon
                          style={{
                            color: "rgb(2, 201, 136)",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                      {userName}
                    </Link>
                  </div>
                )}

                {userId === undefined ||
                  (userId === null && (
                    <Link to="/signup-login">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#02C988",
                          color: "#FFF",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        className="py-2"
                      >
                        Login/Registration
                      </Button>
                    </Link>
                  ))}
              </Box>
            </Nav>

            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              style={{ border: "none" }}
              className="toggle-button d-lg-none"
            >
              <div className="toggle-wrapper d-flex">
                <div
                  className="toggle-image-wrapper"
                  style={{ maxWidth: "24px" }}
                >
                  <img src={Toggle} alt="" />
                </div>
                <span
                  className="toggle-text ps-2"
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    color: "#222",
                  }}
                >
                  Menu
                </span>
              </div>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className="ps-lg-4"
                >
                  <Box className="d-flex justify-content-between align-items-center">
                    {userId !== undefined && userId !== null && (
                      <div className="me-5 ">
                        <Link
                          to="/user-profile"
                          style={{
                            color: "#222222",
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Avatar
                            alt="Remy Sharp"
                            src={userimg}
                            sx={{ width: 50, height: 50 }}
                          />{" "} */}
                          <IconButton className="p-0 pe-3">
                            <AccountCircleIcon
                              style={{
                                color: "rgb(2, 201, 136)",
                                fontSize: "50px",
                              }}
                            />
                          </IconButton>
                          {userName}
                        </Link>
                      </div>
                    )}

                    {userId === undefined ||
                      (userId === null && (
                        <Link to="/signup-login">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#02C988",
                              color: "#FFF",
                            }}
                          >
                            Login/Registration
                          </Button>
                        </Link>
                      ))}
                  </Box>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <NavDropdown.Divider
                style={{
                  background: "#E7E7E7",
                  height: "1px",
                }}
                className="mt-lg-3 mb-lg-5 mt-2 mb-md-4"
              />
              <Offcanvas.Body className="ps-lg-5 ps-md-4 ps-4">
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link to="/zero-brokerage">
                    <Button
                      variant="contained"
                      className={`px-3 ms-3 ${
                        location.pathname === "/zero-brokerage" ? "active" : ""
                      } `}
                      style={{
                        backgroundColor: "#02C988",
                        color: "#FFF",
                        marginBottom: "20px",
                        // fontSize: "14px",
                        // fontWeight: 500,
                      }}
                    >
                      How Zero Brokerage Works?
                    </Button>
                  </Link>
                  {/* <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  /> */}
                  <Nav.Link
                    href="/#/properties/list/all"
                    className={`px-3 ${
                      location.pathname === "/zero-brokerage" ? "active" : ""
                    }`}
                    onClick={handleRefresh}
                  >
                    Properties
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link
                    href={
                      userId !== undefined && userId !== null
                        ? "/#/my-visits"
                        : "/#/signup-login"
                    }
                    className={`px-3 ${
                      location.pathname === "/zero-brokerage" ? "active" : ""
                    }`}
                  >
                    Scheduled Visits
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link
                    href="/#/about"
                    className={`px-3 ${
                      location.pathname === "/zero-brokerage" ? "active" : ""
                    }`}
                  >
                    About Us
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link
                    href="/#/contact"
                    className={`px-3 ${
                      location.pathname === "/zero-brokerage" ? "active" : ""
                    }`}
                  >
                    Contact Us
                  </Nav.Link>
                  {/* --------------------------- */}
                  {/* <Nav.Link
                    href="/#/properties/list"
                    className="ps-lg-2"
                    onClick={handleRefresh}
                  >
                    Properties
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link href="#" className="ps-lg-2">
                    Book Property Here
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link href="/#/about" className="ps-lg-2">
                    About Us
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link href="/#/contact" className="ps-lg-2">
                    Contact Us
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link href="#" className="ps-lg-2">
                    Privacy & Policy
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  />
                  <Nav.Link href="#" className="ps-lg-2">
                    Terms & Conditions
                  </Nav.Link>
                  <NavDropdown.Divider
                    style={{
                      background: "#E7E7E7",
                      height: "1px",
                      marginBottom: "20px",
                      width: "90%",
                    }}
                  /> */}
                </Nav>
                <NavDropdown.Divider
                  className="d-lg-none mb-4"
                  style={{ background: "#E7E7E7", height: "1px" }}
                />
                {/* <Nav className="d-lg-none">
                  <Nav.Link href="/#/my-visits">Scheduled Visits</Nav.Link>
                  <Nav.Link href="#">VR Visits</Nav.Link>
                  <Nav.Link>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#02C988",
                        color: "#FFF",
                      }}
                    >
                      List Your Property
                    </Button>
                  </Nav.Link>
                </Nav>
                <NavDropdown.Divider
                  className="d-lg-none"
                  style={{ background: "#E7E7E7", height: "1px" }}
                />
                <Nav className="d-lg-none d-flex flex-row align-items-center">
                  <Nav.Link className="">
                    <IconButton>
                      <img src={Author} alt="" />
                    </IconButton>
                  </Nav.Link>
                  <Nav.Link href="#" className="">
                    +91-89820-44340 |{" "}
                    <span style={{ color: "#02C988" }}>info@gharaaj.com</span>
                  </Nav.Link>
                </Nav> */}
                {userId === undefined || userId === null ? (
                  ""
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      swal({
                        title: "Are you sure?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          localStorage.clear();
                          Swal.fire({
                            icon: "success",
                            text: "You have been logged out!",
                            showConfirmButton: false,
                            timer: 2000,
                          });
                          Navigate("/");
                          refreshPage();
                        } else {
                          Swal.fire("Your login details are secure!", {
                            icon: "success",
                            showConfirmButton: false,
                            timer: 2000,
                          });
                        }
                      });
                    }}
                    style={{
                      // backgroundColor: "#EA3A91",
                      color: "#222222",
                      border: "1px solid #1F4069",
                      width: "60%",
                      fontWeight: 500,
                    }}
                  >
                    Logout
                  </Button>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>

          {/* Visit Popup */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={visitPopupBg}
          >
            <Fade in={open}>
              <Box sx={visitPopupStyle}>
                <div className="model-header wrapper d-flex justify-content-between align-content-center">
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Book Visit Schedule
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h4"
                    className="closeModel"
                  >
                    <span onClick={handleClose} style={{ cursor: "pointer" }}>
                      X
                    </span>
                  </Typography>
                </div>
                <Typography id="transition-modal-description">
                  Pick a time for a Free assisted house visit
                </Typography>
                {/* 1st--------x-----------------x------------ */}
                {/* <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  locale="pt-BR"
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="Pp"
                  form="external-form"
                />
                <form id="external-form" onSubmit={submitVisitShedule}>
                  <input type="submit" />
                </form> */}

                {/* 2nd-----------x-----------------x--------- */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDateTimePicker
                    orientation="landscape"
                    // onChange={submitVisitShedule}

                    showDaysOutsideCurrentMonth={true}
                  />
                </LocalizationProvider>
              </Box>
            </Fade>
          </Modal>
        </Navbar>
      ))}
    </>
  );
}

export default Header;
