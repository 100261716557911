import React, { useEffect } from "react";
import Banner from "../../Components/Userprofile/banner";
import ProfileDetail from "../../Components/Userprofile/profiledetail";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import MyVisitComponent from "../../Components/Userprofile/myVisitComponent";

export default function MyVisit() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "my-visits");
  }, []);
  return (
    <Layout>
      <Banner />
      <MyVisitComponent />
      <PartnersList />
    </Layout>
  );
}
