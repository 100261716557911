import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import userimg from "../../Images/Universal/user.png";
import styles from "../../Css/userprofile.module.css";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import "../../Pages/UserProfile/index.css";
import { getApihandler } from "../../Apihandler";

import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { Table } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import swal from "sweetalert";
export default function MyVisitComponent() {
  const Navigate = useNavigate();

  const [visitData, setVisitData] = useState([]);
  const [userId, setUserId] = useState();
  // console.log("userId", userId);
  // console.log("visitData", visitData);

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  useEffect(() => {
    if (userId !== undefined) {
      getVisitProperty();
    }
  }, [userId]);

  const getVisitProperty = async () => {
    // console.log("inner userId", userId);

    const response = await getApihandler(`/getVisitPropertyHistory/${userId}`);
    console.log("visit response--->", response);
    setVisitData(response.data);
    if (response.status === 200) {
    }
  };

  const tabs = [
    { name: "Profile Detail", path: "/user-profile" },
    { name: "Change Password", path: "/change-password" },

    // { name: "Watchlist", path: "/wishlist" },
    {
      name: "Recommended Properties",
      path: "/recommended-properties",
    },
    {
      name: "My Properties",
      path: "/my-properties",
    },
    { name: "My Visits", path: "/my-visits", color: `#02C988` },
    { name: "Track My Thing", path: "/track-my-thing" },
    // { name: "Bank Details", path: "#" },
    // { name: "Setting", path: "#" },
    { name: "Help", path: "/help" },
  ];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          // marginBottom: "2rem",
        }}
        className="flex-md-row flex-column"
      >
        <Box
          // sx={{ borderRight: "solid 1px #D2D2D2" }}
          className="ps-lg-3 ps-1 userProfile_TabSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            {/* <Avatar
              alt="Remy Sharp"
              src={userimg}
              sx={{ width: 100, height: 100 }}
            /> */}
            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={
                    {
                      // borderBottom: "1px solid #E7E7E7",
                      // marginBottom: "20px",
                    }
                  }
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          {/* {visitData.length !== 0
            ? visitData.map((val) => {
                return (
                  <>
                    <Card sx={{ mx: "2px", transform: "scale(1)" }}>
                      <Grid container sx={{ padding: "10px" }}>
                        <Grid
                          xs={12}
                          sm={12}
                          lg={4}
                          className={styles.visit_property_name}
                        >
                          <Typography>Property Name</Typography>
                          <Typography
                            sx={{ fontSize: "22px", fontWeight: "600" }}
                          >
                            {val.propertyName}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={4}
                          lg={2}
                          className={styles.visit_property_inner_card_outerdiv}
                        >
                          <PersonIcon sx={{ fontSize: "3rem" }} />
                          <Typography>{val.userName}</Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={4}
                          lg={2}
                          className={styles.visit_property_inner_card_outerdiv}
                        >
                          <CalendarMonthIcon sx={{ fontSize: "3rem" }} />
                          <Typography>{val.date}</Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={4}
                          lg={2}
                          className={styles.visit_property_inner_card_outerdiv}
                        >
                          <QueryBuilderIcon sx={{ fontSize: "3rem" }} />
                          <Typography>{val.time}</Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </>
                );
              })
            : "No Data"} */}
          <div className={styles.payment_upper_section_outerdiv}>
            <div>
              <Typography className={styles.profiledetail_heading}>
                Visit History
              </Typography>
              <Typography className={styles.profiledetail_text_paragraph}>
                See history of your Shedule Visits.
              </Typography>
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "1rem", boxShadow: "none" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Property Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitData !== undefined &&
                  visitData !== null &&
                  visitData.map((val, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{val.propertyName}</TableCell>
                        <TableCell>{val.date}</TableCell>
                        <TableCell>{val.time}</TableCell>
                        <TableCell>
                          {val.booking_status === 0 && "Pending"}
                          {val.booking_status === 2 && "Agent Assigned"}
                          {val.booking_status === 3 && "On the Way"}
                          {val.booking_status === 4 && "Completed"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {/* <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>4</TableCell>
                  <TableCell>Pending</TableCell>
                </TableRow>
              </TableBody> */}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
