import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import { DatePicker } from "@mui/x-date-pickers";
import styles from "../../Css/addproperty.module.css";
import Layout from "../../LayoutWrapper/AdminLayout";
import {
  getApihandler,
  postApihandler,
  postApihandlerAccessToken,
} from "../../Apihandler";

const arr = [
  {
    name: "Nipania",
    city: "Indore",
  },
  {
    name: "Bhanwarkuan",
    city: "Indore",
  },

  {
    name: "Vijay nagar",
    city: "Indore",
  },
  {
    name: "Mr 10",
    city: "Indore",
  },
  {
    name: "Mr 9",
    city: "Indore",
  },
  {
    name: "AB BypassRoad",
    city: "Indore",
  },
  {
    name: "Mahalakshmi Nagar",
    city: "Indore",
  },
  {
    name: "Bicholi Mardana",
    city: "Indore",
  },
  {
    name: "Rau",
    city: "Indore",
  },
  {
    name: "Mhow",
    city: "Indore",
  },
  {
    name: "Khandwa Road",
    city: "Indore",
  },
  {
    name: "Bengali Square",
    city: "Indore",
  },
  {
    name: "Kanadia Road",
    city: "Indore",
  },
  {
    name: "Talawali Chanda",
    city: "Indore",
  },
  {
    name: "Rajendra Nagar",
    city: "Indore",
  },
  {
    name: "Silicon City",
    city: "Indore",
  },
  {
    name: "Pipliyahana",
    city: "Indore",
  },
  {
    name: "Kanadiya",
    city: "Indore",
  },
  {
    name: "Scheme No 54",
    city: "Indore",
  },
  {
    name: "Saket Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 114",
    city: "Indore",
  },
  {
    name: "Jhalaria",
    city: "Indore",
  },
  {
    name: "AB Road indore",
    city: "Indore",
  },
  {
    name: "Ida Scheme No 134",
    city: "Indore",
  },
  {
    name: "Devguradia",
    city: "Indore",
  },
  {
    name: "Tilak Nagar",
    city: "Indore",
  },
  {
    name: "Bijalpur",
    city: "Indore",
  },
  {
    name: "Bhawrasla",
    city: "Indore",
  },
  {
    name: "Bada Bangarda",
    city: "Indore",
  },
  {
    name: "Niranjanpur",
    city: "Indore",
  },
  {
    name: "Scheme No 78",
    city: "Indore",
  },
  {
    name: "Race Course Road",
    city: "Indore",
  },
  {
    name: "Old Palasia",
    city: "Indore",
  },
  {
    name: "MR 11",
    city: "Indore",
  },
  {
    name: "Nainod",
    city: "Indore",
  },
  {
    name: "Bicholi Hapsi",
    city: "Indore",
  },
  {
    name: "Kesar Bagh",
    city: "Indore",
  },
  {
    name: "Tejaji Nagar",
    city: "Indore",
  },
  {
    name: "Palakhedi",
    city: "Indore",
  },
  {
    name: "Scheme No 71",
    city: "Indore",
  },
  {
    name: "Sai Kripa Colony",
    city: "Indore",
  },
  {
    name: "Manorama Ganj",
    city: "Indore",
  },
  {
    name: "Scheme No 103",
    city: "Indore",
  },
  {
    name: "Gulab Bagh",
    city: "Indore",
  },
  {
    name: "Navlakha",
    city: "Indore",
  },
  {
    name: "Rau Pitampur Road",
    city: "Indore",
  },
  {
    name: "Rangwasa",
    city: "Indore",
  },
  {
    name: "Tigaria Badshah",
    city: "Indore",
  },
  {
    name: "Pipliya Kumar",
    city: "Indore",
  },
  {
    name: "LIG Colony",
    city: "Indore",
  },
  {
    name: "Luv Kush Square",
    city: "Indore",
  },
  {
    name: "Kalani Nagar",
    city: "Indore",
  },
  {
    name: "Magarkheda",
    city: "Indore",
  },
  {
    name: "Gumasta Nagar",
    city: "Indore",
  },
  {
    name: "South Tukoganj",
    city: "Indore",
  },
  {
    name: "Nanda Nagar",
    city: "Indore",
  },
  {
    name: "Scheme No 94",
    city: "Indore",
  },
  {
    name: "Sangam Nagar",
    city: "Indore",
  },

  {
    name: "Alok Nagar",
    city: "Indore",
  },
  {
    name: "Anurag Nagar",
    city: "Indore",
  },
  {
    name: "Snehlataganj",
    city: "Indore",
  },
  {
    name: "Sapna Sangeeta Road",
    city: "Indore",
  },
  {
    name: "Vasant Vihar",
    city: "Indore",
  },
  {
    name: "Mangaliya Sadak",
    city: "Indore",
  },
  {
    name: "Paliya",
    city: "Indore",
  },
  {
    name: "Anoop Nagar",
    city: "Indore",
  },
  {
    name: "Bangali Square",
    city: "Indore",
  },
  {
    name: "Arandia",
    city: "Indore",
  },
  {
    name: "Balya Kheda",
    city: "Indore",
  },
  {
    name: "Geeta Bhavan",
    city: "Indore",
  },
  {
    name: "Musakhedi",
    city: "Indore",
  },
  {
    name: "Mig Colony",
    city: "Indore",
  },
  {
    name: "Ring Road",
    city: "Indore",
  },
  {
    name: "Jakhiya",
    city: "Indore",
  },
  {
    name: "Khatiwala Tank",
    city: "Indore",
  },
  {
    name: "Palsikar Colony",
    city: "Indore",
  },
  {
    name: "Rajwada",
    city: "Indore",
  },
  {
    name: "Kordia Barda",
    city: "Indore",
  },
  {
    name: "Mundla Nayta",
    city: "Indore",
  },
  {
    name: "Pithampur Road",
    city: "Indore",
  },
  {
    name: "Bakhtawar Ram Nagar",
    city: "Indore",
  },
  {
    name: "Pigdambar",
    city: "Indore",
  },
  {
    name: "Rambag",
    city: "Indore",
  },
  {
    name: "Vishnupuri Colony",
    city: "Indore",
  },
  {
    name: "Shri Nagar Extension",
    city: "Indore",
  },
  {
    name: "Annapurna Nagar",
    city: "Indore",
  },
  {
    name: "Palasia",
    city: "Indore",
  },
  {
    name: "Budhaniya",
    city: "Indore",
  },
  {
    name: "Gokul Nagar",
    city: "Indore",
  },
  {
    name: "Morod",
    city: "Indore",
  },
  {
    name: "Sanchar Nagar Extention",
    city: "Indore",
  },
  {
    name: "NH3 Agra Mumbai Highway",
    city: "Indore",
  },
  {
    name: "Vallabh Nagar",
    city: "Indore",
  },
  {
    name: "Indrapuri Colony",
    city: "Indore",
  },
  {
    name: "Vaishali Nagar",
    city: "Indore",
  },
  {
    name: "Banganga",
    city: "Indore",
  },
  {
    name: "Shiv Dham Colony",
    city: "Indore",
  },
  {
    name: "Panda",
    city: "Indore",
  },
  {
    name: "Vandana Nagar",
    city: "Indore",
  },
  {
    name: "Sanawadia",
    city: "Indore",
  },
  {
    name: "Dhanvantri Nagar",
    city: "Indore",
  },
  {
    name: "Panchderiya",
    city: "Indore",
  },
  {
    name: "Sainath Colony",
    city: "Indore",
  },
  {
    name: "Tejpur Gadbadi",
    city: "Indore",
  },
  {
    name: "Sawer",
    city: "Indore",
  },
  {
    name: "Malviya Nagar",
    city: "Indore",
  },
  {
    name: "Malharganj",
    city: "Indore",
  },
  {
    name: "Chandan Nagar",
    city: "Indore",
  },
  {
    name: "Mahaveer Nagar",
    city: "Indore",
  },
  {
    name: "Barodiya Ema",
    city: "Indore",
  },
  {
    name: "Usha Nagar Extension",
    city: "Indore",
  },
  {
    name: "Nehru Nagar",
    city: "Indore",
  },
  {
    name: "Baliyakhedi",
    city: "Indore",
  },
  {
    name: "Sirpur",
    city: "Indore",
  },
  {
    name: "Choral",
    city: "Indore",
  },
  {
    name: "Green Park Colony",
    city: "Indore",
  },
  {
    name: "Scheme 54 PU4",
    city: "Indore",
  },
  {
    name: "Sindhi Colony",
    city: "Indore",
  },
  {
    name: "Karolbagh",
    city: "Indore",
  },
  {
    name: "Scheme no 53",
    city: "Indore",
  },
  {
    name: "New palasia",
    city: "Indore",
  },
  {
    name: "Scheme no 74",
    city: "Indore",
  },
  {
    name: "Chikitsak nagar",
    city: "Indore",
  },
  {
    name: "Veena nagar",
    city: "Indore",
  },
  {
    name: "Mansorawar nagar",
    city: "Indore",
  },
  {
    name: "Bapat square",
    city: "Indore",
  },
  {
    name: "Sukliya",
    city: "Indore",
  },
  {
    name: "Royal banglow",
    city: "Indore",
  },
  {
    name: "Dewas Naka",
    city: "Indore",
  },
  {
    name: "Industrial house",
    city: "Indore",
  },
  {
    name: "Khajrana",
    city: "Indore",
  },
  {
    name: "Atal dwar",
    city: "Indore",
  },
  {
    name: "Anand bazar",
    city: "Indore",
  },
  {
    name: "Super corridor",
    city: "Indore",
  },
  {
    name: "Lasudia mori",
    city: "Indore",
  },
  {
    name: "Scheme no 136",
    city: "Indore",
  },
  {
    name: "Scheme no 140",
    city: "Indore",
  },
  {
    name: "Hira nagar",
    city: "Indore",
  },
];

export default function Addproperty() {
  const [images, setImages] = React.useState([]);
  // console.log("images", images);
  const [withowner, setWithowner] = useState();
  // console.log("selectedImages===>", images.length, typeof images);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [furnishing1, setfurnishing] = React.useState("");
  // console.log("furnishing1-->", furnishing1);
  const [localityValue, setLocalityValue] = useState("");
  // console.log("localityValue-->", localityValue);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestionsBox, setShowSuggestionsBox] = useState(false);
  const [showSuggestionsBox1, setShowSuggestionsBox1] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSuggestions1, setShowSuggestions1] = useState(false);
  const [filttersociety, setfiltterSociety] = useState([]);
  const [societyName, setsocietyName] = useState("");
  // console.log("societyName - ", societyName);
  const [propertyVideoAllow, setPropertyVideoAllow] = useState(0);
  const { register, handleSubmit, watch, reset } = useForm();
  let history = useNavigate();
  var FormData = require("form-data");
  // Availabe From--------
  // Get today's date in the format "YYYY-MM-DD"
  const today = new Date().toISOString().split("T")[0];

  // Initialize the selectedDate state with an empty string
  const [selectedDate, setSelectedDate] = useState("");
  // console.log("selectedDate", selectedDate);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setLocalityValue(value);
    setShowSuggestionsBox(true);
  };

  const handleInputChange1 = async (event) => {
    const value = event.target.value;
    setsocietyName(value);
    const res = await getApihandler(`/getSocietyList/${value}`);
    console.log("res---->", res);
    setfiltterSociety(res.data);
    setShowSuggestions1(true);
    setShowSuggestionsBox1(true);
  };

  const handleFilterData = () => {
    // Filter suggestions based on the input value
    const filteredSuggestions = arr.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(localityValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setShowSuggestionsBox(false);
    setLocalityValue(suggestion.name);
    setShowSuggestions(false);
  };

  const handleSuggestionClick1 = (value) => {
    setShowSuggestions1(false);
    setShowSuggestionsBox1(false);
    setsocietyName(value);
  };

  useEffect(() => {
    if (localityValue !== "" && showSuggestionsBox) {
      handleFilterData();
    } else if (localityValue === "" || !showSuggestionsBox) {
      setShowSuggestions(false);
    }
  }, [localityValue]);

  // console.log("checked========>", furnishing1);
  const handleChange1 = (event) => {
    setfurnishing("Unfurnished");
    setChecked1(event.target.checked);
    setChecked2(false);
    setChecked3(false);
  };

  const handleChange2 = (event) => {
    setfurnishing("Semi Furnished");
    setChecked2(event.target.checked);
    setChecked1(false);
    setChecked3(false);
  };

  const handleChange3 = (event) => {
    setfurnishing("Fully Furnished");
    setChecked3(event.target.checked);
    setChecked1(false);
    setChecked2(false);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const selectedImages = Array.from(files);
    setImages(selectedImages);
  };

  const onsubmit = async (value) => {
    // console.log("val==>>", value);
    const id = localStorage.getItem("UserId");
    const {
      propertyName,
      bhk_of_house,
      buildUpArea,
      propertyType,
      price,
      construction_year,
      city,
      available_for,
      deposite_money,
      description,
      bathrooms,
      carpetArea,
      nearBy,
      floor,
      facing,
      balcony,
      construction_status,
      posted_by,
      purchase_type,
      rera_approved,
      campus_type,
      owner_availability,
      independent,
      pet_friendly,
      tour360,
      buildUp_type,
      society,
      // --------
      availableFrom,
      // Furnishing Modules -----------
      light,
      modularKitchen,
      ac,
      sofa,
      cupboard,
      tv,
      fan,
      bed,
    } = value;
    if (furnishing1 === "Unfurnished") {
      const formData = new FormData();
      formData.append("propertyName", propertyName);
      formData.append("bhk_of_house", bhk_of_house);
      formData.append("buildUpArea", buildUpArea);
      formData.append("propertyType", propertyType);
      formData.append("price", price);
      formData.append("construction_year", construction_year);
      formData.append("city", city);
      formData.append("available_for", available_for);
      formData.append("deposite_money", deposite_money);
      formData.append("description", description);
      formData.append("bathrooms", bathrooms);
      formData.append("carpetArea", carpetArea);
      formData.append("nearBy", nearBy);
      formData.append("floor", floor);
      formData.append("facing", facing);
      formData.append("balcony", balcony);
      formData.append("construction_status", construction_status);
      formData.append("posted_by", posted_by);
      formData.append("purchase_type", purchase_type);
      formData.append("rera_approved", rera_approved);
      formData.append("campus_type", campus_type);
      formData.append("owner_availability", owner_availability);
      formData.append("independent", independent);
      formData.append("pet_friendly", pet_friendly);
      formData.append("tour360", tour360);
      formData.append("buildUp_type", buildUp_type);
      formData.append("society", societyName);
      // Without Register-----
      for (let i = 0; i < images.length; i++) {
        formData.append("propertyImages", images[i]);
      }
      formData.append("address", localityValue);
      formData.append("availableFrom", selectedDate);
      formData.append("furnishing", furnishing1);
      console.log("formdata-->", formData);
      const postResponse = await postApihandlerAccessToken(
        `/addUserProperty/${id}`,
        formData
      );
      console.log("Unfurnished Post Property=>>>", postResponse);
      console.log("Unfurnished Submitted");
      if (postResponse.status === 200) {
        Swal.fire({
          position: "middle-centre",
          icon: "success",
          title: "Added Successfully!",
          showConfirmButton: false,
          timer: 2000,
        });

        history("/properties/list/all");
      } else {
        swal(
          "Sorry!",
          `${postResponse.error.response.data.message}`,
          "error"
        ).then((value) => {});
      }
    } else {
      const formData = new FormData();
      formData.append("propertyName", propertyName);
      formData.append("bhk_of_house", bhk_of_house);
      formData.append("buildUpArea", buildUpArea);
      formData.append("propertyType", propertyType);
      formData.append("price", price);
      formData.append("construction_year", construction_year);
      formData.append("city", city);
      formData.append("available_for", available_for);
      formData.append("deposite_money", deposite_money);
      formData.append("description", description);
      formData.append("bathrooms", bathrooms);
      formData.append("carpetArea", carpetArea);
      formData.append("nearBy", nearBy);
      formData.append("floor", floor);
      formData.append("facing", facing);
      formData.append("balcony", balcony);
      formData.append("construction_status", construction_status);
      formData.append("posted_by", posted_by);
      formData.append("purchase_type", purchase_type);
      formData.append("rera_approved", rera_approved);
      formData.append("campus_type", campus_type);
      formData.append("owner_availability", owner_availability);
      formData.append("independent", independent);
      formData.append("pet_friendly", pet_friendly);
      formData.append("tour360", tour360);
      formData.append("buildUp_type", buildUp_type);
      formData.append("society", societyName);
      // Furnishing Modules----
      formData.append("light", light);
      formData.append("modularKitchen", modularKitchen);
      formData.append("ac", ac);
      formData.append("sofa", sofa);
      formData.append("cupboard", cupboard);
      formData.append("tv", tv);
      formData.append("fan", fan);
      formData.append("bed", bed);
      // Without Register-----
      for (let i = 0; i < images.length; i++) {
        formData.append("propertyImages", images[i]);
      }
      formData.append("address", localityValue);
      formData.append("furnishing", furnishing1);
      formData.append("availableFrom", selectedDate);
      const postResponse = await postApihandlerAccessToken(
        `/addUserProperty/${id}`,
        formData
      );
      console.log("Furnished Post Properties - ", postResponse);
      console.log("furnished Submitted");

      if (postResponse.status === 200) {
        Swal.fire({
          position: "middle-centre",
          icon: "success",
          title: "Added Successfully!",
          showConfirmButton: false,
          timer: 2000,
        });

        history("/properties/list/all");
      } else {
        swal(
          "Sorry!",
          `${postResponse.error.response.data.message}`,
          "error"
        ).then((value) => {});
      }
    }
  };

  // Availabe From--------
  // Handle date change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // Function to clear the date
  const clearDate = () => {
    setSelectedDate("");
  };

  return (
    <>
      <br />
      <br />
      <div className="mx-md-5 mx-3 mt-3">
        <h2 className="text-lg-start text-center">Add Property</h2>
        <form onSubmit={handleSubmit(onsubmit)}>
          <Grid container className={styles.outer_container_grid}>
            {/* Property Name */}
            <Grid lg={4} xs={12}>
              <TextField
                fullWidth
                variant="standard"
                id="standard-basic"
                label="Property Name*"
                //   onChange={(e) => setPropertyName(e.target.value)}
                {...register("propertyName")}
              />
            </Grid>
            {/* Select BHK */}
            <Grid lg={5} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select BHK*
                </InputLabel>
                <Select
                  sx={{
                    mt: 2,
                    minWidth: { lg: 356, xs: 230 },
                    maxWidth: "100%",
                  }}
                  variant="standard"
                  id="demo-simple-select-standard"
                  {...register("bhk_of_house")}
                >
                  <MenuItem value="">
                    <em>Select BHK...</em>
                  </MenuItem>
                  <MenuItem value="1 BHK">1 BHK</MenuItem>
                  <MenuItem value="2 BHK">2 BHK</MenuItem>
                  <MenuItem value="3 BHK">3 BHK</MenuItem>
                  <MenuItem value="4 BHK">4 BHK</MenuItem>
                  <MenuItem value="5 BHK">5 BHK</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* City */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="City*"
                variant="standard"
                //   onChange={(e) => setAddress(e.target.value)}
                name="city"
                {...register("city")}
              />
            </Grid>
            {/* Locality/Address */}
            <Grid lg={3} xs={12}>
              <div className="searchData-field-wrapper">
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  value={localityValue}
                  onChange={handleInputChange}
                  label="Locality*"
                />
                {showSuggestions && (
                  <ul className="searchData-wrapper">
                    {suggestions !== undefined &&
                    suggestions !== null &&
                    suggestions.length !== 0 ? (
                      suggestions.map((suggestion, index) => (
                        <li
                          style={{ textAlign: "start", listStyle: "none" }}
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.name}
                        </li>
                      ))
                    ) : (
                      <li style={{ textAlign: "start", listStyle: "none" }}>
                        No Result Found
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </Grid>
            {/* Society */}
            <Grid lg={3} xs={12}>
              <div className="searchData-field-wrapper">
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  value={societyName}
                  onChange={handleInputChange1}
                  label="Society"
                />
                {showSuggestions1 && (
                  <ul
                    className={`searchData-wrapper d-${
                      societyName.length !== 0 ? "block" : "none"
                    }`}
                  >
                    {filttersociety !== undefined &&
                    filttersociety !== null &&
                    filttersociety.length !== 0 ? (
                      filttersociety.map((filttersociety, index) => (
                        <li
                          style={{ textAlign: "start", listStyle: "none" }}
                          key={index}
                          onClick={() =>
                            handleSuggestionClick1(filttersociety.soceityName)
                          }
                        >
                          {filttersociety.soceityName}
                        </li>
                      ))
                    ) : (
                      <li style={{ textAlign: "start", listStyle: "none" }}>
                        No Result Found
                      </li>
                    )}
                  </ul>
                )}
              </div>
              {/* <TextField
                fullWidth
                id="standard-basic"
                label="Society"
                variant="standard"
                name="society"
                {...register("society")}
              /> */}
            </Grid>
            {/* BuildUp Area */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                variant="standard"
                label="BuildUp Area*"
                {...register("buildUpArea")}
              />
            </Grid>
            {/* Carpet Area */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                variant="standard"
                label="Carpet Area"
                {...register("carpetArea")}
              />
            </Grid>
            {/* Property Type */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Property Type*
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="demo-simple-select-standard"
                  {...register("propertyType")}
                >
                  <MenuItem value="">
                    <em>Property Type...</em>
                  </MenuItem>
                  <MenuItem value="Rent">Rent</MenuItem>
                  <MenuItem value="Buy">Sell</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Buildup Type */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Buildup Type*
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="demo-simple-select-standard"
                  {...register("buildUp_type")}
                >
                  <MenuItem value="">
                    <em>Buildup Type...</em>
                  </MenuItem>
                  <MenuItem value="Residential Land">Residential Land</MenuItem>
                  <MenuItem value="Residential Apartment">
                    Residential Apartment
                  </MenuItem>
                  <MenuItem value="Independent House">
                    Independent House
                  </MenuItem>
                  <MenuItem value="Villa Independent">
                    Villa Independent
                  </MenuItem>
                  <MenuItem value="Builder">Builder</MenuItem>
                  <MenuItem value="Floor">Floor</MenuItem>

                  <MenuItem value="Studio Apartment">
                    1 RK/Studio Apartment
                  </MenuItem>
                  <MenuItem value="Serviced Apartments">
                    Serviced Apartments
                  </MenuItem>
                  <MenuItem value="Farm House">Farm House</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Floor No. */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Floor No."
                variant="standard"
                type="number"
                {...register("floor")}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            {/* Nearby */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Nearby"
                variant="standard"
                //   onChange={(e) => setAddress(e.target.value)}
                {...register("nearBy")}
              />
            </Grid>
            {/* Available For */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Available For*
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="available_for"
                  {...register("available_for")}
                >
                  <MenuItem value="">
                    <em>Available For...</em>
                  </MenuItem>
                  <MenuItem value="Family">Family</MenuItem>
                  <MenuItem value="Single Woman">Single Woman</MenuItem>
                  <MenuItem value="Single Man">Single Man</MenuItem>
                  <MenuItem value="Tenants with Company Lease">
                    Tenants with Company Lease
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Campus Type */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Campus Type*
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="campus_type"
                  {...register("campus_type")}
                >
                  <MenuItem value="">
                    <em>Campus Type...</em>
                  </MenuItem>
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Covered">Covered</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Construction Status */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Construction Status
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="construction_status"
                  {...register("construction_status")}
                >
                  <MenuItem value="">
                    <em>Construction Status...</em>
                  </MenuItem>
                  <MenuItem value="Ready to Move">Ready to Move</MenuItem>
                  <MenuItem value="Under Construction">
                    Under Construction
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Indepedent Type */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Indepedent Type
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="independent"
                  {...register("independent")}
                >
                  <MenuItem value="">
                    <em>Indepedent Type...</em>
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Pet Friendly */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Pet Friendly
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="pet_friendly"
                  {...register("pet_friendly")}
                >
                  <MenuItem value="">
                    <em> Pet Friendly...</em>
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* tour360 */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select 360 Tour
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 435 } }}
                  variant="standard"
                  id="tour360"
                  onChange={(e) => setPropertyVideoAllow(e.target.value)}
                  // {...register("tour360")}
                >
                  <MenuItem value="">
                    <em>360 Tour...</em>
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* 360 Video */}
            {/* <Grid
              xs={6}
              className={`d-${
                propertyVideoAllow === 0 ? "none" : "flex"
              } align-items-center justify-content-end py-3 `}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
                className="me-1"
              >
                Property Video:
              </Typography>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="videos"
                  name="videos"
                  type="file"
                  {...register("360videos")}
                />
              </div>
            </Grid> */}
            {/* With Owner */}
            <Grid
              container
              sm={10}
              xs={12}
              className="search_section_outer_container pt-3 justify-content-center"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={withowner}
                name="owner_availability"
                {...register("owner_availability")}
              >
                <div>
                  <FormControlLabel
                    value="With Owner"
                    control={
                      <Radio
                        sx={{
                          color: "rgba(34, 34, 34, 1)",
                          "&.Mui-checked": {
                            color: "rgba(2, 201, 136, 1)",
                          },
                        }}
                        onClick={() => {
                          setWithowner("With Owner");
                        }}
                      />
                    }
                    label="With Owner*"
                  />
                  <FormControlLabel
                    value="Without Owner"
                    control={
                      <Radio
                        sx={{
                          color: "rgba(34, 34, 34, 1)",
                          "&.Mui-checked": {
                            color: "rgba(2, 201, 136, 1)",
                          },
                        }}
                        onClick={() => {
                          setWithowner("Without Owner");
                        }}
                      />
                    }
                    label="Without Owner*"
                  />
                </div>
              </RadioGroup>
            </Grid>
            {/* Posted By */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Posted By
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="posted_by"
                  {...register("posted_by")}
                >
                  <MenuItem value="">
                    <em>Posted By...</em>
                  </MenuItem>
                  <MenuItem value="Owner">Owner</MenuItem>
                  <MenuItem value="Builder">Builder</MenuItem>
                  <MenuItem value="Dealer">Dealer</MenuItem>
                  <MenuItem value="Feature Dealer">Feature Dealer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Purchase Type */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Purchase Type
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="purchase_type"
                  {...register("purchase_type")}
                >
                  <MenuItem value="">
                    <em>Purchase Type...</em>
                  </MenuItem>
                  <MenuItem value="Resale">Resale</MenuItem>
                  <MenuItem value="New Booking">New Booking</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* RERA Approved */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select RERA Approved
                </InputLabel>
                <Select
                  sx={{ minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="rera_approved"
                  {...register("rera_approved")}
                >
                  <MenuItem value="">
                    <em>RERA Approved...</em>
                  </MenuItem>
                  <MenuItem value="RERA Approved Properties">
                    RERA Approved Properties
                  </MenuItem>
                  <MenuItem value="RERA Registered Dealers">
                    RERA Registered Dealers
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Furnished */}
            <Grid
              lg={10}
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
              className="pt-3"
            >
              <FormControlLabel
                checked={checked1}
                control={<Switch />}
                label="Unfurnished*"
                onChange={handleChange1}
                inputProps={{ "aria-label": "controlled" }}
              />
              <FormControlLabel
                checked={checked2}
                control={<Switch />}
                label="Semi Furnished*"
                onChange={handleChange2}
                inputProps={{ "aria-label": "controlled" }}
              />
              <FormControlLabel
                checked={checked3}
                control={<Switch />}
                label="Fully Furnished*"
                onChange={handleChange3}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            {furnishing1 === "" || furnishing1 === "Unfurnished" ? (
              ""
            ) : (
              <Grid lg={10} xs={12}>
                <FormGroup sx={{ display: "block" }} disabled>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Light"
                    {...register("light")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Modular Kitchen"
                    {...register("modularKitchen")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="AC"
                    {...register("ac")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Sofa"
                    {...register("sofa")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Cupboard"
                    {...register("cupboard")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="TV"
                    {...register("tv")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Fan"
                    {...register("fan")}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Bed"
                    {...register("bed")}
                  />
                </FormGroup>
              </Grid>
            )}
            {/* Bathroom */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Bathroom
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="demo-simple-select-standard"
                  {...register("bathrooms")}
                >
                  <MenuItem value="">
                    <em>Select Bathroom...</em>
                  </MenuItem>
                  <MenuItem value={1}>1 Bathroom</MenuItem>
                  <MenuItem value={2}>2 Bathroom</MenuItem>
                  <MenuItem value={3}>3 Bathroom</MenuItem>
                  <MenuItem value={4}>4 Bathroom</MenuItem>
                  <MenuItem value={5}>5 Bathroom</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Balcony */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Balcony
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="demo-simple-select-standard"
                  {...register("balcony")}
                >
                  <MenuItem value="">
                    <em>Select Balcony...</em>
                  </MenuItem>
                  <MenuItem value={0}>No Balcony</MenuItem>
                  <MenuItem value={1}>1 Balcony</MenuItem>
                  <MenuItem value={2}>2 Balcony</MenuItem>
                  <MenuItem value={3}>3 Balcony</MenuItem>
                  <MenuItem value={4}>4 Balcony</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Facing */}
            <Grid lg={3} xs={12}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Select Facing
                </InputLabel>
                <Select
                  sx={{ mt: 2, minWidth: { lg: 214, xs: 230 } }}
                  variant="standard"
                  id="demo-simple-select-standard"
                  {...register("facing")}
                >
                  <MenuItem value="">
                    <em>Select Facing...</em>
                  </MenuItem>
                  <MenuItem value="East">East</MenuItem>
                  <MenuItem value="West">West</MenuItem>
                  <MenuItem value="North">North</MenuItem>
                  <MenuItem value="South">South</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Price */}
            <Grid lg={3} xs={12}>
              <TextField
                type="number"
                fullWidth
                id="standard-basic"
                label="Price*"
                variant="standard"
                //   onChange={(e) => setPrice(e.target.value)}
                {...register("price")}
              />
            </Grid>
            {/* Construction Year */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                variant="standard"
                label="Construction Year*"
                //   onChange={(e) => setConstruction(e.target.value)}
                {...register("construction_year")}
              />
            </Grid>
            {/* Deposite money */}
            <Grid lg={3} xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Deposite Money"
                variant="standard"
                //   onChange={(e) => setDeposite(e.target.value)}
                {...register("deposite_money")}
              />
            </Grid>
            {/* Available From */}
            <Grid
              container
              lg={4}
              sm={8}
              xs={12}
              className="search_section_outer_container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
              >
                Available From*:
              </Typography>
              <div className="select-date-inputWrapper">
                <div className="date-input">
                  <input
                    type="date"
                    id="dateInput"
                    name="dateInput"
                    value={selectedDate}
                    min={today}
                    onChange={handleDateChange}
                    // required
                  />
                  {selectedDate && (
                    <button className="clear-button" onClick={clearDate}>
                      &#10006;
                    </button>
                  )}
                </div>
              </div>
            </Grid>
            {/* Images */}
            <Grid
              lg={6}
              xs={12}
              className="d-flex align-items-center justify-content-lg-end py-3"
            >
              <Typography
                sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "400" }}
                className="me-1"
              >
                Images:
              </Typography>
              <div>
                <input
                  type="file"
                  name="images"
                  multiple
                  onChange={handleImageChange}
                  className="w-100"
                />
              </div>
            </Grid>
            {/* Description */}
            <Grid lg={10} xs={12}>
              <TextField
                multiline
                rows={5}
                fullWidth
                id="standard-basic"
                label="Description*"
                {...register("description")}
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex" }} className={styles.addProperty_Btn}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: "12px",
                backgroundColor: "#004274",
                fontWeight: "700",
              }}
              className="py-3"
            >
              Add Property
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
