import React from "react";
import { Button, Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import Localities1 from "../../Images/Home/Locality.jpg";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const PopularLocalities = () => {
  const localitiesData = [
    { localityImg: Localities1, name: "Nipania", city: "Indore" },
    { localityImg: Localities1, name: "AB BypassRoad", city: "Indore" },
    { localityImg: Localities1, name: "Mahalakshmi Nagar", city: "Indore" },
    {
      localityImg: Localities1,
      name: "Bicholi Mardana",
      city: "Indore",
    },
    { localityImg: Localities1, name: "Rau", city: "Indore" },
    { localityImg: Localities1, name: "Mhow", city: "Indore" },
    { localityImg: Localities1, name: "Khandwa Road", city: "Indore" },
    { localityImg: Localities1, name: "Bengali Square", city: "Indore" },
    { localityImg: Localities1, name: "Kanadia Road", city: "Indore" },
    { localityImg: Localities1, name: "Talawali Chanda", city: "Indore" },
    { localityImg: Localities1, name: "Rajendra Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Silicon City", city: "Indore" },
    { localityImg: Localities1, name: "Pipliyahana", city: "Indore" },
    { localityImg: Localities1, name: "Kanadiya", city: "Indore" },
    { localityImg: Localities1, name: "Scheme No 54", city: "Indore" },
    { localityImg: Localities1, name: "Saket Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Scheme No 114", city: "Indore" },
    { localityImg: Localities1, name: "Jhalaria", city: "Indore" },
    { localityImg: Localities1, name: "AB Road indore", city: "Indore" },
    { localityImg: Localities1, name: "Ida Scheme No 134", city: "Indore" },
    { localityImg: Localities1, name: "Devguradia", city: "Indore" },
    { localityImg: Localities1, name: "Tilak Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Bijalpur", city: "Indore" },
    { localityImg: Localities1, name: "Bhawrasla", city: "Indore" },
    { localityImg: Localities1, name: "Bada Bangarda", city: "Indore" },
    { localityImg: Localities1, name: "Niranjanpur", city: "Indore" },
    { localityImg: Localities1, name: "Scheme No 78", city: "Indore" },
    { localityImg: Localities1, name: "Race Course Road", city: "Indore" },
    { localityImg: Localities1, name: "Old Palasia", city: "Indore" },
    { localityImg: Localities1, name: "MR 11", city: "Indore" },
    { localityImg: Localities1, name: "Nainod", city: "Indore" },
    { localityImg: Localities1, name: "Bicholi Hapsi", city: "Indore" },
    { localityImg: Localities1, name: "Kesar Bagh", city: "Indore" },
    { localityImg: Localities1, name: "Tejaji Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Palakhedi", city: "Indore" },
    { localityImg: Localities1, name: "Scheme No 71", city: "Indore" },
    { localityImg: Localities1, name: "Sai Kripa Colony", city: "Indore" },
    { localityImg: Localities1, name: "Manorama Ganj", city: "Indore" },
    { localityImg: Localities1, name: "Scheme No 103", city: "Indore" },
    { localityImg: Localities1, name: "Gulab Bagh", city: "Indore" },
    { localityImg: Localities1, name: "Navlakha", city: "Indore" },
    { localityImg: Localities1, name: "Rau Pitampur Road", city: "Indore" },
    { localityImg: Localities1, name: "Rangwasa", city: "Indore" },
    { localityImg: Localities1, name: "Tigaria Badshah", city: "Indore" },
    { localityImg: Localities1, name: "Pipliya Kumar", city: "Indore" },
    { localityImg: Localities1, name: "LIG Colony", city: "Indore" },
    { localityImg: Localities1, name: "Luv Kush Square", city: "Indore" },
    { localityImg: Localities1, name: "Kalani Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Magarkheda", city: "Indore" },
    { localityImg: Localities1, name: "Gumasta Nagar", city: "Indore" },
    { localityImg: Localities1, name: "South Tukoganj", city: "Indore" },
    { localityImg: Localities1, name: "Nanda Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Scheme No 94", city: "Indore" },
    { localityImg: Localities1, name: "Sangam Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Alok Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Anurag Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Snehlataganj", city: "Indore" },
    { localityImg: Localities1, name: "Sapna Sangeeta Road", city: "Indore" },
    { localityImg: Localities1, name: "Vasant Vihar", city: "Indore" },
    { localityImg: Localities1, name: "Mangaliya Sadak", city: "Indore" },
    { localityImg: Localities1, name: "Paliya", city: "Indore" },
    { localityImg: Localities1, name: "Anoop Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Bangali Square", city: "Indore" },
    { localityImg: Localities1, name: "Arandia", city: "Indore" },
    { localityImg: Localities1, name: "Balya Kheda", city: "Indore" },
    { localityImg: Localities1, name: "Geeta Bhavan", city: "Indore" },
    { localityImg: Localities1, name: "Musakhedi", city: "Indore" },
    { localityImg: Localities1, name: "Mig Colony", city: "Indore" },
    { localityImg: Localities1, name: "Ring Road", city: "Indore" },
    { localityImg: Localities1, name: "Jakhiya", city: "Indore" },
    { localityImg: Localities1, name: "Khatiwala Tank", city: "Indore" },
    { localityImg: Localities1, name: "Palsikar Colony", city: "Indore" },
    { localityImg: Localities1, name: "Rajwada", city: "Indore" },
    { localityImg: Localities1, name: "Kordia Barda", city: "Indore" },
    { localityImg: Localities1, name: "Mundla Nayta", city: "Indore" },
    { localityImg: Localities1, name: "Pithampur Road" },
    { localityImg: Localities1, name: "Bakhtawar Ram Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Pigdambar", city: "Indore" },
    { localityImg: Localities1, name: "Rambag", city: "Indore" },
    { localityImg: Localities1, name: "Vishnupuri Colony", city: "Indore" },
    { localityImg: Localities1, name: "Shri Nagar Extension", city: "Indore" },
    { localityImg: Localities1, name: "Annapurna Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Palasia", city: "Indore" },
    { localityImg: Localities1, name: "Budhaniya", city: "Indore" },
    { localityImg: Localities1, name: "Gokul Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Morod", city: "Indore" },
    {
      localityImg: Localities1,
      name: "Sanchar Nagar Extention",
      city: "Indore",
    },
    {
      localityImg: Localities1,
      name: "NH3 Agra Mumbai Highway",
      city: "Indore",
    },
    { localityImg: Localities1, name: "Vallabh Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Indrapuri Colony", city: "Indore" },
    { localityImg: Localities1, name: "Vaishali Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Banganga", city: "Indore" },
    { localityImg: Localities1, name: "Shiv Dham Colony", city: "Indore" },
    { localityImg: Localities1, name: "Panda", city: "Indore" },
    { localityImg: Localities1, name: "Vandana Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Sanawadia", city: "Indore" },
    { localityImg: Localities1, name: "Dhanvantri Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Panchderiya", city: "Indore" },
    { localityImg: Localities1, name: "Sainath Colony", city: "Indore" },
    { localityImg: Localities1, name: "Tejpur Gadbadi", city: "Indore" },
    { localityImg: Localities1, name: "Sawer", city: "Indore" },
    { localityImg: Localities1, name: "Malviya Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Malharganj", city: "Indore" },
    { localityImg: Localities1, name: "Chandan Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Mahaveer Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Barodiya Ema", city: "Indore" },
    { localityImg: Localities1, name: "Usha Nagar Extension", city: "Indore" },
    { localityImg: Localities1, name: "Nehru Nagar", city: "Indore" },
    { localityImg: Localities1, name: "Baliyakhedi" },
    { localityImg: Localities1, name: "Sirpur", city: "Indore" },
    { localityImg: Localities1, name: "Choral", city: "Indore" },
    { localityImg: Localities1, name: "Green Park Colony", city: "Indore" },
    { localityImg: Localities1, name: "Scheme 54 PU4", city: "Indore" },
    { localityImg: Localities1, name: "Sindhi Colony", city: "Indore" },
    { localityImg: Localities1, name: "Karolbagh", city: "Indore" },
  ];

  return (
    <section
      className="popular-localities-explore py-5"
      style={{ overflow: "hidden" }}
    >
      <Container maxWidth="100%" className="">
        <Grid
          container
          spacing={2}
          className="justify-content-between flex-lg-row flex-column flex-lg-nowrap"
        >
          <Grid
            item
            lg={12}
            className="py-lg-5 py-3 d-flex align-items-center justify-content-md-start justify-content-center"
          >
            <Box className="text-lg-start text-center">
              <Typography
                variant="h5"
                component="h4"
                className="smallHeading_TwoHeadings"
              >
                Explore Property in Popular Localities
              </Typography>
              {/* Desktop Versions -x-x-x-x-x-x--x-x-x-x-x-x */}
              <Grid
                container
                spacing={1}
                className="justify-content-md-between d-md-flex d-none justify-content-center flex-md-row flex-column flex-lg-wrap pt-4"
              >
                {/* {localitiesData.slice(0, 8).map((val, ind) => { */}
                {localitiesData.slice(0, 8).map((val, ind) => {
                  return (
                    <Grid
                      item
                      lg={3}
                      sm={5}
                      className="py-3 d-flex align-items-center"
                    >
                      <Grid container className="flex-nowrap">
                        <Grid item sm={3} className="me-3">
                          <img src={val.localityImg} alt="Localities" />
                        </Grid>
                        <Grid
                          item
                          sm={8}
                          className="d-flex align-items-start justify-content-center flex-column"
                        >
                          <Link
                            to={`/properties/${val.name}/all`}
                            style={{ color: "#000" }}
                          >
                            <Typography
                              variant="h5"
                              component="h6"
                              className="locality-title text-start"
                            >
                              {val.name}
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              {/* Mobile Versions -x-x-x-x-x-x--x-x-x-x-x-x */}
              <Grid
                container
                spacing={1}
                className="justify-content-center d-md-none d-flex flex-md-row flex-column flex-lg-wrap pt-4 overflow-hidden green-carousel"
              >
                <Carousel>
                  {localitiesData.slice(0, 8).map((val, ind) => {
                    return (
                      <Carousel.Item interval={2000} key={ind}>
                        <Link
                          to={`/properties/${val.name}/all`}
                          style={{ color: "#000" }}
                        >
                          <Grid
                            item
                            lg={12}
                            sm={5}
                            className="py-3 d-flex align-items-center justify-content-center flex-md-row flex-column"
                          >
                            <Grid item sm={4} xs={8} className="me-3">
                              <img src={val.localityImg} alt="Localities" />
                            </Grid>
                            <Grid
                              item
                              sm={8}
                              xs={12}
                              className="d-flex align-items-start justify-content-center flex-column mt-md-0 mt-4"
                            >
                              <Typography
                                variant="h5"
                                component="h6"
                                className="locality-title text-start"
                              >
                                {val.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PopularLocalities;
