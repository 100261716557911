import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import userimg from "../../Images/Universal/user.png";
import styles2 from "../../Css/userprofile.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShareIcon from "@mui/icons-material/Share";
import styles from "../../Css/property.module.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { getApihandler, imageUrl, postApihandler } from "../../Apihandler";
import Carousel from "react-bootstrap/Carousel";
import { RWebShare } from "react-web-share";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import swal from "sweetalert";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../Images/Universal/gharaaj-logo.svg";

const tabs = [
  { name: "Profile Detail", path: "/user-profile" },
  { name: "Change Password", path: "/change-password" },

  // { name: "Watchlist", path: "/wishlist" },
  {
    name: "Recommended Properties",
    path: "/recommended-properties",
  },
  {
    name: "My Properties",
    path: "/my-properties",
    color: `#02C988`,
  },
  { name: "My Visits", path: "/my-visits" },
  { name: "Track My Thing", path: "/track-my-thing" },
  // { name: "Bank Details", path: "#" },
  // { name: "Setting", path: "#" },
  { name: "Help", path: "/help" },
];

export default function MyPropertiesComponent() {
  const Navigate = useNavigate();
  const [loaderHide, setloaderHide] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [PropertiesList, setPropertiesList] = useState([]);
  const [userId, setuserId] = React.useState();
  // console.log("userId", userId);
  const [propertyId, setPropertyId] = useState();
  // console.log("propertyId - ", propertyId);
  // console.log("PropertiesList", PropertiesList);

  useEffect(() => {
    setuserId(localStorage.getItem("UserId"));
  }, []);

  useEffect(() => {
    if (userId !== undefined) {
      getProperties();
    }
  }, [userId]);

  useEffect(() => {
    if (propertyId !== undefined && userId !== undefined) {
      updateWatchlist();
    }
  }, [propertyId]);

  const getProperties = async () => {
    const response = await getApihandler(`/getUserProperties/id_${userId}`);
    // console.log("Property List", response.data);
    setPropertiesList(response.data);
  };

  const updateWatchlist = async () => {
    const response = await postApihandler(
      `/addPropertyToWishList/${userId}/${propertyId}`
    );
    // console.log("updateWatchlist====>", response);
    if (response.status === 200) {
      setPropertyId();
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Wishlist Added",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      setPropertyId();

      Swal.fire({
        position: "middle-centre",
        icon: "error",
        title: response.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  // Loader Wheel=x=x=xx=x=x
  const loaderAction = (val) => {
    // console.log("Loader Action Value - ", val);
    function loaderActivated() {
      setloaderHide(false);
    }
    function loaderDeactivated() {
      setloaderHide(true);
    }

    if (val === 0) {
      setTimeout(loaderDeactivated, 3000);
    } else {
      setTimeout(loaderActivated, 100);
    }
  };
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly" }}
        className="flex-md-row flex-column"
      >
        <Box
          sx={{ borderRight: "solid 1px #D2D2D2" }}
          className="ps-lg-3 ps-1 userProfile_TabSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            {/* <Avatar
              alt="Remy Sharp"
              src={userimg}
              sx={{ width: 100, height: 100 }}
            /> */}
            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={
                    {
                      // borderBottom: "1px solid #E7E7E7",
                      // marginBottom: "20px",
                    }
                  }
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          <Typography className={styles2.profiledetail_heading}>
            My Properties
          </Typography>
          {/* <Typography className={styles2.profiledetail_text_paragraph}>
            See history of your payment plan invoice.
          </Typography> */}
          <Grid lg={12} xs={12} sx={{ paddingY: "2rem" }} className="px-md-2">
            {PropertiesList !== undefined &&
            (PropertiesList !== null) & (PropertiesList.length !== 0) ? (
              PropertiesList.slice(0, 20).map((val, ind) => {
                {
                  loaderAction(1);
                }
                return (
                  <Card
                    key={ind}
                    sx={{
                      border: "1px solid #D2D2D2",
                      padding: "5px",
                      display: "flex",
                      boxShadow: "none",
                    }}
                    className="mb-4"
                  >
                    <Grid container>
                      <Grid lg={3} xs={12}>
                        <Link
                          to={`/property-details/${val._id}`}
                          className="h-100"
                        >
                          <div
                            className={`property-image-slider-wrapper d-${
                              val.propertyImages.length !== 0 ? "block" : "none"
                            }`}
                          >
                            <Carousel>
                              {val.propertyImages.map((imgsrc, i) => {
                                return (
                                  <Carousel.Item
                                    interval={3000 + ind * 1000}
                                    key={i}
                                  >
                                    <img
                                      src={`${imageUrl + imgsrc.images}`}
                                      alt=""
                                      style={{
                                        height: "10rem",
                                        borderRadius: "6px",
                                        objectFit: "cover",
                                        width: "100%",
                                      }}
                                    />
                                    <div
                                      className="property-image-overlay position-absolute"
                                      style={{
                                        height: "10rem",
                                        borderRadius: "6px",
                                        width: "100%",
                                        top: 0,
                                        background: "#0000007a",
                                      }}
                                    ></div>
                                    <div
                                      className="property-type position-absolute"
                                      style={{
                                        top: "2%",
                                        left: "2%",
                                        zIndex: "1",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: "#fff",
                                          color: "#222",
                                          textTransform: "capitalize",
                                          paddingTop: ".3rem",
                                          paddingBottom: ".3rem",
                                          fontSize: "16px",
                                        }}
                                        className="px-2"
                                      >
                                        {val.available_for[0]}
                                      </Button>
                                    </div>
                                    <div className="mobile-share d-md-none d-block">
                                      <RWebShare
                                        data={{
                                          text: "Web Share - Share Property",
                                          url: `http://gharaaj.com/#/property-details/${val._id}`,
                                          title: "Share Property",
                                        }}
                                        onClick={() =>
                                          console.log("shared successfully!")
                                        }
                                      >
                                        <IconButton sx={{ mr: 4 }}>
                                          <ShareIcon
                                            sx={{
                                              color: "#fff",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </IconButton>
                                      </RWebShare>
                                    </div>
                                    <div
                                      className={`premiumPropertyCarousel position-absolute d-${
                                        val.price > 500000 ? "block" : "none"
                                      }`}
                                      style={{
                                        top: "2%",
                                        right: "2%",
                                        zIndex: "1",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: "#EA3A91",
                                          color: "#fff",
                                          textTransform: "capitalize",
                                          paddingTop: ".3rem",
                                          paddingBottom: ".3rem",
                                          fontSize: "16px",
                                        }}
                                        className="px-2"
                                      >
                                        Premium
                                      </Button>
                                    </div>
                                  </Carousel.Item>
                                );
                              })}
                            </Carousel>
                          </div>
                          <div
                            className={`property-dummyImage-wrapper px-5 align-items-center rounded-2 justify-content-center d-${
                              val.propertyImages.length !== 0 ? "none" : "flex"
                            }`}
                            style={{
                              border: "1px solid rgb(0 0 0 / 18%",
                              height: "10rem",
                            }}
                          >
                            <img src={Logo} alt="property" />
                          </div>
                        </Link>
                      </Grid>
                      <Grid lg={8} xs={12}>
                        <Link to={`/property-details/${val._id}`}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // padding: "1rem",
                            }}
                            className="flex-md-row flex-column  pt-md-0 pt-2 px-md-2"
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Typography className="fs-5 fw-medium px-2 py-2 text-dark">
                                ₹{val.price}/-
                              </Typography>
                              <Typography className="fw-medium px-2 py-2 text-dark">
                                Deposit: ₹{val.deposite_money}
                              </Typography>
                            </div>
                            <div className="mt-md-0 mt-4 d-md-block d-none">
                              <RWebShare
                                data={{
                                  text: "Web Share - Share Property",
                                  url: `http://gharaaj.com/#/property-details/${val._id}`,
                                  title: "Share Property",
                                }}
                                onClick={() =>
                                  console.log("shared successfully!")
                                }
                              >
                                <IconButton sx={{ mr: 4 }}>
                                  <ShareIcon
                                    sx={{
                                      color: "#666666",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </RWebShare>
                            </div>
                          </div>
                          <Typography className="fw-medium px-3  text-dark pb-4 d-md-block d-none">
                            Property Id: {val._id}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              paddingLeft: "15px",
                            }}
                          >
                            <Link
                              to={`/property-details/${val._id}`}
                              style={{ color: "black" }}
                            >
                              {val.propertyName}
                            </Link>
                          </Typography>
                          <div>
                            <Link to={`/property-details/${val._id}`}>
                              <Grid
                                container
                                className="py-1 mx-3"
                                // sx={{ borderBottom: "1px solid #D2D2D2" }}
                              >
                                <Grid sm={3} xs={6} className="mb-md-0 mb-2">
                                  <Card
                                    sx={{
                                      padding: "0px",
                                      textAlign: "center",
                                      boxShadow: "none",
                                      // borderRight: "1px solid #DEDEDE",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                      }}
                                      className="text-md-center text-start"
                                    >
                                      Location
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: "16px" }}
                                      className="text-md-center text-start"
                                    >
                                      {val.address}
                                      {/* , {val.city} */}
                                    </Typography>
                                  </Card>
                                </Grid>

                                <Grid sm={3} xs={6} className="mb-md-0 mb-2">
                                  <Card
                                    sx={{
                                      textAlign: "center",
                                      boxShadow: "none",
                                      // borderRight: "1px solid #DEDEDE",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                      }}
                                      className="text-md-center text-start"
                                    >
                                      Property Type
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: "16px" }}
                                      className="text-md-center text-start"
                                    >
                                      {val.propertyType}
                                    </Typography>
                                  </Card>
                                </Grid>
                                <Grid sm={3} xs={6} className="mb-md-0 mb-2">
                                  <Card
                                    sx={{
                                      textAlign: "center",
                                      boxShadow: "none",
                                      // borderRight: "1px solid #DEDEDE",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                      }}
                                      className="text-md-center text-start"
                                    >
                                      BHK
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: "16px" }}
                                      className="text-md-center text-start"
                                    >
                                      {val.bhk_of_house}
                                    </Typography>
                                  </Card>
                                </Grid>
                                <Grid
                                  sm={3}
                                  xs={6}
                                  className="mb-md-0 mb-2 d-md-block d-none"
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "rgba(2, 201, 136, 1)",
                                      color: "#FFF",
                                      textTransform: "capitalize",
                                      paddingTop: ".4rem",
                                      paddingBottom: ".4rem",
                                    }}
                                    className="px-md-4 px-2"
                                    // onClick={() => {
                                    //   if (
                                    //     userId !== undefined &&
                                    //     userId !== null
                                    //   ) {
                                    //     handleOpen();
                                    //     setPropertyId(val._id);
                                    //   } else {
                                    //     handleClose();
                                    //     // Swal.fire({
                                    //     //   icon: "error",
                                    //     //   title: "Please Login Your Account",
                                    //     //   showConfirmButton: false,
                                    //     //   timer: 2000,
                                    //     // });
                                    //     navigate("/signup-login");
                                    //   }
                                    // }}
                                  >
                                    Visit For{" "}
                                    <span
                                      style={{
                                        color: "rgba(31, 64, 105, 1)",
                                        fontSize: "18px",
                                      }}
                                      className="different-font"
                                    >
                                      {" "}
                                      Free
                                    </span>
                                  </Button>
                                </Grid>
                              </Grid>
                            </Link>
                          </div>
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "300",
                              lineHeight: "24px",
                              paddingLeft: "12px",
                            }}
                          >
                            <Link
                              to={`/property-details/${val._id}`}
                              style={{ color: "black" }}
                            >
                              {val.description}
                            </Link>
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
            ) : (
              <>
                <div
                  style={{
                    justifyContent: "space-evenly",
                    paddingTop: "2rem",
                  }}
                  className={`d-${loaderHide ? "none" : "flex"}`}
                >
                  <CircularProgress />
                </div>
                <p
                  className={`px-3 py-5 font-bold text-center w-100 d-${
                    loaderHide ? "block" : "none"
                  }`}
                >
                  No Result Found
                </p>
                {loaderAction(0)}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
