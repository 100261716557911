import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "../../Css/contact.module.css";
import call from "../../Images/Contact/call.png";
import contact from "../../Images/Contact/contact.png";
import location from "../../Images/Contact/location.png";
export default function SecondSection() {
  return (
    <div className={styles.outer_div_secondsectioncontact}>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", gridGap: "21px" }}
        className="px-4"
      >
        <Grid
          lg={3.6}
          xs={12}
          sx={{ backgroundColor: "#fff", borderRadius: "10px" }}
        >
          <Card sx={{ py: 2, px: 1, boxShadow: "none" }}>
            <Grid container>
              <Grid lg={9} xs={9} sx={{ padding: "1rem" }}>
                <Typography
                  // sx={{
                  //   fontSize: "23px",
                  //   lineHeight: "34.68px",
                  //   fontWeight: "500",
                  // }}
                  className={styles.contact_cards_heading}
                >
                  Hotline Number
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(102, 102, 102, 1)",
                    pt: 1,
                  }}
                >
                  Now it more easy to connect with us- We just Launched our
                </Typography>
              </Grid>
              <Grid lg={3} xs={3} sx={{ padding: "10px" }}>
                <img src={call} alt="call icon" style={{ width: "3rem" }} />
              </Grid>
              <Grid lg={12} xs={12} sx={{ padding: "1rem", paddingBottom: 0 }}>
                <Typography
                  sx={{
                    color: "rgba(31, 64, 105, 1)",
                    // marginBottom: "10px",
                  }}
                  className={styles.contact_cards_heading}
                >
                  7314-038723
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: "500",
                    color: "rgba(31, 64, 105, 1)",
                    paddingTop: "1rem",
                  }}
                >
                  10:00 am - 07:00 pm
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid
          lg={3.8}
          xs={12}
          sx={{ backgroundColor: "#fff", borderRadius: "10px" }}
        >
          <Card sx={{ py: 2, px: 1, boxShadow: "none" }}>
            <Grid container>
              <Grid lg={9} xs={9} sx={{ padding: "1rem" }}>
                <Typography
                  // sx={{
                  //   fontSize: "23px",
                  //   lineHeight: "34.68px",
                  //   fontWeight: "500",
                  // }}
                  className={styles.contact_cards_heading}
                >
                  For Inquiries contact:
                </Typography>
              </Grid>
              <Grid lg={3} xs={3} sx={{ padding: "10px" }}>
                <img src={contact} alt="call icon" style={{ width: "3rem" }} />
              </Grid>
              <Grid container sx={{ padding: "1rem" }}>
                <Grid sm={6} xs={10}>
                  <Card
                    sx={{ boxShadow: "none", border: " 1px solid #D9D9D9" }}
                    className="p-2 me-1 rounded-2"
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "23px",
                        fontWeight: "600",
                      }}
                    >
                      Akash sharma
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "rgba(102, 102, 102, 1)",
                      }}
                    >
                      Public relations manager
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        paddingTop: "12px",
                      }}
                    >
                      7999668576
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        paddingBottom: "6px",
                      }}
                    >
                      beyond@gharaaj.com
                    </Typography>
                  </Card>
                </Grid>
                <Grid sm={6} xs={10} className="mt-md-0 mt-3">
                  <Card
                    sx={{ boxShadow: "none", border: " 1px solid #D9D9D9" }}
                    className="p-2 ms-md-1 rounded-2"
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "23px",
                        fontWeight: "600",
                      }}
                    >
                      Robby tiwari
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "rgba(102, 102, 102, 1)",
                      }}
                    >
                      Public relations associate
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        paddingTop: "12px",
                      }}
                    >
                      8349933849
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "600",
                        paddingBottom: "6px",
                      }}
                    >
                      tiwarirobby@gharaaj.com
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid
          lg={3.6}
          xs={12}
          sx={{ backgroundColor: "#fff", borderRadius: "10px" }}
        >
          <Card sx={{ py: 2, px: 1, boxShadow: "none" }}>
            <Grid container>
              <Grid lg={9} xs={9} sx={{ padding: "1rem" }}>
                <Typography
                  // sx={{
                  //   fontSize: "23px",
                  //   lineHeight: "34.68px",
                  //   fontWeight: "500",
                  // }}
                  className={styles.contact_cards_heading}
                >
                  Corporate Headquarters
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(102, 102, 102, 1)",
                    pt: 1,
                  }}
                >
                  Now it more easy to connect with us- We just Launched our
                </Typography>
              </Grid>
              <Grid lg={3} xs={3} sx={{ padding: "10px" }}>
                <img src={location} alt="call icon" style={{ width: "3rem" }} />
              </Grid>
              <Grid lg={12} xs={12} sx={{ padding: "1rem" }}>
                <Typography
                  sx={{
                    // fontSize: "22px",
                    // lineHeight: "31px",
                    // fontWeight: "500",
                    color: "rgba(31, 64, 105, 1)",
                  }}
                  className={styles.contact_cards_heading}
                >
                  Apollo Premier 501, Indore - 452010 Madhya Pradesh
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
