/** @format */

import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import "./index.css";
import { Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import Rating from "../../Images/Home/Testimonial/testimonial-rating.png";
import { useEffect } from "react";
import { getApihandler } from "../../Apihandler";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Styles from "../../Css/about.module.css";
import { Style } from "@mui/icons-material";
// const style = {
//   position: "absolute",
//   top: "83%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 1000,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   // overflow-y: auto,
// };

const TenantPlan = () => {
  const [PlansData, setPlansData] = useState([]);
  const [userId, setUserId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Get Local Storage Data
  React.useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  useEffect(() => {
    localStorage.setItem("activatedPage", "tenant-plan");
    getPlans();
  }, []);
  // Page Refresh
  function refreshPage() {
    window.location.reload();
  }
  // Handle Page Refresh-------------
  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 200);
  };

  const getPlans = async () => {
    const response = await getApihandler("/getPlans/All");
    // console.log("getPlans response - ", response);
    setPlansData(response.data);
  };

  // const PlansData = [
  //   {
  //     planName: "Azaadi Plan",
  //     planPrice: "1499",
  //     // planURL: "https://cosmofeed.com/vp/651eb48bb89d1b001ea92210",
  //     planURL: "/payment",
  //     planPoints: [
  //       "Matchmaking by expert",
  //       "6 Free Visits",
  //       "Zero Brokerage",
  //       "Premium Filters",
  //       "Instant Property Alerts",
  //       "Rent Negotiation",
  //     ],
  //   },
  //   {
  //     planName: "Sukoon Plan",
  //     planPrice: "2900",
  //     // planURL: "https://cosmofeed.com/vp/651ed34cb89d1b001eaaecad",
  //     planURL: "/payment",
  //     planPoints: [
  //       "Dedicated Manager to save time and money",
  //       "Fixes Meetings",
  //       "Schedules Visit",
  //       "Provides Locality Level Expertise",
  //       "Provided Free Packers and Movers FREE Discounted Quote",
  //       "Premium Filters",
  //       "Zero Brokerage",
  //       "10 Free Visits",
  //       "Instant Property Alerts",
  //       "Dedicated Matchmaking",
  //       "24*7 Customer Support",
  //     ],
  //   },
  //   {
  //     planName: "Paise Wapas Plan",
  //     planPrice: "5999",
  //     // planURL: "https://cosmofeed.com/vp/651ed56db89d1b001eab0f24",
  //     planURL: "/payment",
  //     planPoints: [
  //       "Dedicated Manager to save time and money",
  //       "Unlimited Free Visits",
  //       "Zero Brokerage",
  //       "Premium Filters",
  //       "Instant Property Alerts",
  //       "Dedicated Matchmaking by Expert",
  //       "Locality Expertise",
  //       "Get Guaranteed Home or 100% Refund(If you get a house from someone else",
  //       "24*7 Premium Customer Support",
  //     ],
  //   },
  // ];
  return (
    <Layout>
      <section className="tenant-plan-wrapper py-5 mt-5 px-lg-5 my-5">
        {/* <Container fluid className="pt-4">
         */}
        <Container maxWidth="100%" className="py-5 px-lg-5">
          <Box className="text-lg-start text-center pt-xxl-5 mt-xxl-5">
            <Typography
              variant="h5"
              component="h4"
              className="smallHeading_TwoHeadings"
              style={{ color: "#222222" }}
            >
              Tenant & Owner Plans
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            className="justify-content-center mt-lg-3"
          >
            {PlansData !== undefined &&
              PlansData !== null &&
              PlansData.map((val, ind) => {
                return (
                  <Grid
                    key={ind}
                    item
                    className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 tenant-planboxWrapper mb-4"
                    lg={4}
                    sm={7}
                    xs={12}
                  >
                    <Card
                      className="mx-auto tenant-plan-boxes"
                      style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        color: "#fff !important",
                        border: "1px solid #D9D9D9",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px",
                        padding: "0px 30px 20px",
                        borderRadius: "30px",
                      }}
                    >
                      <CardContent className=" pb-0 px-0">
                        <Typography
                          variant="subtitle1"
                          component="h5"
                          className="tenant-plan-title"
                          style={{
                            fontSize: "24px",
                            fontWeight: 500,
                            color: "#1f4069",
                          }}
                        >
                          {val.planName}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          className="text-dark"
                        >
                          ₹{val.amount}
                        </Typography>
                        <Typography
                          variant="p"
                          component="p"
                          className="text-dark mb-4"
                        >
                          <strong>
                            Free Visit -{" "}
                            {val.freeVisitCount === -1
                              ? "Unlimited"
                              : val.freeVisitCount}{" "}
                            Time
                          </strong>
                        </Typography>
                        {val.facilities !== undefined &&
                          val.facilities !== null &&
                          val.facilities[0].split(",").map((points, ind) => {
                            return (
                              <Typography
                                variant="body1"
                                component="p"
                                className="text-dark d-flex align-items-start"
                                key={ind}
                              >
                                <span className="pe-2">-</span>{" "}
                                <span>{points}</span>
                              </Typography>
                            );
                          })}
                      </CardContent>
                      <Box className="pt-lg-5 pt-4 pb-2 ">
                        {/* <Link to={`/payment/tenantPayment/${val._id}`}> */}

                        <Button
                          style={{
                            marginBottom: "20px",
                            color: "#00C0FB",
                            fontWeight: 500,
                            textAlign: "center",
                            fontSize: "16px",
                            padding: "10px 15px",
                            color: "#fff",
                            background: "red",
                            borderRadius: "4px",
                            border: "none",
                          }}
                          className="w-100"
                          onClick={handleOpen}
                        >
                          <span>Terms of Service</span>
                        </Button>

                        <Link
                          to={`${
                            userId !== undefined && userId !== null
                              ? `/payment/tenantPayment/${val._id}`
                              : "/signup-login"
                          }`}
                        >
                          <Button
                            style={{
                              color: "#00C0FB",
                              fontWeight: 500,
                              textAlign: "center",
                              fontSize: "16px",
                              padding: "10px 15px",
                              color: "#fff",
                              background: "#02c988 ",
                              borderRadius: "4px",
                              border: "none",
                            }}
                            className="w-100"
                          >
                            <span>Subscribe</span>
                          </Button>
                        </Link>
                      </Box>
                    </Card>
                    {/* test */}
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </section>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{ overflow: "auto" }}
      >
        <Fade in={open}>
          <Box className={Styles.modal_box}>
            <div style={{ textAlign: "end" }}>
              <CloseIcon onClick={handleClose} />
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              1. Eligibility:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The tenant plans offered by Gharaaj are available to individuals
              aged 18 years and above.
              <br />
              Tenants must not have engaged with other brokers or conducted
              meetings with property owners through Gharaaj executive services
              prior to purchasing a plan.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              2. Plan Exclusivity:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              Tenants are prohibited from taking a Gharaaj tenant plan if they
              have engaged with other brokers regarding the same property for
              which they are seeking Gharaaj's services.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              3. Visit Limitations:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              Tenants cannot take a new plan if they have exhausted the allotted
              number of visits in their previously purchased plan.
              <br />
              The number of free visits specified in each plan is exclusive to
              that plan and cannot be transferred or accumulated.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              4. Meetings with Property Owners:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              If a meeting with a property owner is conducted by a Gharaaj
              executive, the tenant is not eligible to purchase a tenant plan
              for the same property.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              5. Plan Specifics:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The features and benefits outlined in each plan are specific to
              that plan and cannot be interchanged or combined with features
              from other plans.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              6. Property Engagement:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The tenant agrees not to engage with other brokers or platforms
              for the same property while availing Gharaaj's services.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              7. Refund Policy:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              In the event that a tenant secures a property independently or
              through another source after purchasing the Paise Wapas Plan and
              provides evidence of the same, they may be eligible for a 100%
              refund. The refund is subject to verification by Gharaaj.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              8. Plan Activation:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The tenant plan becomes active immediately upon successful
              purchase, and the benefits outlined in the chosen plan are
              applicable from that moment.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              9. Renewal and Upgrade:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              Tenants have the option to renew or upgrade their plans before the
              expiration date to continue enjoying the benefits. However, any
              unused visits or benefits from the previous plan will not be
              carried over.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              10. Modification of Terms:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              Gharaaj reserves the right to modify these terms and conditions at
              any time. Any changes will be communicated to the tenants through
              appropriate channels.
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
};

export default TenantPlan;
