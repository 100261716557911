import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "../../LayoutWrapper/AdminLayout";
import { Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Button,
} from "@mui/material";
import "./index.css";
import { useEffect } from "react";
import Map from "../../Images/PriceTrends/map.png";

const defaultTheme = createTheme();

export default function PriceTrends() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "price-trends");
  }, []);

  const PriceTrendsData = [
    {
      title: "AB Bypass Road",
      buyRates: [{ price: "-", qq: "-", trends: "Not Available" }],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 8,328 - 10,410",
          threeBedrooms: "Rs. 11,550 - 16,500",
        },
      ],
    },
    {
      title: "AB Road Indore",

      buyRates: [{ price: "-", qq: "-", trends: "Not Available" }],
      rentalRates: [
        {
          oneBedrooms: "Rs. 8,400 - 12,600",
          twoBedrooms: "-",
          threeBedrooms: "Rs. 16,926 - 23,436",
        },
      ],
    },
    {
      title: "Airport Road",

      buyRates: [
        {
          price: "Rs. 2,700 - 3,100/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Alok Nagar",

      buyRates: [
        {
          price: "Rs. 2,800 - 3,450/sq. ft.",
          qq: "6.35%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 8,000 - 8,000",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Annapurna Main Road",

      buyRates: [
        {
          price: "Rs. 3,350 - 4,450/sq. ft.",
          qq: "10%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Annapurna Nagar",

      buyRates: [
        {
          price: "Rs. 3,200 - 5,350/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Bengali Square",

      buyRates: [
        {
          price: "Rs. 3,100 - 4,050/sq. ft.",
          qq: "8.57%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 10,580 - 13,754",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Bhawrasla",

      buyRates: [
        {
          price: "Rs. 3,200 - 3,500/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Bicholi Mardana",

      buyRates: [
        {
          price: "Rs. 3,800 - 5,000/sq. ft.",
          qq: "12.5%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 8,328 - 10,410",
          threeBedrooms: "Rs. 12,664 - 17,413",
        },
      ],
    },
    {
      title: "Bijalpur",

      buyRates: [
        {
          price: "Rs. 3,400 - 4,250/sq. ft.",
          qq: "2.63%",
          trends: "See Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Chhota Bangarda",

      buyRates: [
        {
          price: "Rs. 2,950 - 3,500/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 9,000 - 12,000",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Dewas Naka(Panchvati)",

      buyRates: [
        {
          price: "Rs. 2,200 - 2,650/sq. ft.",
          qq: "6.67%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "HIlink City",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 9,954 - 12,166",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Jakhiya",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 8,648 - 8,648",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Kanadia Road",

      buyRates: [
        {
          price: "Rs. 2,500 - 3,250/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 9,955 - 12,670",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Kesar Bagh",

      buyRates: [
        {
          price: "Rs. 3,550 - 5,850/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Khajrana",

      buyRates: [
        {
          price: "Rs. 2,900 - 4,050/sq. ft.",
          qq: "1.56%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 7,007 - 9,555",
          twoBedrooms: "Rs. 9,837 - 9,837",
          threeBedrooms: "Rs. 13,020 - 13,020",
        },
      ],
    },
    {
      title: "Khatiwala Tank",

      buyRates: [
        {
          price: "Rs. 2,500 - 3,650/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Limbodi",

      buyRates: [
        {
          price: "Rs. 3,200 - 4,300/sq. ft.",
          qq: "3.46%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 6,300 - 9,900",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Mahalakshmi Nagar",

      buyRates: [
        {
          price: "Rs. 3,250 - 3,900/sq. ft.",
          qq: "1.45%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 12,140 - 13,354",
          twoBedrooms: "Rs. 11,760 - 13,720",
          threeBedrooms: "Rs. 15,213 - 17,979",
        },
      ],
    },
    {
      title: "Manavta Nagar",

      buyRates: [
        {
          price: "Rs. 2,550 - 3,150/sq. ft.",
          qq: "5.36%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Manishpuri",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "Rs. 23,375 - 23,375",
        },
      ],
    },
    {
      title: "Manorama Ganj",

      buyRates: [
        {
          price: "Rs. 5,500 - 6,950/sq. ft.",
          qq: "2.22%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 10,500 - 10,500",
          threeBedrooms: "Rs. 22,470 - 24,075",
        },
      ],
    },
    {
      title: "Mayakhedi",

      buyRates: [
        {
          price: "Rs. 2,000 - 2,800/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "MR 10",

      buyRates: [
        {
          price: "Rs. 2,500 - 3,650/sq. ft.",
          qq: "0%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "MR 10",

      buyRates: [
        {
          price: "Rs. 2,500 - 3,650/sq. ft.",
          qq: "0%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Nanda Nagar",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 4,375 - 9,625",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "New Palasia",

      buyRates: [
        {
          price: "Rs. 5,550 - 6,500/sq. ft.",
          qq: "11.94%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "Rs. 41,600 - 47,840",
        },
      ],
    },
    {
      title: "Nipania",

      buyRates: [
        {
          price: "Rs. 3,900 - 4,600/sq. ft.",
          qq: "2.35%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 11,232 - 13,728",
          twoBedrooms: "Rs. 15,400 - 18,700",
          threeBedrooms: "Rs. 20,352 - 25,440",
        },
      ],
    },
    {
      title: "Niranjanpur",

      buyRates: [
        {
          price: "Rs. 3,300 - 3,700/sq. ft.",
          qq: "6.67%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 9,376 - 11,720",
          threeBedrooms: "Rs. 12,888 - 14,320",
        },
      ],
    },
    {
      title: "Old Palasia",

      buyRates: [
        {
          price: "Rs. 2,900 - 4,050/sq. ft.",
          qq: "5.26%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 12,090 - 14,880",
          threeBedrooms: "Rs. 21,996 - 30,456",
        },
      ],
    },
    {
      title: "Palda",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 7,720 - 7,720",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Palsikar Colony",

      buyRates: [
        {
          price: "Rs. 3,000 - 3,800/sq. ft.",
          qq: "0%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Panchsheel Nagar",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 9,954 - 12,166",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Pipliyahana",

      buyRates: [
        {
          price: "Rs. 3,450 - 4,200/sq. ft.",
          qq: "3.95%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Piplya Kumar",

      buyRates: [
        {
          price: "Rs. 3,950 - 4,450/sq. ft.",
          qq: "4.76%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 14,728 - 16,832",
          threeBedrooms: "Rs. 18,909 - 22,347",
        },
      ],
    },
    {
      title: "Race Course Road",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "Rs. 18,850 - 24,505",
        },
      ],
    },
    {
      title: "Rajendra Nagar",

      buyRates: [
        {
          price: "Rs. 2,750 - 3,250/sq. ft.",
          qq: "1.67%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 4,816 - 6,880",
          twoBedrooms: "Rs. 8,199 - 10,021",
          threeBedrooms: "Rs. 13,370 - 17,381",
        },
      ],
    },
    {
      title: "Rambag",

      buyRates: [
        {
          price: "Rs. 2,850 - 3,200/sq. ft.",
          qq: "1.67%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Rangwasa",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 6,030 - 10,050",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Rau",

      buyRates: [
        {
          price: "Rs. 1,850 - 2,550/sq. ft.",
          qq: "6.12%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 4,081 - 5,830",
          twoBedrooms: "Rs. 7,616 - 9,792",
          threeBedrooms: "Rs. 10,935 - 13,365",
        },
      ],
    },
    {
      title: "Rau Road",

      buyRates: [
        {
          price: "Rs. 1,950 - 2,650/sq. ft.",
          qq: "8%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 3,794 - 5,420",
          twoBedrooms: "Rs. 6,378 - 9,567",
          threeBedrooms: "Rs. 10,935 - 13,365",
        },
      ],
    },
    {
      title: "Ring Road",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 7,984 - 7,984",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Sai Kripa Colony",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 15,000 - 18,000",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Saket Nagar",

      buyRates: [
        {
          price: "Rs. 4,600 - 5,850/sq. ft.",
          qq: "8%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 15,750 - 18,000",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Sanchar Nagar Extention",

      buyRates: [
        {
          price: "Rs. 2,850 - 3,500/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Sapna-Sangeeta Road",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 7,046 - 9,214",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Scheme No. 54 Indore",

      buyRates: [
        {
          price: "Rs. 3,500 - 4,900/sq. ft.",
          qq: "3.75%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "Rs. 18,000 - 21,000",
        },
      ],
    },
    {
      title: "Scheme No. 114 Indore",

      buyRates: [
        {
          price: "Rs. 2,450 - 3,300/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Scheme No. 140 Indore",

      buyRates: [
        {
          price: "Rs. 3,950 - 5,350/sq. ft.",
          qq: "17.92%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 11,605 - 14,770",
          threeBedrooms: "Rs. 21,864 - 27,330",
        },
      ],
    },
    {
      title: "Scheme No 136 Indore",

      buyRates: [
        {
          price: "Rs. 3,950 - 5,350/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 5,200 - 5,200",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Shri Nagar",

      buyRates: [
        {
          price: "Rs. 3,050 - 4,100/sq. ft.",
          qq: "0%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Snehlataganj",

      buyRates: [
        {
          price: "Rs. 3,500 - 4,050/sq. ft.",
          qq: "3.9%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Sukhliya",

      buyRates: [
        {
          price: "Rs. 3,350 - 3,600/sq. ft.",
          qq: "2.9%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 5,620 - 7,868",
          twoBedrooms: "Rs. 8,540 - 9,394",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Super Corridor",

      buyRates: [
        {
          price: "Rs. 3,100 - 3,500/sq. ft.",
          qq: "2.82%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 4,496 - 6,744",
          twoBedrooms: "Rs. 9,576 - 12,768",
          threeBedrooms: "Rs. 11,000 - 15,125",
        },
      ],
    },
    {
      title: "Talawali Chanda",

      buyRates: [
        {
          price: "Rs. 2,500 - 3,300/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Tejaji Nagar",

      buyRates: [
        {
          price: "-",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 8,496 - 8,496",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Tilak Nagar",

      buyRates: [
        {
          price: "Rs. 3,200 - 4,000/sq. ft.",
          qq: "2.99%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 10,131 - 11,973",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Tulsi Nagar",

      buyRates: [
        {
          price: "Rs. 4,200 - 4,500/sq. ft.",
          qq: "2.25%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "-",
          threeBedrooms: "Rs. 24,052 - 25,770",
        },
      ],
    },
    {
      title: "Ujjain Road",

      buyRates: [
        {
          price: "Rs. 2,850 - 3,500/sq. ft.",
          qq: "0%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "Rs. 4,752 - 4,752",
          twoBedrooms: "-",
          threeBedrooms: "-",
        },
      ],
    },
    {
      title: "Vijay Nagar",

      buyRates: [
        {
          price: "Rs. 3,450 - 4,950/sq. ft.",
          qq: "9.09%",
          trends: "See   Trends",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 12,155 - 16,575",
          threeBedrooms: "Rs. 17,844 - 22,305",
        },
      ],
    },
    {
      title: "-",

      buyRates: [
        {
          price: "Rs. 3,450 - 4,950/sq. ft.",
          qq: "-",
          trends: "Not Available",
        },
      ],
      rentalRates: [
        {
          oneBedrooms: "-",
          twoBedrooms: "Rs. 12,155 - 16,575",
          threeBedrooms: "Rs. 17,844 - 22,305",
        },
      ],
    },
  ];
  return (
    <Layout>
      <ThemeProvider theme={defaultTheme}>
        <section className="refer-earn-body insight-tools py-5 px-md-0 px-2">
          <Container maxWidth="xxl" className="py-4">
            <Grid
              container
              component="main"
              className="flex-lg-row flex-column flex-lg-wrap flex-nowrap mt-4 justify-content-center"
            >
              <CssBaseline />
              <Grid
                item
                xs={12}
                elevation={6}
                square
                className="px-lg-5 px-md-2 px-0 py-lg-0 py-5"
              >
                <Typography
                  variant="h5"
                  component="h4"
                  className="smallHeading_TwoHeadings mb-1 "
                >
                  Property Rates & Price Trends in Indore
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  accordion
                  style={{ fontWeight: 500 }}
                  className="mb-2"
                >
                  Localities in Indore Societies in Indore.
                </Typography>
                <Typography variant="body1" component="p" accordion>
                  Residential Apartment | Independent/Builder Floor |
                  Residential Land
                </Typography>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ mt: 5 }}>
                    {/* Desktop Data Table---------------------------------------------- */}
                    <TableContainer
                      component={Paper}
                      className="table-wrapper d-lg-block d-none"
                    >
                      <Table>
                        <TableHead>
                          <TableRow className="table-header">
                            <TableCell className="table-elements table-parent-element">
                              <strong>Locality Name</strong>
                            </TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <strong>Buy Rates </strong>
                            </TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <strong>Rental Rates </strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className="table-second-header">
                            <TableCell className="table-elements table-parent-element"></TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <div className="multiple-elements-wrapper">
                                <div className="table-elements">
                                  Price Range (Rs. per sq. ft.)
                                </div>
                                <div className="table-elements">
                                  Q/Q <br />
                                  <span style={{ fontWeight: 400 }}>
                                    (A measure of real estate growth from one
                                    quarter to the next.)
                                  </span>
                                </div>
                                <div className="table-elements">Trends</div>
                              </div>
                            </TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <div className="multiple-elements-wrapper">
                                <div className="table-elements">1 Bedroom</div>
                                <div className="table-elements">2 Bedroom</div>
                                <div className="table-elements"> 3 Bedroom</div>
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow className="table-localities-row">
                            <TableCell className="table-elements table-parent-element">
                              Indore
                            </TableCell>
                            <TableCell className="table-elements table-parent-element"></TableCell>
                            <TableCell className="table-elements table-parent-element"></TableCell>
                          </TableRow>
                          {PriceTrendsData.map((val, ind) => {
                            return (
                              <TableRow
                                className="table-localities-row table-body-data"
                                key={ind}
                              >
                                <TableCell className="table-elements table-parent-element">
                                  {val.title}
                                </TableCell>
                                <TableCell className="table-elements table-parent-element">
                                  {val.buyRates.map((innerVal, innerInd) => {
                                    return (
                                      <div
                                        className="multiple-elements-wrapper"
                                        key={innerInd}
                                      >
                                        <div className="table-elements">
                                          {innerVal.price}
                                        </div>
                                        <div className="table-elements">
                                          {innerVal.qq}
                                        </div>
                                        <div className="table-elements">
                                          {innerVal.trends}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </TableCell>
                                <TableCell className="table-elements table-parent-element">
                                  {val.rentalRates.map((innerVal, innerInd) => {
                                    return (
                                      <div
                                        className="multiple-elements-wrapper"
                                        key={innerInd}
                                      >
                                        <div className="table-elements">
                                          {innerVal.oneBedrooms}
                                        </div>
                                        <div className="table-elements">
                                          {innerVal.twoBedrooms}
                                        </div>
                                        <div className="table-elements">
                                          {innerVal.threeBedrooms}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* Mobile Data Table---------------------------------------------- */}
                    <Box className="d-lg-none d-block">
                      {PriceTrendsData.map((val, ind) => {
                        return (
                          <Card
                            sx={{
                              border: "1px solid #D2D2D2",
                              boxShadow: "none",
                            }}
                            className="mb-4 p-4"
                          >
                            <Grid container className="align-items-md-center">
                              <Grid sm={1.5} xs={2.5}>
                                <div
                                  style={{
                                    width: "50px",
                                  }}
                                >
                                  <img src={Map} alt="property" />
                                </div>
                              </Grid>
                              <Grid sm={10} xs={9}>
                                <h4
                                  style={{ fontWeight: 600, fontSize: "18px" }}
                                  className="d-md-block d-flex flex-column"
                                >
                                  <span> {val.title}</span>
                                  {val.buyRates.map((innerVal, innerInd) => {
                                    return (
                                      <span
                                        style={{
                                          color: "#fff",
                                          backgroundColor: "rgb(2, 201, 136)",
                                          fontSize: "16px",
                                          maxWidth: "85px",
                                        }}
                                        className={`ms-md-3 mt-md-0 mt-2 rounded-1 px-2 py-1 d-${
                                          innerVal.qq === "-"
                                            ? "none"
                                            : "inline"
                                        }`}
                                      >
                                        {innerVal.qq}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                          className="d-inline-block ps-1"
                                        >
                                          &#x2191;
                                        </span>
                                      </span>
                                    );
                                  })}
                                </h4>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className="align-items-center mt-4 mb-md-5"
                            >
                              <Grid
                                sm={4}
                                xs={12}
                                className="d-md-block d-flex align-items-center"
                              >
                                <Typography
                                  className="me-md-0 me-3"
                                  style={{ fontWeight: 600 }}
                                >
                                  1 Bedroom
                                </Typography>
                                {val.rentalRates.map((innerVal, innerInd) => {
                                  return (
                                    <Typography>
                                      {innerVal.oneBedrooms}
                                    </Typography>
                                  );
                                })}
                              </Grid>
                              <Grid
                                sm={4}
                                xs={12}
                                className="d-md-block d-flex align-items-center"
                              >
                                <Typography
                                  className="me-md-0 me-3"
                                  style={{ fontWeight: 600 }}
                                >
                                  2 Bedroom
                                </Typography>
                                {val.rentalRates.map((innerVal, innerInd) => {
                                  return (
                                    <Typography>
                                      {innerVal.twoBedrooms}
                                    </Typography>
                                  );
                                })}
                              </Grid>
                              <Grid
                                sm={4}
                                xs={12}
                                className="d-md-block d-flex align-items-center"
                              >
                                <Typography
                                  className="me-md-0 me-3"
                                  style={{ fontWeight: 600 }}
                                >
                                  3 Bedroom
                                </Typography>
                                {val.rentalRates.map((innerVal, innerInd) => {
                                  return (
                                    <Typography>
                                      {innerVal.threeBedrooms}
                                    </Typography>
                                  );
                                })}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className="align-items-md-center mt-md-4 mt-2 justify-content-md-between flex-md-row flex-column"
                            >
                              <Grid
                                sm={6}
                                xs={12}
                                className="d-md-block d-flex align-items-center"
                              >
                                {val.buyRates.map((innerVal, innerInd) => {
                                  return (
                                    <h6
                                      className={`me-md-0 me-3 d-${
                                        innerVal.price === "-"
                                          ? "none"
                                          : "block"
                                      }`}
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "16px",
                                      }}
                                    >
                                      {innerVal.price}
                                    </h6>
                                  );
                                })}
                              </Grid>
                              <Grid
                                sm={6}
                                xs={12}
                                className=" text-md-end mt-md-0 mt-4"
                              >
                                {val.buyRates.map((innerVal, innerInd) => {
                                  return (
                                    <Button
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(2, 201, 136)",
                                        cursor: "pointer",
                                        textTransform: "capitalize",
                                      }}
                                      className={`d-${
                                        innerVal.trends === "Not Available"
                                          ? "none"
                                          : "inline"
                                      }`}
                                    >
                                      {innerVal.trends} &nbsp; &#8594;
                                    </Button>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </section>
      </ThemeProvider>
    </Layout>
  );
}
