import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import userimg from "../../Images/Universal/user.png";
import styles from "../../Css/userprofile.module.css";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import "../../Pages/UserProfile/index.css";
import { getApihandler, putApihandler } from "../../Apihandler";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import swal from "sweetalert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function ProfileDetail() {
  const Navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  //useState
  const [data, setData] = useState({});
  const [gender, setGender] = useState();
  const [status, setStatus] = useState();
  // console.log("gender=>", gender);

  const tabs = [
    { name: "Profile Detail", path: "/user-profile", color: `#02C988` },
    { name: "Change Password", path: "/change-password" },

    // { name: "Watchlist", path: "/wishlist" },
    {
      name: "Recommended Properties",
      path: "/recommended-properties",
    },
    {
      name: "My Properties",
      path: "/my-properties",
    },
    { name: "My Visits", path: "/my-visits" },
    { name: "Track My Thing", path: "/track-my-thing" },
    // { name: "Bank Details", path: "#" },
    // { name: "Setting", path: "#" },
    { name: "Help", path: "/help" },
  ];

  useEffect(() => {
    getUserdetail();
  }, []);

  const getUserdetail = async () => {
    const id = localStorage.getItem("UserId");
    const response = await getApihandler(`/getUserDetails/${id}`);
    console.log("getUserdetail resp - ", response);
    setData(response.data);
    setGender(response.data.gender.toLowerCase());
    setStatus(response.data.marital_status.toLowerCase());
    setValue("fullName", response.data.fullName);
    setValue("userEmail", response.data.userEmail);
    setValue("phone_number", response.data.phone_number);
    setValue("hobbies", response.data.hobbies);
  };
  const onsubmit = async (value) => {
    const id = localStorage.getItem("UserId");
    const { fullName, userEmail, phone_number, hobbies } = value;

    const array = {
      fullName: fullName,
      userEmail: userEmail,
      phone_number: phone_number,
      hobbies: hobbies,
      gender: gender,
      marital_status: status,
    };
    const response = await putApihandler(`/updateUserDetails/${id}`, array);
    if (response.status === 200) {
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Profile Updated Successfully!",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      swal("Sorry!", `${response.error.response.data.message}`, "error").then(
        (value) => {}
      );
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
        className="flex-md-row flex-column"
      >
        <Box className="ps-lg-3 ps-1 userProfile_TabSection">
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            {/* <Avatar
              alt="Remy Sharp"
              src={userimg}
              sx={{ width: 100, height: 100 }}
            /> */}
            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          <Typography className={styles.profiledetail_heading}>
            ProfileDetail
          </Typography>
          <Typography className={styles.profiledetail_text_paragraph}>
            Let us know a little bit more about you.
          </Typography>
          <form onSubmit={handleSubmit(onsubmit)}>
            <Grid
              container
              sx={{
                gridRowGap: "20px",
                borderBottom: "1px solid rgba(210, 210, 210, 1)",
              }}
              className="pt-md-4 pb-md-5 pt-4 pb-4 mt-2"
            >
              <Grid lg={5} xs={12}>
                <Typography>Full Name</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="Name"
                  variant="outlined"
                  name="fullName"
                  {...register("fullName")}
                />
              </Grid>
              <Grid lg={5} xs={12}>
                <Typography>Email</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="Email"
                  variant="outlined"
                  name="userEmail"
                  {...register("userEmail")}
                />
              </Grid>
              <Grid lg={5} xs={12}>
                <Typography>Mobile Number</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="Mobile Number"
                  variant="outlined"
                  name="phone_number"
                  {...register("phone_number")}
                />
              </Grid>
              <Grid lg={5} xs={12}>
                <Typography>Gender</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={gender}
                  name="gender"
                  {...register("gender")}
                >
                  <div>
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          sx={{
                            color: "rgba(34, 34, 34, 1)",
                            "&.Mui-checked": {
                              color: "rgba(2, 201, 136, 1)",
                            },
                          }}
                          onClick={() => {
                            setGender("Male");
                          }}
                        />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          sx={{
                            color: "rgba(34, 34, 34, 1)",
                            "&.Mui-checked": {
                              color: "rgba(2, 201, 136, 1)",
                            },
                          }}
                          onClick={() => {
                            setGender("Female");
                          }}
                        />
                      }
                      label="Female"
                    />
                  </div>
                </RadioGroup>
              </Grid>

              <Grid lg={7} xs={12}>
                <Typography>Marital Status</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={status}
                  name="marital_status"
                  {...register("marital_status")}
                >
                  <div>
                    <FormControlLabel
                      value="Unmarried"
                      control={
                        <Radio
                          sx={{
                            color: "rgba(34, 34, 34, 1)",
                            "&.Mui-checked": {
                              color: "rgba(2, 201, 136, 1)",
                            },
                          }}
                          onClick={() => {
                            setStatus("Unmarried");
                          }}
                        />
                      }
                      label="Unmarried"
                    />
                    <FormControlLabel
                      value="Married"
                      control={
                        <Radio
                          sx={{
                            color: "rgba(34, 34, 34, 1)",
                            "&.Mui-checked": {
                              color: "rgba(2, 201, 136, 1)",
                            },
                          }}
                          onClick={() => {
                            setStatus("Married");
                          }}
                        />
                      }
                      label="Married"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              <Grid lg={7} xs={12}>
                <Typography>Hobbies</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="Hobbies"
                  variant="outlined"
                  name="hobbies"
                  {...register("hobbies")}
                />
              </Grid>
              {/* <Grid lg={7} xs={12}></Grid> */}
            </Grid>
            <Button
              className={styles.profiledetail_save}
              variant="outlined"
              type="submit"
              // sx={{
              //   marginTop: "2rem",
              // }}
            >
              Save
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
}
