import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "../../LayoutWrapper/AdminLayout";
import { Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
} from "@mui/material";
// import "./index.css";
import LocalityImg from "../../Images/LocalityInsight/map.png";
import StarIcon from "../../Images/LocalityInsight/star.png";
import { useEffect } from "react";

const defaultTheme = createTheme();

export default function LocalityInsight() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "locality-insight");
  }, []);

  const LocalityInsightData = [
    {
      locality: "Nipania",
      city: "Indore ",
      propertyType: "PREMIUM",
      ratings: "4.2",
      totalReviews: "70+",
      price: "* 4,350 / sq.ft Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
    },
    {
      locality: "AB Bypass Road",
      city: "Indore ",
      propertyType: "MID - SEGMENT",
      price: "â‚¬ 3,450 / sq.ft Avg . Rate",
      avgIncreasePrice: "1.5 % YOY",
      ratings: "4.3",
      totalReviews: "60+",
    },
    {
      locality: "Mahalakshmi Nagar",
      city: "Indore ",
      propertyType: "MID - SEGMENT",
      price: "â‚¬ 3,350 / sq.ft Avg . Rate",
      avgIncreasePrice: "9.8 % YOY",
      ratings: "4.2",
      totalReviews: "40+",
    },
    {
      locality: "-",
      city: "-",
      propertyType: "MID - SEGMENT",
      price: "NA Avg . Rate",
      avgIncreasePrice: "-",
      ratings: "-",
      totalReviews: "30+",
    },
    {
      locality: "Bicholi Mardana",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "â‚¬ 4,500 / sq.ft Avg . Rate",
      avgIncreasePrice: "25% YOY",
      ratings: "4.0",
      totalReviews: "15+",
    },
    {
      locality: "Rau",
      city: "Indore ",
      propertyType: "AFFORDABLE",
      price: "â‚¬ 2,750 / sq.ft Avg . Rate",
      avgIncreasePrice: "25% YOY",
      ratings: "3.9",
      totalReviews: "26+",
    },
    {
      locality: "Mhow",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "4.2",
      totalReviews: "14+",
    },
    {
      locality: "Khandwa Road",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "â‚¬ 4,100 / sq.ft Avg . Rate",
      avgIncreasePrice: "41.4 % YOY",
      ratings: "3.8",
      totalReviews: "5+",
    },
    {
      locality: "Bengali Square",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "4.2 ",
      totalReviews: "70+",
    },
    {
      locality: "Kanadia Road",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "* 2,550 / sq.ft",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "-",
    },
    {
      locality: "Talawali Chanda",
      city: "Indore ",
      propertyType: "MID - SEGMENT",
      price: "* 3,350 / sq.ft Avg . Rate",
      avgIncreasePrice: "9.8 % YOY",
      ratings: "4.0 ",
      totalReviews: "7+",
    },
    {
      locality: "Rajendra Nagar",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "NA Avg . Rate",
      avgIncreasePrice: "4.6 % YOY",
      ratings: "4.2 ",
      totalReviews: "40+",
    },
    {
      locality: "Silicon City",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "3+",
    },
    {
      locality: "Pipliyahana",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "NA Avg . Rate",
      avgIncreasePrice: "86.4 % YOY",
      ratings: "4.0 ",
      totalReviews: "-",
    },
    {
      locality: "Kanadiya",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Scheme No 54",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "* 4,250 / sq.ft",
      avgIncreasePrice: "1.2 % YOY",
      ratings: "4.4 ",
      totalReviews: "250+",
    },
    {
      locality: "Saket Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0 ",
      totalReviews: "9+",
    },
    {
      locality: "Scheme No 114",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Jhalaria",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "3.7 ",
      totalReviews: "3+",
    },
    {
      locality: "AB Road indore",
      city: "Indore ",
      propertyType: "MID - SEGMENT",
      price: "â‚¬ 3,450 / sq.ft Avg . Rate",
      avgIncreasePrice: "9.5 % YOY",
      ratings: "4.2 ",
      totalReviews: "60+",
    },
    {
      locality: "Ida Scheme No 134",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Devguradia",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Tilak Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.2 ",
      totalReviews: "24+",
    },
    {
      locality: "Bijalpur",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "3.5 ",
      totalReviews: "3+",
    },
    {
      locality: "Bhawrasla",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "â‚¬ 4,200 / sq.ft Avg . Rate",
      avgIncreasePrice: "20 % YOY",
      ratings: "3.8",
      totalReviews: "4+",
    },
    {
      locality: "Bada Bangarda",
      city: "Indore ",
      propertyType: "-",
      price: "â‚¬ 3,450 / sq.ft Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "3.9 ",
      totalReviews: "6+",
    },
    {
      locality: "Niranjanpur",
      city: "Indore ",
      propertyType: "PREMIUM",
      price: "* 4,250 / sq.ft Avg . Rate",
      avgIncreasePrice: "19.7 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Scheme No 78",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "14+",
    },
    {
      locality: "Race Course Road",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Old Palasia",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "14+",
    },
    {
      locality: "MR 11",
      city: "Indore ",
      propertyType: "MID - SEGMENT",
      price: "â‚¬ 3,700 / sq.ft Avg . Rate",
      avgIncreasePrice: "21.3 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Nainod",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Bicholi Hapsi",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Kesar Bagh",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Tejaji Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Palakhedi",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Scheme No 71",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "5+",
    },
    {
      locality: "Sai Kripa Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Manorama Ganj",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "5+",
    },
    {
      locality: "Scheme No 103",
      city: "Indore ",
      propertyType: "-",
      price: "â‚¬ 4,700 / sq.ft Avg . Rate",
      avgIncreasePrice: "2.2 % YOY",
      ratings: "3.8",
      totalReviews: "3+",
    },
    {
      locality: "Gulab Bagh",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Navlakha",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "2.2 % YOY",
      ratings: "4.0",
      totalReviews: "16+",
    },
    {
      locality: "Rau Pitampur Road",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Rangwasa",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Tigaria Badshah",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Pipliya Kumar",
      city: "Indore ",
      propertyType: "-",
      price: "* 5,000 / sq.ft Avg . Rate",
      avgIncreasePrice: "25% YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "LIG Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "4+",
    },
    {
      locality: "Luv Kush Square",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Kalani Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "17+",
    },
    {
      locality: "Magarkheda",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Gumasta Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "5+",
    },
    {
      locality: "South Tukoganj",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "5+",
    },
    {
      locality: "Nanda Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.1",
      totalReviews: "12+",
    },
    {
      locality: "Scheme No 94",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Sangam Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "7+",
    },
    {
      locality: "Alok Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.1",
      totalReviews: "5+",
    },
    {
      locality: "Anurag Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "4+",
    },
    {
      locality: "Snehlataganj",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Sapna Sangeeta Road",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "4+",
    },
    {
      locality: "Vasant Vihar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Mangaliya Sadak",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Paliya",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Anoop Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "3+",
    },
    {
      locality: "Bangali Square",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.1",
      totalReviews: "8+",
    },
    {
      locality: "Arandia",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Balya Kheda",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Geeta Bhavan",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "14+",
    },
    {
      locality: "Musakhedi",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.6",
      totalReviews: "8+",
    },
    {
      locality: "Mig Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "6+",
    },
    {
      locality: "Ring Road",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Jakhiya",
      city: "Indore ",
      propertyType: "-",
      price: "* 3,400 / sq.ft",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Palsikar Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "3+",
    },
    {
      locality: "Rajwada",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "10+",
    },
    {
      locality: "Kordia Barda",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Mundla Nayta",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Pithampur Road",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Bakhtawar Ram",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "4.0",
      totalReviews: "3+",
    },
    {
      locality: "Pigdambar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Rambag",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "11.5 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Vishnupuri Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.1",
      totalReviews: "6+",
    },
    {
      locality: "Shri Nagar Extension",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Annapurna Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "6+",
    },
    {
      locality: "Palasia",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Budhaniya",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Gokul Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "3+",
    },
    {
      locality: "Morod",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Sanchar Nagar Extention",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.1",
      totalReviews: "7+",
    },
    {
      locality: "NH3 Agra Mumbai Highway",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Vallabh Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "7+",
    },
    {
      locality: "Indrapuri Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "9",
    },
    {
      locality: "Vaishali Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.7",
      totalReviews: "3+",
    },
    {
      locality: "Banganga",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.5",
      totalReviews: "9",
    },
    {
      locality: "Shiv Dham Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "3+",
    },
    {
      locality: "Panda",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Vandana Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "4+",
    },
    {
      locality: "Sanawadia",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Dhanvantri Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "4+",
    },
    {
      locality: "Panchderiya",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "NA",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Sainath Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "4+",
    },
    {
      locality: "Tejpur Gadbadi",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Sawer",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Malviya Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "7+",
    },
    {
      locality: "Malharganj",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.5",
      totalReviews: "3+",
    },
    {
      locality: "Chandan Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.7",
      totalReviews: "6+",
    },
    {
      locality: "Mahaveer Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Barodiya Ema",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Usha Nagar Extension",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "4.0",
      totalReviews: "3+",
    },
    {
      locality: "Nehru Nagar",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.6",
      totalReviews: "3+",
    },
    {
      locality: "Baliyakhedi",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Sirpur",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "3+",
    },
    {
      locality: "Choral",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "NA",
      totalReviews: "NA",
    },
    {
      locality: "Green Park Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.9",
      totalReviews: "10+",
    },
    {
      locality: "Scheme 54 PU4",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.5",
      totalReviews: "5+",
    },
    {
      locality: "Sindhi Colony",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.7",
      totalReviews: "3+",
    },
    {
      locality: "Karolbagh",
      city: "Indore ",
      propertyType: "-",
      price: "NA Avg . Rate",
      avgIncreasePrice: "0 % YOY",
      ratings: "3.8",
      totalReviews: "4+",
    },
  ];

  return (
    <Layout>
      <ThemeProvider theme={defaultTheme}>
        <section className="locality-insight-body py-5 px-md-0 px-2">
          <Container maxWidth="xxl" className="py-4">
            <Grid
              container
              component="main"
              className="flex-lg-row flex-column flex-lg-wrap flex-nowrap mt-4 justify-content-center"
            >
              <CssBaseline />
              <Grid
                item
                xs={12}
                elevation={6}
                square
                className="px-lg-5 px-md-2 px-0 py-lg-0 py-5"
              >
                <Typography
                  variant="h5"
                  component="h4"
                  className="smallHeading_TwoHeadings mb-1 "
                >
                  Locality Insights in Indore
                </Typography>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ mt: 5 }}>
                    {/* Desktop Data Table---------------------------------------------- */}
                    <TableContainer
                      component={Paper}
                      className="table-wrapper d-lg-block d-none"
                    >
                      <Table>
                        <TableHead>
                          <TableRow className="table-header">
                            <TableCell className="table-elements table-parent-element">
                              <strong>Locality</strong>
                            </TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <strong>Reviews</strong>
                            </TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <strong>Price Per/sqft</strong>
                            </TableCell>
                            <TableCell className="table-elements table-parent-element">
                              <strong>
                                Average Increase in price(Year On Year){" "}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {LocalityInsightData.map((val, ind) => {
                            return (
                              <TableRow className="table-localities-row">
                                <TableCell className="table-elements table-parent-element">
                                  <div className="locality-wrapper">
                                    <div className="locality-img mt-2">
                                      <img
                                        src={LocalityImg}
                                        alt=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                    </div>
                                    <div className="locality-content ms-3 text-start">
                                      <Typography
                                        variant="body1"
                                        component="h6"
                                        className=""
                                        style={{ fontWeight: 600 }}
                                      >
                                        {val.locality}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        component="p"
                                        accordion
                                        style={{ fontWeight: 500 }}
                                        className=""
                                      >
                                        {val.city}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        component="p"
                                        accordion
                                        style={{ color: "rgb(2, 201, 136)" }}
                                      >
                                        {val.propertyType}
                                      </Typography>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className="table-elements table-parent-element">
                                  <div className="reviews-wrapper">
                                    <div className="review-img mt-2 d-flex align-items-center">
                                      <Typography
                                        variant="body1"
                                        component="h6"
                                        accordion
                                        style={{
                                          fontWeight: 500,
                                          color: "#000000CF",
                                        }}
                                        className="me-1"
                                      >
                                        {val.ratings}
                                      </Typography>
                                      <img
                                        src={StarIcon}
                                        alt=""
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </div>
                                    <div className="review-content text-start">
                                      <Typography
                                        variant="body1"
                                        component="h5"
                                        className=""
                                        style={{ fontWeight: 500 }}
                                      >
                                        {val.totalReviews} Reviews
                                      </Typography>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className="table-elements table-parent-element">
                                  {val.price}
                                </TableCell>
                                <TableCell className="table-elements table-parent-element">
                                  {val.avgIncreasePrice}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* Mobile Data Table---------------------------------------------- */}
                    <Box className="d-lg-none d-block">
                      {LocalityInsightData.map((val, ind) => {
                        return (
                          <Card
                            sx={{
                              border: "1px solid #D2D2D2",
                              boxShadow: "none",
                            }}
                            className="mb-4 px-4 py-md-4 p-3"
                          >
                            <Grid container className="align-items-start">
                              {/* <Grid sm={1.2} xs={2.2}>
                                <div
                                  style={{
                                    width: "50px",
                                  }}
                                >
                                  <img
                                    src={LocalityImg}
                                    alt=""
                                    style={{ width: "40px" }}
                                  />
                                </div>
                              </Grid> */}
                              <Grid xs={12} className="ps-md-0 ps-2">
                                <Typography
                                  variant="body1"
                                  component="h6"
                                  className=" d-md-block d-flex flex-column"
                                  style={{ fontWeight: 600 }}
                                >
                                  <span>
                                    <img
                                      src={LocalityImg}
                                      alt=""
                                      style={{ width: "40px" }}
                                      className="pe-3"
                                    />
                                    {val.locality}
                                  </span>

                                  <span
                                    className={`my-md-0 my-3 d-${
                                      val.totalReviews === "NA" &&
                                      (val.ratings === "NA" ||
                                        val.ratings === "-")
                                        ? "none"
                                        : "inline"
                                    }`}
                                  >
                                    <span
                                      style={{
                                        color: "#fff",
                                        backgroundColor: "rgb(2, 201, 136)",
                                        fontSize: "16px",
                                        maxWidth: "85px",
                                      }}
                                      className={`ms-md-3 mt-md-0 mt-2 rounded-1 px-2 py-1 d-${
                                        val.ratings === "NA" ||
                                        val.ratings === "-"
                                          ? "none"
                                          : "inline"
                                      }`}
                                    >
                                      {val.ratings}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                        className="d-inline-block ps-1"
                                      >
                                        &#9733;
                                      </span>
                                    </span>
                                    <span
                                      style={{ fontSize: "12px" }}
                                      className={`ps-2 d-${
                                        val.totalReviews === "NA"
                                          ? "none"
                                          : "inline"
                                      }`}
                                    >
                                      {val.totalReviews} Reviews
                                    </span>
                                  </span>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  component="p"
                                  accordion
                                  style={{ fontWeight: 500 }}
                                  className={`d-${
                                    val.city === "-" ? "none" : "block"
                                  } mt-md-2`}
                                >
                                  {val.city}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  component="p"
                                  accordion
                                  style={{ color: "rgb(2, 201, 136)" }}
                                  className={`d-${
                                    val.propertyType === "-" ? "none" : "block"
                                  }`}
                                >
                                  {val.propertyType}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`align-items-md-center mt-md-4 mt-3 justify-content-md-between flex-md-row flex-column d-${
                                val.price === "NA Avg . Rate" &&
                                (val.avgIncreasePrice === "-" ||
                                  val.avgIncreasePrice === "NA")
                                  ? "none"
                                  : "flex"
                              }`}
                            >
                              <Grid sm={6} xs={8}>
                                <h5
                                  className={`d-md-${
                                    val.price === "NA Avg . Rate"
                                      ? "none"
                                      : "block"
                                  } d-${
                                    val.price === "NA Avg . Rate"
                                      ? "none"
                                      : "flex"
                                  } align-items-center`}
                                >
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    &#8377;{" "}
                                  </span>
                                  <span> {val.price}</span>
                                </h5>
                              </Grid>
                              <Grid
                                sm={6}
                                xs={4}
                                className={`text-md-end mt-md-0 mt-2 d-${
                                  val.avgIncreasePrice === "-" ||
                                  val.avgIncreasePrice === "NA"
                                    ? "none"
                                    : "block"
                                }`}
                              >
                                <h5>
                                  <span
                                    style={{
                                      fontSize: "20px",
                                    }}
                                  >
                                    &#8593;
                                  </span>
                                  <span> {val.avgIncreasePrice}</span>
                                </h5>
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </section>
      </ThemeProvider>
    </Layout>
  );
}
