import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import { getApihandler } from "../../Apihandler";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const FaqDetails = () => {
  const { QueryID } = useParams();
  const [faqData, setFAQData] = useState();
  const [faqBodyData, setFAQBodyData] = useState([]);
  // console.log("faqData - ", faqData);

  useEffect(() => {
    getFAQDetails();
  }, [QueryID]);

  const getFAQDetails = async () => {
    const resp = await getApihandler(`/getFaqById/${QueryID}`);
    // console.log("faq details - ", resp);
    setFAQData(resp.data);
    setFAQBodyData(resp.data.description);
  };

  return (
    <Layout>
      <section
        className="breadcrumbs-wrapper pt-lg-0 pt-4"
        style={{ borderBottom: "1px solid rgb(0 0 0 / 14%)" }}
      >
        <Container maxWidth="100%" className="pt-5 pb-3">
          {faqData !== undefined && (
            <h6 className="d-flex align-items-center flex-wrap">
              <Link to="/faq" className="text-dark">
                Help Center
              </Link>
              <NavigateNextIcon
                fontSize="small"
                style={{ color: "#1f4069", paddingTop: "3px" }}
              />
              <Link to={`/faq/${faqData.type}`} className="text-dark">
                {faqData.type}
              </Link>
              <NavigateNextIcon
                fontSize="small"
                style={{ color: "#1f4069", paddingTop: "3px" }}
              />
              <Link
                to={`/faq-list/${faqData.type}/${faqData.category}`}
                className="text-dark"
              >
                {faqData.category}
              </Link>
            </h6>
          )}
        </Container>
      </section>
      <Container maxWidth="100%" className="pt-4 mb-4">
        {faqData !== undefined ? (
          <>
            <Box>
              <h3 className="mt-3">{faqData.title}</h3>
            </Box>
            <Box className="faq-details-wrapper my-4 pb-4">
              {faqBodyData !== undefined &&
                faqBodyData.map((val, ind) => {
                  return (
                    <p className="faq-body-data">
                      <span
                        style={{ fontWeight: 600 }}
                        className={`d-${
                          val.heading === "" ? "none" : "inline"
                        }`}
                      >
                        {val.heading}: &nbsp;
                      </span>
                      {val.sub_description}
                    </p>
                  );
                })}
            </Box>
          </>
        ) : (
          <h2 className="mt-3">No Result Found</h2>
        )}
      </Container>
    </Layout>
  );
};

export default FaqDetails;
