import { Container } from "@mui/material";
import React from "react";

import BannerContact from "../../Components/Contactpage/banner";
import SecondSection from "../../Components/Contactpage/seconsection";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "contact");
  }, []);
  return (
    <Layout>
      <Container maxWidth="xxl" className="p-0">
        <BannerContact />
        <SecondSection />
        <PartnersList />
      </Container>
    </Layout>
  );
}
