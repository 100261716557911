import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./index.css";
import { useEffect } from "react";
import Icon1 from "../../Images/ZeroBrokerage/plan.svg";
import Icon2 from "../../Images/ZeroBrokerage/search.svg";
import Icon3 from "../../Images/ZeroBrokerage/calender.svg";
import Icon4 from "../../Images/ZeroBrokerage/portal.svg";
export default function ZeroBrokerage() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "zero-brokerage");
  }, []);
  return (
    <Layout>
      <section className="ZeroBrokerage-textWrapper mt-5 inter-family">
        <Container maxWidth="100%" className="mt-5">
          <Row className="mt-5">
            <Col xs={4}>
              <Typography
                variant="h2"
                component="h2"
                className="zero-brokerage-title"
              >
                0%
              </Typography>
            </Col>
            <Col xs={8}>
              <Typography
                variant="h2"
                component="h2"
                className="zero-brokerage-title text-end"
              >
                BROKERAGE
              </Typography>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="ZeroBrokerage-Banner mt-4 mb-5 inter-family py-3">
        <Container maxWidth="100%" className="py-lg-4 py-0">
          <Row className="justify-content-center">
            <Col xl={6} lg={8} sm={9} xs={10}>
              <Typography
                variant="h2"
                component="h2"
                className="zero-brokerage-banner-title-1 text-center text-white inter-family mb-xl-0 mb-5"
              >
                UNLOCK THE UNBELIEVEABLE
              </Typography>
              <div className="first-point-wrapper text-white mt-xl-3 mt-lg-4">
                <div className="first-point-title-wrapper d-flex justify-content-xl-center justify-content-start align-items-start ">
                  <Box className="point-icon-wrapper">
                    <img src={Icon1} />
                  </Box>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="zero-brokerage-banner-title-2 inter-family me-2"
                  >
                    1
                  </Typography>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      className="zero-brokerage-banner-title-3 inter-family"
                    >
                      CHOOSE <br />
                      TENANT PLAN
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="zero-brokerage-banner-title-4 inter-family"
                    >
                      “START BY SELECTING A<br /> TENANT PLAN”
                    </Typography>
                    <div className="points-btn-wrapper mt-2">
                      <Link to="/tenant-plan">
                        <button className="inter-family">GET STARTED</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="second-point-wrapper text-white ">
                <div className="second-point-title-wrapper d-flex justify-content-center align-items-start ">
                  <Box className="point-icon-wrapper">
                    <img src={Icon2} />
                  </Box>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="zero-brokerage-banner-title-2 inter-family me-2"
                  >
                    2
                  </Typography>
                  <div>
                    <div className="d-flex align-items-center">
                      <Typography
                        variant="h4"
                        component="h4"
                        className="zero-brokerage-banner-title-3 inter-family"
                      >
                        SEARCH FOR
                        <br /> PROPERTIES
                      </Typography>
                      <div className="points-btn-wrapper ms-3 d-xl-block d-none">
                        <Link to="/properties/list/all">
                          <button className="inter-family">
                            START SEARCHING
                          </button>
                        </Link>
                      </div>
                    </div>

                    <Typography
                      variant="h5"
                      component="h5"
                      className="zero-brokerage-banner-title-4 inter-family"
                    >
                      “AFTER CHOOSING YOUR TENANT PLAN, ENJOY DEDICATED
                      MATCHMAKING AND PERSONALIZED RECOMMENDATIONS TAILORED TO
                      YOUR PREFERENCES”
                    </Typography>
                    <div className="points-btn-wrapper mt-2 d-xl-none">
                      <Link to="/properties/list/all">
                        <button className="inter-family">
                          START SEARCHING
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="third-point-wrapper text-white ">
                <div className="third-point-title-wrapper d-flex justify-content-center align-items-start ">
                  <Box className="point-icon-wrapper">
                    <img src={Icon3} />
                  </Box>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="zero-brokerage-banner-title-2 inter-family me-2"
                  >
                    3
                  </Typography>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      className="zero-brokerage-banner-title-3 inter-family"
                    >
                      SCHEDULE <br />A VISIT
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="zero-brokerage-banner-title-4 inter-family"
                    >
                      “INTERSTED? SCHEDULE A PROPERTY VISIT THROUGH OUR PORTAL.”
                    </Typography>
                    <div className="points-btn-wrapper mt-2">
                      <Link to="/properties/list/all">
                        <button className="inter-family">SCHEDULE NOW</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fourth-point-wrapper text-white ">
                <div className="fourth-point-title-wrapper d-flex justify-content-center align-items-start ">
                  <Box className="point-icon-wrapper">
                    <img src={Icon4} />
                  </Box>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="zero-brokerage-banner-title-2 inter-family me-2"
                  >
                    4
                  </Typography>

                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      className="zero-brokerage-banner-title-3 inter-family"
                    >
                      BOOK ON OUR <br /> PORTAL
                    </Typography>
                    <Typography
                      variant="h5"
                      component="h5"
                      className="zero-brokerage-banner-title-4 inter-family"
                    >
                      “RESERVE YOUR CHOSEN PROPERTY DIRETLY THORUGHT OUR PORTAL
                      & GET 100% DISCOUNT AND BROKERAGE AND OYHER BENEFITS.”
                    </Typography>
                    <div className="points-btn-wrapper mt-2">
                      <Link to="/properties/list/all">
                        <button className="inter-family">GET BOOK NOW!</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fifth-point-wrapper text-white ">
                <div className="fifth-point-title-wrapper ">
                  <Typography
                    variant="h4"
                    component="h4"
                    className="zero-brokerage-banner-title-3 inter-family text-xl-end"
                  >
                    YOU ALWAYS
                    <br /> DESERVE’D
                    <br /> THIS
                  </Typography>
                  <div className="points-btn-wrapper text-xl-end mt-2">
                    <Link to="/contact">
                      <button className="inter-family">
                        YOUR FEEDBACK MATTERS
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
