import * as React from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import phonepayImg from "../../Images/Payment/phonepay.webp";
import phonepay from "../../Images/Payment/PhonePe-Logo.wine.png";
import Logo from "../../Images/Universal/gharaaj-logo.svg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";

import { getApihandler, postApihandler } from "../../Apihandler";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function PhonePay() {
  const { planType, planId } = useParams();
  const [userId, setUserId] = React.useState();

  // console.log(`planType -  ${planType}, planId -  ${planId}`);
  const [amount, setAmount] = React.useState(0);
  // console.log("amount is - ", amount);

  const { register, handleSubmit, reset } = useForm();
  React.useEffect(() => {
    if (planType === "tenantPayment") {
      getTenantAmount();
    } else if (planType === "ownerPayment") {
      getOwnerAmount();
    } else if (planType === "property-payment") {
      // console.log("booking called");
      setAmount(5000);
    } else if (planType === "visit-payment") {
      // console.log("booking called");
      setAmount(5000);
    }
  }, []);

  // Get Local Storage Data
  React.useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  const getTenantAmount = async () => {
    const response = await getApihandler(`/getPlans/id_${planId}`);
    // console.log("getTenantAmount response -", response);
    setAmount(response.data[0].amount);
  };
  const getOwnerAmount = async () => {
    const response = await getApihandler(`/getOwnerPlans/id_${planId}`);
    console.log("getOwnerAmount response -", response);
    setAmount(response.data[0].amount);
  };

  const onSubmit = async (value) => {
    const { mobileNumber, name } = value;
    const item = {
      amount: Number(amount),
      mobileNumber: mobileNumber,
      name: name,
    };
    console.log("value==>", item);
    if (planType === "tenantPayment") {
      // console.log("tenant payment enable");

      const res = await postApihandler(
        `/planPurchaseByUser/${userId}/${planId}`,
        item
      ).then((response) => {
        console.log("resp outside - ", response);
        if (response.error) {
          console.log("resp - ", response.error.response.data.message);
          Swal.fire({
            position: "middle-centre",
            icon: "error",
            title: "Something went wrong",
            text: response.error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          window.location.href =
            response.data.instrumentResponse.redirectInfo.url;
        }
      });
      // .catch((error) => {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Something went wrong",
      //     text: error,
      //   });
      // });
    } else if (planType === "ownerPayment") {
      const res = await postApihandler(
        `/ownerPlanPurchaseByUser/${userId}/${planId}`,
        item
      ).then((response) => {
        console.log("resp outside - ", response);
        if (response.error) {
          console.log("resp - ", response.error.response.data.message);
          Swal.fire({
            position: "middle-centre",
            icon: "error",
            title: "Something went wrong",
            text: response.error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          window.location.href =
            response.data.instrumentResponse.redirectInfo.url;
        }
      });
      // .catch((error) => {
      //   console.log("error - ", error);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Something went wrong",
      //     text: error,
      //   });
      // });
    } else if (planType === "visit-payment") {
      // console.log("booking payment enable");
      const res = await postApihandler(`/payForBooking/${userId}`, item).then(
        (response) => {
          console.log("resp outside - ", response);
          if (response.error) {
            console.log("resp - ", response.error.response.data.message);
            Swal.fire({
              position: "middle-centre",
              icon: "error",
              title: "Something went wrong",
              text: response.error.response.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            window.location.href =
              response.data.instrumentResponse.redirectInfo.url;
          }
        }
      );
      // .catch((error) => {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Something went wrong",
      //     text: error,
      //   });
      // });
    } else if (planType === "property-payment") {
      // console.log("booking payment enable");
      // bookingProperty/{userId}{propertyId}

      const res = await postApihandler(
        `/bookingProperty/${userId}/${planId}`,
        item
      ).then((response) => {
        console.log("resp outside - ", response);
        if (response.error) {
          console.log("resp - ", response.error.response.data.message);
          Swal.fire({
            position: "middle-centre",
            icon: "error",
            title: "Something went wrong",
            text: response.error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          window.location.href =
            response.data.instrumentResponse.redirectInfo.url;
        }
      });
      // .catch((error) => {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Something went wrong",
      //     text: error,
      //   });
      // });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(" + phonepayImg + ")",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 3,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="pt-5"
          >
            {/* <img src={phonepay} style={{ width: "40%" }} /> */}
            <Link to="/" className="text-center">
              <img src={Logo} style={{ width: "60%" }} className="mx-auto" />
            </Link>

            <Typography component="h1" variant="h5" className="pt-3">
              Pay Now
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                color="secondary"
                id="standard-basic"
                label="Name"
                variant="standard"
                type="text"
                name="name"
                {...register("name")}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                color="secondary"
                id="standard-basic"
                label="Mobile Number"
                variant="standard"
                type="number"
                name="mobileNumber"
                {...register("mobileNumber")}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                color="secondary"
                id="standard-basic"
                label="Amount"
                variant="standard"
                type="number"
                name="amount"
                value={amount}
                // {...register("amount")}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, background: "#5f249f" }}
                color="secondary"
              >
                Pay
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
