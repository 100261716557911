/** @format */

import React, { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import "../../Pages/Home/home.css";
import {
  Box,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getApihandler, imageUrl, postApihandler } from "../../Apihandler";
import Layout from "../../LayoutWrapper/AdminLayout";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import balconyImg from "../../Images/Property/balcony.png";
import buildingArea from "../../Images/Property/buildingArea.png";
import bathroomImg from "../../Images/Property/bathroom.png";
import furnishedImg from "../../Images/Property/furnished.png";
import carpetImg from "../../Images/Property/carpet.png";
import BedImg from "../../Images/Property/bedImg.png";
import YearImg from "../../Images/Property/yearImg.png";
import availableImg from "../../Images/Property/available.png";
import FacingImg from "../../Images/Property/facing.png";
import DoneIcon from "@mui/icons-material/Done";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import visitProperty from "../../Images/Property/single-property-visit.png";
import BookImg from "../../Images/Property/bookImg.png";
import clockImg from "../../Images/Property/clock-property.png";
import calenderImg from "../../Images/Property/calender-property.png";
import { RWebShare } from "react-web-share";
import ShareIcon from "@mui/icons-material/Share";
import Share from "../../Components/Share";
import ImageModal from "../../Components/Properties/imagemodal";
// import { Carousel } from "react-bootstrap";
import ModalImage from "react-modal-image";
// import "bootstrap/dist/css/bootstrap.min.css";
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SingleProperty = () => {
  const { searchKey } = useParams();
  const navigate = useNavigate();
  const [PropertiesList, setPropertiesList] = useState([]);
  // console.log("PropertiesList - ", PropertiesList);
  const [servicesList, setServicesList] = useState([]);
  const [similarProperty, setsimilarProperty] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  // console.log("amenitiesList - ", amenitiesList);
  const [imageModelOpen, setImageModelOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [propertyId, setPropertyId] = useState();
  const [ServiceId, setServiceId] = useState("");
  const [socityName, setsocityName] = useState("");
  // console.log("socityName - ", socityName);
  const [modelBtnCount, setModelBtnCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [open4, setOpen4] = React.useState(false);
  const [locality, setlocality] = React.useState("");
  // console.log("long-->", long);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const handleChange123 = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    localStorage.setItem("activatedPage", "single-property");
  }, []);

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  useEffect(() => {
    getFilterdProperties();
    getServices();
  }, []);

  useEffect(() => {
    if (socityName !== "") {
      getAmenities();
    }
  }, [socityName]);

  const getAmenities = async () => {
    const res = await getApihandler(
      `/getAmenitiesFromsocietyName/${socityName}`
    );
    setAmenitiesList(res.data.amenities);
    // console.log("amenities response - ", res);
  };

  const getFilterdProperties = async () => {
    const response = await getApihandler(`/getProperties/id_${searchKey}`);
    console.log("getFilterdProperties response==>", response);
    if (response.status === 200) {
      setPropertiesList(response.data);
      setsocityName(response.data[0].society);
      setsimilarProperty(response.similarProperty);
      setlocality(response.data[0].address);
    }
  };

  const getServices = async () => {
    const response = await getApihandler(`/getServices/All`);
    setServicesList(response.data);
  };

  const bookService = async () => {
    if (userId !== undefined && userId !== null) {
      if (ServiceId !== "") {
        const response = await postApihandler(
          `/bookServices/${userId}/${ServiceId}`
        );
        // console.log("bookService====>", response);
        if (response.status === 200) {
          Swal.fire({
            position: "middle-centre",
            icon: "success",
            title: "Service Booked",
            showConfirmButton: false,
            timer: 2000,
          });
          setServiceId("");
          handleClose();
        } else {
          Swal.fire({
            position: "middle-centre",
            icon: "error",
            title: response.error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        handleClose();
        Swal.fire({
          // position: "middle-centre",
          icon: "error",
          title: "Please Select Any Service",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      handleClose();
      Swal.fire({
        icon: "error",
        title: "Please Login Your Account",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  // Visit Popup------x-------------x------------------

  const visitPopupBg = {
    background: "#00000061",
  };

  const visitPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    minWidth: "40vw",
    minHeight: "50vh",
  };

  const imagePopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 1,
    minWidth: { lg: "80vw", sm: "70vh", xs: "50vh" },
    minHeight: { xl: "50vh", lg: "60vh", md: "50vh" },
    borderRadius: "6px",
  };

  // Check Number Digit Is Smaller Than 1
  function formatNumber(num) {
    // Convert the number to a string
    num = num.toString();

    // Check if the string has only one character
    if (num.length === 1) {
      // Add a leading '0'
      num = "0" + num;
    }

    return num;
  }

  const submitVisitShedule = async (value) => {
    if (userId !== undefined && userId !== null) {
      if (
        value !== null &&
        value.$y !== null &&
        value.$M !== null &&
        value.$D !== null &&
        value.$H !== null &&
        value.$H !== 0
      ) {
        const visitDate =
          value.$y +
          "-" +
          formatNumber(value.$M + 1) +
          "-" +
          formatNumber(value.$D);
        const visitTime = formatNumber(value.$H) + ":" + formatNumber(value.$m);
        // console.log("visitDate - ", visitDate, "visitTime - ", visitTime);
        const response = await postApihandler(
          `/bookingForPropertyVisit/${userId}/${propertyId}/`,
          {
            date: visitDate,
            time: visitTime,
          }
        );

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Visit Request Has Sent Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.error.response.data.message,
            buttons: true,
            confirmButtonText: "Pay",
            buttonsStyling: true,
            customClass: {
              confirmButton: "swal-button--custom",
            },
          }).then((willDelete) => {
            if (willDelete) {
              window.location.href = "/#/payment/visit-payment/book-visit";
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Please Select Date & Time Both",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      navigate("/signup-login");
    }
  };

  // Get Date From Full Data --------
  const getDate = (val) => {
    let d = new Date(val);
    d.getDate();
    return `${d.getFullYear()}/${d.getMonth()}/${d.getDate()}`;
  };

  const [open1, setOpen1] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen1(true);
    setScroll(scrollType);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open1) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open1]);

  const videoSlider = () => {
    if (PropertiesList !== undefined) {
      return PropertiesList.map((val, ind) => {
        if (val.video !== "") {
          return (
            <Carousel.Item interval={3000 + ind * 1000} className={`testnew`}>
              <video
                disablePictureInPicture
                autoPlay
                loop
                controls
                // style={{ maxHeight: "350px" }}
              >
                <source src={`${imageUrl + val.video}`} type="video/mp4" />{" "}
              </video>
            </Carousel.Item>
          );
        }
      });
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc);
    setModalOpen(true);
  };

  return (
    <Layout>
      <>
        {/* <div>
          <iframe
            width="853"
            height="480"
            src="https://my.matterport.com/show/?m=xJP6CrHES9r"
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
        </div> */}
        <section className="singleProperty-listing pt-5  outer-div-single-property">
          <Container maxWidth="100%" className="p-0">
            <Grid container className="justify-content-start px-xl-0 px-3">
              {PropertiesList !== undefined &&
                PropertiesList.map((val, ind) => {
                  return (
                    <Grid item lg={7} xs={11} className="" key={ind}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography
                            variant="body1"
                            component="h6"
                            className="text-start "
                            style={{
                              fontWeight: 400,
                              color: "#989898",
                              lineHeight: "1.5",
                            }}
                          >
                            Last update at : {getDate(val.updatedAt)}
                          </Typography>
                        </div>
                        <div>
                          <RWebShare
                            data={{
                              text: "Check out this property - ",
                              url: `http://gharaaj.com/#/property-details/${val._id}`,
                              title: "Share Property",
                            }}
                            onClick={() => console.log("shared successfully!")}
                          >
                            <IconButton sx={{ mr: 4 }}>
                              <ShareIcon
                                sx={{
                                  color: "#666666",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </RWebShare>
                          {/* <Share
                          label="Share"
                          title="My Web Share Adventures"
                          text="Hello World! I shared this content via Web Share"
                        /> */}
                        </div>
                      </div>
                      <Typography
                        className="locality-title text-start "
                        sx={{
                          textTransform: "capitalize",
                          textAlign: "start",
                          letterSpacing: "0em",
                        }}
                      >
                        {val.propertyName}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="h6"
                        className=" text-start py-2"
                        style={{ fontWeight: 300, color: "#666666" }}
                      >
                        Only For {val.available_for[0]} &nbsp;&nbsp; |
                        &nbsp;&nbsp;
                        {val.bhk_of_house} Available
                      </Typography>
                      <Typography className="fw-medium text-dark pb-4">
                        Property Id: {val._id}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid
              container
              spacing={2}
              className="justify-content-xl-between justify-content-center pb-4"
            >
              {PropertiesList !== undefined &&
              PropertiesList !== null &&
              PropertiesList.length !== 0 ? (
                PropertiesList.map((val, ind) => {
                  return (
                    <>
                      <Grid
                        item
                        lg={7}
                        xs={11}
                        className="singleProperties-Box"
                        key={ind}
                      >
                        <div className="card-mediaWrapper position-relative">
                          <div className="cardImg-wrapper">
                            <Carousel>
                              {val.propertyImages.map((imgsrc, i) => (
                                <Carousel.Item
                                  key={i}
                                  interval={3000 + i * 1000}
                                >
                                  <div
                                    style={{
                                      cursor: "zoom-in",
                                      height: "350px",
                                      overflow: "hidden", // Ensures the image does not overflow
                                      display: "flex", // Flexbox helps to align items
                                      justifyContent: "center", // Centers the image horizontally
                                      alignItems: "center", // Centers the image vertically
                                    }}
                                  >
                                    <ModalImage
                                      small={`${imageUrl + imgsrc.images}`}
                                      large={`${imageUrl + imgsrc.images}`}
                                      alt={`Property Image ${i + 1}`}
                                      hideDownload={true} // Optionally hide the download button
                                      hideZoom={true} // Disable built-in zoom feature
                                      className="carousel-image"
                                      style={{
                                        width: "100%", // Makes sure the image takes up the full width
                                        height: "auto", // Maintains the aspect ratio
                                        objectFit: "contain", // Ensures the image covers the entire div without distortion
                                      }}
                                    />
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div>
                        </div>
                        <Box className="px-4 py-2">
                          <Box className="single-properties-details-wrapper px-0">
                            <Box className="rent-details-wrapper p-0">
                              <Typography
                                variant="subtitle1"
                                component="h5"
                                className="property-type-title text-capitalize mb-2"
                                style={{
                                  fontWeight: 500,
                                  color: "#000",
                                }}
                              >
                                {val.propertyType === "Rent" ? "Rent" : "Sell"}{" "}
                                this house at
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "2rem",
                                }}
                              >
                                <div>
                                  <Typography
                                    className=" text-start"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    {val.propertyType === "Rent"
                                      ? "Rent"
                                      : "Sell"}{" "}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start"
                                    style={{ fontWeight: 500, color: "#000" }}
                                  >
                                    ₹{val.price}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    className=" text-start"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    Security deposit
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start"
                                    style={{ fontWeight: 500, color: "#000" }}
                                  >
                                    ₹{val.deposite_money}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box className="rent-details-wrapper p-0">
                              <Typography
                                variant="subtitle1"
                                component="h5"
                                className="property-type-title text-capitalize mb-2"
                                style={{
                                  fontWeight: 500,
                                  color: "#000",
                                }}
                              >
                                Society
                              </Typography>
                              <Typography
                                className=" text-start"
                                style={{ fontWeight: 500, color: "#666666" }}
                              >
                                {val.society}
                              </Typography>
                            </Box>
                            <Box className="address-details-wrapper mt-3">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                className="flex-md-row flex-column align-items-md-center align-items-start"
                              >
                                <Typography
                                  variant="subtitle1"
                                  component="h5"
                                  className="property-type-title text-capitalize mb-2"
                                  style={{
                                    fontWeight: 500,
                                    color: "#000",
                                  }}
                                >
                                  Address{" "}
                                  {"Near " + val.address + ", " + val.city}
                                </Typography>
                                <Link
                                  to={`https://www.google.com/maps/place/${
                                    val.address +
                                    "+" +
                                    val.city +
                                    "+madhya+pradesh"
                                  }`}
                                  target="_blank"
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#02C988",
                                      color: "#fff",
                                      textTransform: "capitalize",
                                      paddingTop: ".2rem",
                                      paddingBottom: ".2rem",
                                      fontSize: "16px",
                                    }}
                                    className="px-3"
                                  >
                                    Show on Map
                                  </Button>
                                </Link>
                              </div>
                              <div className="mt-md-0 mt-4">
                                <Typography
                                  variant="body1"
                                  component="p"
                                  className=" text-start mb-2 p-2 width-aadress-single-page"
                                  style={{
                                    fontWeight: 500,
                                    color: "#ffff",
                                    background: "rgb(2, 201, 136)",
                                    borderRadius: "12px",
                                  }}
                                >
                                  <ApartmentIcon />
                                  &nbsp;
                                  {"Near" +
                                    val.nearBy +
                                    ", " +
                                    val.address +
                                    ", " +
                                    val.city}
                                </Typography>
                              </div>
                            </Box>
                            <Box className="house-features-wrapper mt-3">
                              <Typography
                                variant="subtitle1"
                                component="h5"
                                className="property-type-title text-capitalize mb-2"
                                style={{
                                  fontWeight: 500,
                                  color: "#000",
                                }}
                              >
                                House Features
                              </Typography>
                              <Grid container>
                                <Grid item lg={6} xs={6}>
                                  <div style={{ paddingLeft: "0rem" }}>
                                    <img
                                      src={balconyImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Balcony
                                    </span>{" "}
                                    - {val.balcony}
                                  </Typography>
                                  <div style={{ paddingLeft: "0rem" }}>
                                    <img
                                      src={buildingArea}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Buildup Area
                                    </span>{" "}
                                    - {val.buildUpArea}
                                  </Typography>
                                  <div>
                                    <img
                                      src={FacingImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Facing -{" "}
                                    </span>
                                    {val.facing}
                                  </Typography>
                                  <div>
                                    <img
                                      src={furnishedImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Furnishing -{" "}
                                    </span>
                                    {val.furnishing}
                                  </Typography>
                                  <div>
                                    <img
                                      src={BedImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>

                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Bedroom{" "}
                                    </span>{" "}
                                    - {val.bhk_of_house}
                                  </Typography>
                                </Grid>
                                <Grid item lg={6} xs={6}>
                                  <div>
                                    <img
                                      src={bathroomImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Bathrooms{" "}
                                    </span>{" "}
                                    - {val.bathrooms}
                                  </Typography>
                                  <div>
                                    <img
                                      src={carpetImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Carpet Area -{" "}
                                    </span>
                                    {val.carpetArea}
                                  </Typography>
                                  <div>
                                    <img
                                      src={YearImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Construction Year -{" "}
                                    </span>
                                    {val.construction_year}
                                  </Typography>
                                  <div>
                                    <img
                                      src={availableImg}
                                      style={{ width: "10%" }}
                                    />
                                  </div>

                                  <Typography
                                    variant="body1"
                                    component="p"
                                    className=" text-start mb-2"
                                    style={{
                                      fontWeight: 500,
                                      color: "#666666",
                                    }}
                                  >
                                    <span style={{ color: "#000" }}>
                                      Available From -{" "}
                                    </span>
                                    {getDate(val.availableFrom)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={5}
                        xs={10}
                        className="singleProperties-Box mt-xl-0 mt-0 Hidden-Section"
                        key={ind}
                      >
                        <Card style={{ boxShadow: "none" }}>
                          <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={value}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <TabList
                                  onChange={handleChange123}
                                  aria-label="lab API tabs example"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Tab
                                    label="Visit this property"
                                    value="1"
                                    sx={{ width: "50%", color: "black" }}
                                  />

                                  <Tab
                                    label="Book"
                                    value="2"
                                    sx={{ width: "50%", color: "black" }}
                                  />
                                </TabList>
                              </Box>
                              <TabPanel value="1">
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={visitProperty}
                                    style={{ width: "30%" }}
                                  />
                                </div>
                                <Typography className="fs-5 fw-medium text-center">
                                  FREE Guided Tour
                                </Typography>
                                <Typography className="fs-6 text-body-secondary text-center">
                                  with our executive
                                </Typography>
                                <Link
                                  to={`${
                                    userId !== undefined && userId !== null
                                      ? `/payment/property-payment/${val._id}`
                                      : "/signup-login"
                                  }`}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "rgba(2, 201, 136, 1)",
                                      color: "#FFF",
                                      textTransform: "capitalize",
                                      width: "100%",
                                    }}
                                    className="mt-3 rounded-0"

                                    // onClick={() => {
                                    //   handleOpen();
                                    //   setPopupProperty(1);
                                    // }}
                                  >
                                    Book
                                    <span
                                      style={{
                                        color: "rgba(31, 64, 105, 1)",
                                        fontSize: "18px",
                                      }}
                                      className="different-font"
                                    >
                                      Now
                                    </span>
                                  </Button>
                                </Link>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "rgba(2, 201, 136, 1)",
                                    color: "#FFF",
                                    textTransform: "capitalize",
                                    width: "100%",
                                  }}
                                  className="mt-3 rounded-0"
                                  onClick={() => {
                                    if (
                                      userId !== undefined &&
                                      userId !== null
                                    ) {
                                      handleOpen();
                                      setPropertyId(searchKey);
                                      setModelBtnCount(1);
                                    } else {
                                      navigate("/signup-login");
                                    }
                                  }}
                                >
                                  Visit For{" "}
                                  <span
                                    style={{
                                      color: "rgba(31, 64, 105, 1)",
                                      fontSize: "18px",
                                    }}
                                    className="different-font"
                                  >
                                    {" "}
                                    Free
                                  </span>
                                </Button>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="p-3"
                                >
                                  <div>
                                    <img
                                      src={calenderImg}
                                      style={{ width: "50%" }}
                                    />
                                  </div>
                                  <div>
                                    <Typography className="fs-6 fw-medium ">
                                      Move-in available from
                                      <br />
                                      {getDate(val.availableFrom)}
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="p-3"
                                >
                                  <div>
                                    <img
                                      src={clockImg}
                                      style={{ width: "50%" }}
                                    />
                                  </div>
                                  <div>
                                    <Typography className="fs-6 fw-medium ">
                                      5 people scheduled visit.
                                      <br /> avoid missing out.
                                    </Typography>
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel value="2">
                                <div style={{ textAlign: "center" }}>
                                  <img src={BookImg} style={{ width: "30%" }} />
                                </div>
                                <Typography className="fs-5 fw-medium text-center">
                                  Pay token & book
                                </Typography>
                                <Typography className="fs-6 text-body-secondary text-center">
                                  And the remaining before moving in.
                                </Typography>
                                <Link
                                  to={`${
                                    userId !== undefined && userId !== null
                                      ? "/payment/property-payment/booking"
                                      : "/signup-login"
                                  }`}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "rgba(2, 201, 136, 1)",
                                      color: "#FFF",
                                      textTransform: "capitalize",
                                      width: "100%",
                                    }}
                                    className="mt-3 rounded-0"

                                    // onClick={() => {
                                    //   handleOpen();
                                    //   setPopupProperty(1);
                                    // }}
                                  >
                                    Book
                                    <span
                                      style={{
                                        color: "rgba(31, 64, 105, 1)",
                                        fontSize: "18px",
                                      }}
                                      className="different-font"
                                    >
                                      Now
                                    </span>
                                  </Button>
                                </Link>
                              </TabPanel>
                            </TabContext>
                          </Box>
                        </Card>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <p className="px-3 py-5 font-bold text-center w-100">
                  No Result Found
                </p>
              )}
              {/* single properties */}
            </Grid>
            <Grid
              container
              spacing={2}
              className="justify-content-md-start justify-content-center"
            >
              {PropertiesList !== undefined &&
                PropertiesList.map((val, ind) => {
                  return (
                    <Grid
                      item
                      lg={5.5}
                      xs={11}
                      className="singleProperties-Box pb-5 "
                      key={ind}
                    >
                      <Box
                        // style={{ boxShadow: "0px 0px 2px 2px #00000040" }}
                        className="px-xl-2 px-2"
                      >
                        <Box className="single-properties-details-wrapper">
                          <Box className="amenity-details-wrapper ">
                            <Typography
                              variant="subtitle1"
                              component="h5"
                              className="property-type-title text-capitalize mb-2 text-md-start text-center"
                              style={{
                                fontWeight: 500,
                                color: "#000",
                              }}
                            >
                              Society Amenity
                            </Typography>
                            <Grid
                              container
                              className="justify-content-md-start justify-content-center"
                            >
                              <Grid
                                item
                                lg={8}
                                className=" d-flex align-items-center"
                              >
                                {amenitiesList !== undefined &&
                                  amenitiesList !== null &&
                                  amenitiesList.map((val, ind) => {
                                    return (
                                      <div
                                        className="amenity-wrapper d-flex"
                                        key={ind}
                                      >
                                        <img
                                          src={`${
                                            imageUrl + val.amenitiesImage
                                          }`}
                                          style={{ width: "20px" }}
                                        />
                                        <Typography
                                          variant="body1"
                                          component="p"
                                          className=" text-start mb-2 me-5 ps-2"
                                          style={{
                                            fontWeight: 500,
                                            color: "#666666",
                                          }}
                                        >
                                          {val.amenitiesName}
                                        </Typography>
                                      </div>
                                    );
                                  })}
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className="description-wrapper mt-1">
                            <Typography
                              variant="subtitle1"
                              className=" text-capitalize mb-2"
                              style={{
                                fontWeight: 500,
                                color: "#000",
                                fontSize: "14px",
                              }}
                            >
                              About House
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start mb-2"
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              {val.description}
                            </Typography>
                          </Box>
                          <Box className="description-wrapper mt-4">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                className=" text-capitalize mb-2"
                                style={{
                                  fontWeight: 500,
                                  color: "#000",
                                  fontSize: "14px",
                                }}
                              >
                                Security Deposit <br />
                                (Instant refund)
                              </Typography>
                              <Typography
                                variant="body1"
                                component="p"
                                className=" text-start "
                                style={{ fontWeight: 500, color: "#666666" }}
                              >
                                ₹{val.deposite_money}
                              </Typography>
                            </div>

                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              Fully refundable within 1-7 days if vacated in
                              original condition
                            </Typography>
                          </Box>
                          <Box className="description-wrapper mt-4">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                className=" text-capitalize mb-2"
                                style={{
                                  fontWeight: 500,
                                  color: "#000",
                                  fontSize: "14px",
                                }}
                              >
                                One Time Gharaaj Fees
                              </Typography>
                              <Typography
                                variant="body1"
                                component="p"
                                className=" text-start "
                                style={{ fontWeight: 500, color: "#666666" }}
                              >
                                ₹
                                {val.propertyType === "Rent"
                                  ? val.price / 2
                                  : val.price / 50}
                              </Typography>
                            </div>

                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              Gharaaj charges a one time accommodation
                              convenience fee of Rs.
                              {val.propertyType === "Rent"
                                ? val.price / 2
                                : val.price / 50}
                              .
                            </Typography>

                            <Button
                              onClick={handleOpen4}
                              variant="contained"
                              style={{
                                backgroundColor: "#02C988",
                                color: "#fff",
                                textTransform: "capitalize",
                                paddingTop: ".2rem",
                                paddingBottom: ".2rem",
                                fontSize: "16px",
                              }}
                              className="px-3 mt-4"
                            >
                              Want to save this fees?
                            </Button>
                          </Box>
                          <Box className="description-wrapper mt-4">
                            <Typography
                              variant="subtitle1"
                              className=" text-capitalize mb-2"
                              style={{
                                fontWeight: 500,
                                color: "#000",
                                fontSize: "16px",
                              }}
                            >
                              For this house...
                            </Typography>
                            <Typography
                              variant="body1"
                              component="h6"
                              className=" text-start mb-2"
                              style={{
                                fontWeight: 500,
                                color: "#000",
                                fontSize: "14px",
                              }}
                            >
                              Gharaaj is responsible for
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              <DoneIcon style={{ color: "#00ca95" }} />
                              Showing this house
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              <DoneIcon style={{ color: "#00ca95" }} />
                              Booking confirmation with owner
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              <DoneIcon style={{ color: "#00ca95" }} />
                              Creating rental agreement with owner
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              <DoneIcon style={{ color: "#00ca95" }} />
                              Post move-in services and maintenance at nominal
                              prices
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start "
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              <DoneIcon style={{ color: "#00ca95" }} />
                              Returning deposit at the time of move-out
                            </Typography>
                          </Box>
                          <Box className="description-wrapper mt-4">
                            <Typography
                              variant="subtitle1"
                              component="h5"
                              className="property-type-title text-capitalize mb-2"
                              style={{
                                fontWeight: 500,
                                color: "#000",
                                fontSize: "14px",
                              }}
                            >
                              Terms of Stay
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-start mb-2"
                              style={{ fontWeight: 500, color: "#666666" }}
                            >
                              The dos and dont's of staying in a Gharaaj home
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className=" text-end mb-2"
                              style={{ fontWeight: 500, color: "#00ca95" }}
                            ></Typography>
                            <div style={{ textAlign: "start" }}>
                              <Button
                                onClick={handleClickOpen("paper")}
                                variant="contained"
                                style={{
                                  backgroundColor: "#02C988",
                                  color: "#fff",
                                  textTransform: "capitalize",
                                  paddingTop: ".2rem",
                                  paddingBottom: ".2rem",
                                  fontSize: "16px",
                                }}
                                className="px-3"
                              >
                                View
                              </Button>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
            <div>
              <Typography
                variant="subtitle1"
                component="h5"
                className="property-type-title text-capitalize mb-4 text-md-start text-center"
                style={{
                  fontWeight: 500,
                  color: "#000",
                  fontSize: "18px",
                }}
              >
                Similar Houses
              </Typography>
            </div>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex" }}
              className="justify-content-md-start justify-content-center py-4"
            >
              {similarProperty.length === 0
                ? ""
                : similarProperty.slice(0, 3).map((val) => {
                    return (
                      <Grid lg={4} md={12} sx={12} className="mb-md-0 mb-4">
                        <Link to={`/property-details/${val._id}`}>
                          <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                image={imageUrl + val.propertyImages[0].images}
                                alt="Property Image"
                                sx={{ height: "15rem" }}
                              />
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="p"
                                  component="div"
                                  sx={{ color: "#d45252" }}
                                >
                                  {val.address} ,{val.propertyName} ,{val.city}
                                </Typography>

                                <div style={{ display: "flex", gap: "1rem" }}>
                                  <div>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {val.furnishing}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {val.propertyType}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      ₹{val.price}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      |
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      |
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      [{val.buildUpArea}Sq]
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    ></Typography>
                                  </div>
                                </div>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Link>
                      </Grid>
                    );
                  })}
            </Grid>
            {/* <div
            className="buttonDisplay position-fixed"
            style={{
              zIndex: "9",
              paddingLeft: "3.5rem",
              paddingRight: "3.5rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              bottom: "1%",
              right: "1%",
              background: "#1f4069",
            }}
          >
            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgba(2, 201, 136, 1)",
                  color: "#FFF",
                  textTransform: "capitalize",
                  paddingTop: ".6rem",
                  paddingBottom: ".6rem",
                }}
                className="px-md-3 px-3"
                onClick={() => {
                  handleOpen();
                  setPropertyId(searchKey);
                  setModelBtnCount(1);
                }}
              >
                Visit For{" "}
                <span
                  style={{
                    color: "rgba(31, 64, 105, 1)",
                    fontSize: "18px",
                  }}
                  className="different-font"
                >
                  {" "}
                  Free
                </span>
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgba(2, 201, 136, 1)",
                  color: "#FFF",
                  textTransform: "capitalize",
                  paddingTop: ".6rem",
                  paddingBottom: ".6rem",
                }}
                className="px-md-3 px-3"
                // onClick={() => {
                //   handleOpen();
                //   setPopupProperty(1);
                // }}
              >
                Book
                <span
                  style={{
                    color: "rgba(31, 64, 105, 1)",
                    fontSize: "18px",
                  }}
                  className="different-font"
                >
                  Now
                </span>
              </Button>
            </div>
          </div> */}
          </Container>
          {PropertiesList !== undefined &&
            PropertiesList.map((val, ind) => {
              return (
                <Box className="d-md-none d-flex gx-5 position-fixed bottom-0 w-100 bg-white pb-2">
                  <Link
                    to={`${
                      userId !== undefined && userId !== null
                        ? "/payment/property-payment/booking"
                        : "/signup-login"
                    }`}
                    className="w-50 mx-2"
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgba(2, 201, 136, 1)",
                        color: "#FFF",
                        textTransform: "capitalize",
                        width: "100%",
                      }}
                      className="mt-3 rounded-0 "

                      // onClick={() => {
                      //   handleOpen();
                      //   setPopupProperty(1);
                      // }}
                    >
                      Book
                      <span
                        style={{
                          color: "rgba(31, 64, 105, 1)",
                          fontSize: "18px",
                        }}
                        className="different-font"
                      >
                        Now
                      </span>
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "rgba(2, 201, 136, 1)",
                      color: "#FFF",
                      textTransform: "capitalize",
                      // width: "100%",
                    }}
                    className="mt-3 rounded-0 w-50 mx-2"
                    onClick={() => {
                      if (userId !== undefined && userId !== null) {
                        handleOpen();
                        setPropertyId(searchKey);
                        setModelBtnCount(1);
                      } else {
                        navigate("/signup-login");
                      }
                    }}
                  >
                    Visit For{" "}
                    <span
                      style={{
                        color: "rgba(31, 64, 105, 1)",
                        fontSize: "18px",
                      }}
                      className="different-font"
                    >
                      {" "}
                      Free
                    </span>
                  </Button>
                </Box>
              );
            })}

          {/* Tenent plane model */}
          <Modal
            open={open4}
            onClose={handleClose4}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ color: "#1f4069", fontWeight: "500" }}
              >
                <span className="fs-2">G</span>et a Tenant plans <br />
                <span className="fs-2">S</span>tarting from ₹1499
                <br />
                And get 100% discount on Gharaaj.
              </Typography>
              <Link
                to={`/tenant-plan`}
                // onClick={handleRefresh}
              >
                <Button
                  className="mt-5"
                  style={{
                    color: "#fff",
                    background: "#1F4069",
                    borderRadius: "4px",
                  }}
                >
                  Get Tenant plans
                </Button>
              </Link>
            </Box>
          </Modal>
          {/* Visit Popup */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
              handleClose();
              setImageModelOpen(false);
              setModelBtnCount(0);
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={visitPopupBg}
          >
            <Fade in={open}>
              <Box sx={!imageModelOpen ? visitPopupStyle : imagePopupStyle}>
                <div
                  className={`model-header wrapper d-flex justify-content-between align-content-center d-${
                    imageModelOpen ? "none" : "block"
                  }`}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {modelBtnCount === 1
                      ? "Book Visit Schedule"
                      : modelBtnCount === 2
                      ? "Book Services"
                      : ""}
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h4"
                    className="closeModel"
                  >
                    <span
                      onClick={() => {
                        handleClose();
                        setImageModelOpen(false);
                        setModelBtnCount(0);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      X
                    </span>
                  </Typography>
                </div>
                <div
                  className={`visit-shedule-wrapper d-${
                    modelBtnCount === 1 ? "block" : "none"
                  }`}
                >
                  <Typography id="transition-modal-description">
                    Pick a time for a Free assisted house visit
                  </Typography>
                  <div className="d-md-block d-none">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDateTimePicker
                        orientation="landscape"
                        onAccept={(value) => {
                          submitVisitShedule(value);
                          handleClose();
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="d-md-none d-block">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["StaticDateTimePicker"]}>
                        <DemoItem label="Static variant">
                          <StaticDateTimePicker
                            // defaultValue={dayjs("2022-04-17T15:30")}
                            onAccept={(value) => {
                              submitVisitShedule(value);
                              handleClose();
                              // console.log("Mobile Visit Time Submitted", value);
                            }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
                <div
                  className={`book-services-wrapper d-${
                    modelBtnCount === 2 ? "block" : "none"
                  }`}
                >
                  <Typography id="transition-modal-description">
                    Book Additional Services:
                  </Typography>
                  <div className="mt-5">
                    <Grid container spacing={2} className="m-0">
                      <Grid md={6}>
                        <FormControl variant="standard">
                          <InputLabel id="demo-simple-select-standard-label">
                            Select Services
                          </InputLabel>
                          <Select
                            sx={{ mt: 2, minWidth: { lg: 214, xs: 435 } }}
                            variant="standard"
                            id="demo-simple-select-standard"
                            onChange={(e) => setServiceId(e.target.value)}
                            value={ServiceId}
                          >
                            <MenuItem value="">
                              <em>Services...</em>
                            </MenuItem>
                            {servicesList !== null &&
                              servicesList !== undefined &&
                              servicesList.map((val, ind) => {
                                return (
                                  <MenuItem value={val._id}>
                                    {val.serviceName}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid md={6}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "rgba(2, 201, 136, 1)",
                            color: "#FFF",
                            textTransform: "capitalize",
                            paddingTop: ".6rem",
                            paddingBottom: ".6rem",
                          }}
                          className="px-md-3 px-3"
                          onClick={() => {
                            // handleOpen();
                            // setModelBtnCount(2);
                            bookService();
                          }}
                        >
                          Book
                          <span
                            style={{
                              color: "rgba(31, 64, 105, 1)",
                              fontSize: "18px",
                            }}
                            className="different-font"
                          >
                            Services
                          </span>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div
                  className={`imageGallery-wrapper d-${
                    !imageModelOpen ? "none" : "block"
                  }`}
                >
                  {PropertiesList !== undefined ? (
                    PropertiesList.map((val, ind) => {
                      return (
                        <Grid
                          item
                          lg={6}
                          xs={11}
                          className="singleProperties-Box"
                          key={ind}
                        >
                          <div className="card-mediaWrapper position-relative ">
                            <div className="cardImg-wrapper">
                              <Carousel>
                                {val.propertyImages.map((imgsrc, i) => (
                                  <Carousel.Item
                                    key={i}
                                    interval={3000 + i * 1000}
                                  >
                                    <div style={{ cursor: "zoom-in" }}>
                                      <ModalImage
                                        small={`${imageUrl + imgsrc.images}`}
                                        large={`${imageUrl + imgsrc.images}`}
                                        alt={`Property Image ${i + 1}`}
                                        hideDownload={true} // Optionally hide the download button
                                        hideZoom={true} // Disable built-in zoom feature
                                      />
                                    </div>
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            </div>
                          </div>
                        </Grid>
                      );
                    })
                  ) : (
                    <p className="px-3 py-5 font-bold text-center w-100">
                      No Result Found
                    </p>
                  )}
                </div>
              </Box>
            </Fade>
          </Modal>
          {/* Term and Stay */}
          <Dialog
            open={open1}
            onClose={handleClose1}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <p style={{ color: "black" }}>
                  {" "}
                  Gharaaj manages the owner’s house and enables renting out to
                  tenants. Hence, only repairs and maintenance within the
                  purview of the house like electrical, plumbing, and carpentry
                  issues can be availed through Gharaaj’s services in accordance
                  with the terms. Gharaaj does not have a say in matters
                  pertaining to the neighbourhood, society, and locality not
                  managed by the platform.
                </p>
                <h6 style={{ color: "black" }}>Booking</h6>
                <h6 style={{ color: "#954628" }}>TOKEN CHARGES</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Percentage of one month’s rent which is part of Security
                  Deposit, to be paid at the time of booking. If the license
                  start date is within 2 days of booking, tenant has to pay
                  Security Deposit as well. The token is not-refundable if the
                  booking is cancelled.
                </p>
                <h6 style={{ color: "#954628" }}>EARLY TERMINATION CHARGES</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  If tenants moves out of a Gharaaj house before completing 6
                  months of stay, they will be required to pay an amount
                  equivalent to one month's rent. If tenants opt for Internal
                  Transfer within 6 Months, 50% of one month's rent will be
                  charged.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Why these charges? Our house owners prefer their properties to
                  be rented out for a long duration (also the reason for 11
                  month agreements). Sometimes frequent move in and move out
                  also hampers the condition of the house & overall experience
                  of tenants and owners.
                </p>
                <h6 style={{ color: "black" }}>Living</h6>
                <h6 style={{ color: "#954628" }}>
                  FOR A SECURE & PEACEFUL LIVING EXPERIENCE
                </h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Fellow Nesties and their guests or Area Managers may access
                  your house at times (with prior intimation) to show it to
                  prospective Nesties.Keeping valuables securely is suggested.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Incase issues occur between tenants in a shared house, we
                  encourage it to be resolved amicably amongst tenants without
                  involving Gharaaj.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  We encourage you to be considerate of your co-tenants and
                  respect their privacy.
                </p>
                <h6 style={{ color: "#954628" }}>SERVICE & MAINTENANCE</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  In case of any repairs or maintenance related issues, please
                  raise a service request from your dashboard.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Service visit and material (if any) charges apply to all
                  repair or maintenance related issues.
                </p>
                <h6 style={{ color: "#954628" }}>RENT LATE PAYMENT CHARGES</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  In case of non payment by the due date. Gharaaj charges a late
                  payment fee of INR 100 per day till the date the dues are
                  cleared
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Non-payment of rent post 15th of the month would result in a
                  breach of tenancy terms and the tenant would be required to
                  vacate the scheduled premises immediately.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  These charges are levied in order to encourage tenants to pay
                  by due date. This is also to avoid financial inconvenience to
                  owners and facilitate payment of rent to owner by Gharaaj, on
                  time.
                </p>
                <h6 style={{ color: "#954628" }}>UTILITIES</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  All utilities account details and payment related information
                  are accessible through the tenant dashboard.
                </p>
                <h6 style={{ color: "#954628" }}>RENT INCREMENT</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Rent would be increased per terms of agreement post 11 months
                  of stay. Execution charges for the revised agreement would be
                  applicable.
                </p>
                <h6 style={{ color: "#954628" }}>PAYMENT GATEWAY CHARGES</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Additional 2% (exclusive of taxes) is applicable on payments
                  made via payment gateway. These charges can be avoided by
                  paying via UPI.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Why these charges? A nominal fee, charged by the payment
                  gateway provider and not by Gharaaj.
                </p>
                <h6 style={{ color: "black" }}>Guest Hosting Policy</h6>
                <h6 style={{ color: "#954628" }}>HOSTING POLICY</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Gharaaj has an open guest policy - to host guests please
                  discuss with your co-tenants and get a recorded consent
                  through Whatsapp, email or text message.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  At homes governed by policies of Society Association, recorded
                  consent of the society association in addition to that of the
                  co-tenants is required.
                </p>
                <h6 style={{ color: "black" }}>Policy for keeping pets</h6>
                <h6 style={{ color: "#954628" }}>POLICY</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Pets can be kept if fellow tenants are comfortable and approve
                  of the same.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  At homes governed by policies of Society Association, please
                  adhere to society rules for pets, which might include a small
                  fee for maintenance.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Take good care of pets and your house.
                </p>
                <h6 style={{ color: "black" }}>Move-out</h6>
                <h6 style={{ color: "#954628" }}>NOTICE PERIOD CHARGES</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Tenant has to provide 45-days' of notice while vacating. Any
                  shortfall in notice period will be charged on a pro-rata basis
                  where the charges would be equal to the no. of days of
                  shortfall of the 45 day period.
                </p>
                <h6 style={{ color: "#954628" }}>REFUND</h6>
                <p style={{ color: "black" }}>
                  {" "}
                  Any unpaid rent and utility payments will be deducted from the
                  security deposit.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  In case of any damage to the property or furnishings, charges
                  for the same will be deducted from the security deposit.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  All refunds shall be processed via online transfer.
                </p>
                <p style={{ color: "black" }}>
                  {" "}
                  Gharaaj will refund within 7 days of moving out (Not
                  applicable for zero deposit houses)
                </p>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </section>
        <PartnersList />
      </>
    </Layout>
  );
};

export default SingleProperty;
