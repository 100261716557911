import React from "react";
import Layout from "../../LayoutWrapper/AdminLayout";
import { useEffect } from "react";

const Booking = () => {
  useEffect(() => {
    localStorage.setItem("activatedPage", "booking");
  }, []);
  return (
    <Layout>
      <p className="font-bold text-center">Booking Now...</p>
    </Layout>
  );
};

export default Booking;
