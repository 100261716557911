import React from "react";
import { Button, Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import FreeConsultantImg from "../../Images/Home/free-cosultant-module.png";
import AudioConsultant from "../../Images/Home/audio-consultant.svg";
import VideoConsultant from "../../Images/Home/video-consultant.svg";
import MeetConsultant from "../../Images/Home/meet-consultant.svg";
import { Link } from "react-router-dom";

const FreeConsultant = () => {
  return (
    <section className="free-consultant-book py-md-5 py-4">
      <Container maxWidth="100%" className="">
        <Grid
          container
          spacing={2}
          className="justify-content-between flex-xl-row flex-column flex-xl-nowrap"
        >
          <Grid
            item
            lg={5}
            className="py-lg-5 py-3 d-flex align-items-center justify-content-xl-start justify-content-center"
          >
            <Box className="text-lg-start text-center">
              <Typography
                variant="h5"
                component="h4"
                className="smallHeading_TwoHeadings text-white mb-3 text-xl-start text-center"
              >
                Book a Free Consultant
              </Typography>
              {/* <Typography variant="body1" component="p" className="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Typography> */}
              <Box className="free-consultant-book-btns mt-4 d-flex align-items-center justify-content-lg-start justify-content-center flex-wrap">
                <Link to="tel:7999668576">
                  <Button className="consultant-btn bg-white rounded-5 d-flex border-0 align-items-center px-4 py-2 mt-lg-3 mt-xxl-0">
                    <img src={AudioConsultant} style={{ width: "22px" }} />
                    <span
                      style={{
                        color: "#1F4069",
                        fontWeight: 500,
                        paddingLeft: "10px",
                      }}
                    >
                      Audio
                    </span>
                  </Button>
                </Link>
                <Link
                  to="https://gharaaj-com.bitrix24.in/video/3wjMv2Mk"
                  target="_blank"
                >
                  <Button className="consultant-btn bg-white rounded-5 d-flex border-0 align-items-center px-4 py-2 ms-3 mt-lg-3 mt-xxl-0">
                    <img src={VideoConsultant} style={{ width: "22px" }} />
                    <span
                      style={{
                        color: "#1F4069",
                        fontWeight: 500,
                        paddingLeft: "10px",
                      }}
                    >
                      Video
                    </span>
                  </Button>
                </Link>

                <Link
                  to="https://gharaaj-com.bitrix24.in/~76Ed5"
                  target="_blank"
                >
                  <Button className="consultant-btn bg-white rounded-5 d-flex border-0 align-items-center px-4 py-2 ms-md-3 mt-md-0 mt-3 mt-lg-3 mt-xxl-0">
                    <img src={MeetConsultant} style={{ width: "22px" }} />
                    <span
                      style={{
                        color: "#1F4069",
                        fontWeight: 500,
                        paddingLeft: "10px",
                      }}
                    >
                      Personal Meet
                    </span>
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            className="pt-lg-5 pb-lg-5 pt-lg-5 pb-0 ms-lg-3 d-md-block d-none"
          >
            <Box className="free-consultant-book-benifits">
              <img src={FreeConsultantImg} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default FreeConsultant;
