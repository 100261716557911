import React, { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import "../../Pages/Home/home.css";
import {
  Box,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useParams } from "react-router-dom";
import { getApihandler, imageUrl, postApihandler } from "../../Apihandler";
import Layout from "../../LayoutWrapper/AdminLayout";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";

const SingleRecommended = () => {
  const { searchKey } = useParams();
  // console.log("searchKey - ", searchKey);

  const [PropertiesList, setPropertiesList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  // console.log("PropertiesList - ", PropertiesList);
  // const [PopupProperty, setPopupProperty] = useState(0);
  const [imageModelOpen, setImageModelOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [propertyId, setPropertyId] = useState();
  const [ServiceId, setServiceId] = useState("");
  // console.log("ServiceId - ", ServiceId);
  const [modelBtnCount, setModelBtnCount] = useState(0);

  // console.log("PopupProperty - ", PopupProperty);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  useEffect(() => {
    getFilterdProperties();
    getServices();
  }, []);

  const getFilterdProperties = async () => {
    const response = await getApihandler(
      `/getRecommondedPropertyByAdmin/${searchKey}`
    );
    // console.log("getRecommondedPropertyByAdmin", response);
    setPropertiesList(response.data);
  };

  const getServices = async () => {
    const response = await getApihandler(`/getServices/All`);
    // console.log("getServices List", response);
    setServicesList(response.data);
  };

  const bookService = async () => {
    if (userId !== undefined && userId !== null) {
      if (ServiceId !== "") {
        const response = await postApihandler(
          `/bookServices/${userId}/${ServiceId}`
        );
        // console.log("bookService====>", response);
        if (response.status === 200) {
          Swal.fire({
            position: "middle-centre",
            icon: "success",
            title: "Service Booked",
            showConfirmButton: false,
            timer: 2000,
          });
          setServiceId("");
          handleClose();
        } else {
          Swal.fire({
            position: "middle-centre",
            icon: "error",
            title: response.error.response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        handleClose();
        Swal.fire({
          // position: "middle-centre",
          icon: "error",
          title: "Please Select Any Service",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      handleClose();
      Swal.fire({
        icon: "error",
        title: "Please Login Your Account",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  // Visit Popup------x-------------x------------------

  const visitPopupBg = {
    background: "#00000061",
  };

  const visitPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { lg: "50%" },
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    minWidth: "40vw",
    minHeight: "50vh",
  };

  const imagePopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { lg: "50%" },
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 1,
    minWidth: { lg: "80vw", sm: "70vh", xs: "50vh" },
    minHeight: { xl: "50vh", lg: "60vh", md: "50vh" },
    borderRadius: "6px",
  };

  // Check Number Digit Is Smaller Than 1
  function formatNumber(num) {
    // Convert the number to a string
    num = num.toString();

    // Check if the string has only one character
    if (num.length === 1) {
      // Add a leading '0'
      num = "0" + num;
    }

    return num;
  }

  const submitVisitShedule = async (value) => {
    if (userId !== undefined && userId !== null) {
      if (
        value !== null &&
        value.$y !== null &&
        value.$M !== null &&
        value.$D !== null &&
        value.$H !== null &&
        value.$H !== 0
      ) {
        const visitDate =
          value.$y +
          "-" +
          formatNumber(value.$M) +
          "-" +
          formatNumber(value.$D);
        const visitTime = formatNumber(value.$H) + ":" + formatNumber(value.$m);
        const response = await postApihandler(
          `/bookingForPropertyVisit/${userId}/${propertyId}/`,
          {
            date: visitDate,
            time: visitTime,
          }
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Visit Request Has Sent Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        // console.log("catch error is - ");
        Swal.fire({
          icon: "error",
          title: "Please Select Date & Time Both",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please Login Your Account",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <Layout>
      <section className="singleProperty-listing">
        <Container fluid className="pt-5 px-md-5">
          <Grid
            container
            spacing={2}
            className="justify-content-lg-start justify-content-between flex-row"
          >
            {PropertiesList !== undefined ? (
              PropertiesList.map((val, ind) => {
                return (
                  <Grid
                    item
                    lg={6}
                    xs={11}
                    className="singleProperties-Box pb-5 px-md-5 mx-lg-0 mx-auto"
                    key={ind}
                  >
                    <div className="card-mediaWrapper position-relative pb-4">
                      <div className="cardImg-wrapper">
                        <Carousel>
                          {val.propertyImages.map((imgsrc, i) => {
                            return (
                              <Carousel.Item interval={3000 + ind * 1000}>
                                <img
                                  src={`${imageUrl + imgsrc.images}`}
                                  alt=""
                                  style={{
                                    height: "19rem",
                                    borderRadius: "6px",
                                    objectFit: "cover",
                                  }}
                                />
                                <div
                                  className="property-image-overlay position-absolute"
                                  style={{
                                    height: "19rem",
                                    borderRadius: "6px",
                                    width: "100%",
                                    top: 0,
                                    background: "#0000007a",
                                  }}
                                  onClick={() => {
                                    handleOpen();
                                    setImageModelOpen(true);
                                  }}
                                ></div>
                                <div
                                  className="property-type position-absolute"
                                  style={{
                                    top: "2%",
                                    left: "2%",
                                    zIndex: "1",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#222",
                                      textTransform: "capitalize",
                                      paddingTop: ".3rem",
                                      paddingBottom: ".3rem",
                                      fontSize: "16px",
                                    }}
                                    className="px-2"
                                  >
                                    {val.available_for[0]}
                                  </Button>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                      {/* <div className="favorite-wrapper position-absolute rounded-circle">
                        <IconButton aria-label="add to favorites">
                          <FavoriteIcon
                            style={{ color: "#EA3A91" }}
                            size="large"
                          />
                        </IconButton>
                      </div> */}
                    </div>
                    <Box>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Property Name - </span>
                        {val.propertyName}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Address - </span>{" "}
                        {"Near" +
                          val.nearBy +
                          ", " +
                          val.address +
                          ", " +
                          val.city}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Price - </span>
                        {val.price}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Available From - </span>
                        {val.availableFrom}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Available For - </span>
                        {val.available_for[0]}'s
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Type Of BHK - </span>
                        {val.bhk_of_house}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Balcony - </span>
                        {val.balcony}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Bathrooms - </span>
                        {val.bathrooms}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Buildup Area - </span>
                        {val.buildUpArea}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Carpet Area - </span>
                        {val.carpetArea}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Construction Year - </span>
                        {val.construction_year}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Description - </span>
                        {val.description}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Facing - </span>
                        {val.facing}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Floor - </span>
                        {val.floor}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Furnishing - </span>
                        {val.furnishing}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Hot Properties - </span>
                        {val.hotProperties}
                      </Typography>
                      <Typography variant="body1" component="h5">
                        <span className="font-bold">New Arrival - </span>
                        {val.newArrival}
                      </Typography>

                      <Typography variant="body1" component="h5">
                        <span className="font-bold">Property Type - </span>
                        {val.propertyType}
                      </Typography>
                    </Box>
                    <Box>
                      <Grid
                        container
                        className="py-5 justify-content-start"
                        columnGap={5}
                      >
                        <Grid lg={2.5} sm={2.5}>
                          <Link
                            to="https://cosmofeed.com/vp/65036a1fe415b1001ef7e53b"
                            target="_blank"
                          >
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "rgba(2, 201, 136, 1)",
                                color: "#FFF",
                                textTransform: "capitalize",
                                paddingTop: ".6rem",
                                paddingBottom: ".6rem",
                              }}
                              className="px-md-4 px-3"
                              // onClick={() => {
                              //   handleOpen();
                              //   setPopupProperty(1);
                              // }}
                            >
                              Book
                              <span
                                style={{
                                  color: "rgba(31, 64, 105, 1)",
                                  fontSize: "18px",
                                }}
                                className="different-font"
                              >
                                Now
                              </span>
                            </Button>
                          </Link>
                        </Grid>
                        <Grid lg={3.5} sm={3.5} className="mt-below350">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "rgba(2, 201, 136, 1)",
                              color: "#FFF",
                              textTransform: "capitalize",
                              paddingTop: ".6rem",
                              paddingBottom: ".6rem",
                            }}
                            className="px-md-4 px-3"
                            onClick={() => {
                              handleOpen();
                              setPropertyId(searchKey);
                              setModelBtnCount(1);
                            }}
                          >
                            Visit For{" "}
                            <span
                              style={{
                                color: "rgba(31, 64, 105, 1)",
                                fontSize: "18px",
                              }}
                              className="different-font"
                            >
                              {" "}
                              Free
                            </span>
                          </Button>
                        </Grid>
                        <Grid lg={3} sm={3.5} className="mt-md-0 mt-4">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "rgba(2, 201, 136, 1)",
                              color: "#FFF",
                              textTransform: "capitalize",
                              paddingTop: ".6rem",
                              paddingBottom: ".6rem",
                            }}
                            className="px-md-3 px-3"
                            onClick={() => {
                              handleOpen();
                              setModelBtnCount(2);
                            }}
                          >
                            Book
                            <span
                              style={{
                                color: "rgba(31, 64, 105, 1)",
                                fontSize: "18px",
                              }}
                              className="different-font"
                            >
                              Services
                            </span>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <p className="px-3 py-5 font-bold text-center w-100">
                No Result Found
              </p>
            )}
            {/* single properties */}
          </Grid>
        </Container>

        {/* Visit Popup */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => {
            handleClose();
            setImageModelOpen(false);
            setModelBtnCount(0);
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={visitPopupBg}
        >
          <Fade in={open}>
            <Box sx={!imageModelOpen ? visitPopupStyle : imagePopupStyle}>
              <div
                className={`model-header wrapper d-flex justify-content-between align-content-center d-${
                  imageModelOpen ? "none" : "block"
                }`}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {modelBtnCount === 1
                    ? "Book Visit Schedule"
                    : modelBtnCount === 2
                    ? "Book Services"
                    : ""}
                </Typography>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h4"
                  className="closeModel"
                >
                  <span
                    onClick={() => {
                      handleClose();
                      setImageModelOpen(false);
                      setModelBtnCount(0);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    X
                  </span>
                </Typography>
              </div>
              <div
                className={`visit-shedule-wrapper d-${
                  modelBtnCount === 1 ? "block" : "none"
                }`}
              >
                <Typography id="transition-modal-description">
                  Pick a time for a Free assisted house visit
                </Typography>
                <div className="d-md-block d-none">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateTimePicker
                      orientation="landscape"
                      onAccept={(value) => {
                        submitVisitShedule(value);
                        handleClose();
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="d-md-none d-block">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["StaticDateTimePicker"]}>
                      <DemoItem label="Static variant">
                        <StaticDateTimePicker
                          // defaultValue={dayjs("2022-04-17T15:30")}
                          onAccept={(value) => {
                            submitVisitShedule(value);
                            handleClose();
                            // console.log("Mobile Visit Time Submitted", value);
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div
                className={`book-services-wrapper d-${
                  modelBtnCount === 2 ? "block" : "none"
                }`}
              >
                <Typography id="transition-modal-description">
                  Book Additional Services:
                </Typography>
                <div className="mt-5">
                  <Grid container spacing={2} className="m-0">
                    <Grid md={6}>
                      <FormControl variant="standard">
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Services
                        </InputLabel>
                        <Select
                          sx={{ mt: 2, minWidth: { lg: 214, xs: 435 } }}
                          variant="standard"
                          id="demo-simple-select-standard"
                          onChange={(e) => setServiceId(e.target.value)}
                          value={ServiceId}
                        >
                          <MenuItem value="">
                            <em>Services...</em>
                          </MenuItem>
                          {servicesList !== null &&
                            servicesList !== undefined &&
                            servicesList.map((val, ind) => {
                              return (
                                <MenuItem value={val._id}>
                                  {val.serviceName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid md={6}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "rgba(2, 201, 136, 1)",
                          color: "#FFF",
                          textTransform: "capitalize",
                          paddingTop: ".6rem",
                          paddingBottom: ".6rem",
                        }}
                        className="px-md-3 px-3"
                        onClick={() => {
                          // handleOpen();
                          // setModelBtnCount(2);
                          bookService();
                        }}
                      >
                        Book
                        <span
                          style={{
                            color: "rgba(31, 64, 105, 1)",
                            fontSize: "18px",
                          }}
                          className="different-font"
                        >
                          Services
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div
                className={`imageGallery-wrapper d-${
                  !imageModelOpen ? "none" : "block"
                }`}
              >
                {PropertiesList !== undefined ? (
                  PropertiesList.map((val, ind) => {
                    return (
                      <Grid
                        item
                        lg={6}
                        xs={11}
                        className="singleProperties-Box"
                        key={ind}
                      >
                        <div className="card-mediaWrapper position-relative ">
                          <div className="cardImg-wrapper">
                            <Carousel>
                              {val.propertyImages.map((imgsrc, i) => {
                                return (
                                  <Carousel.Item interval={3000 + ind * 1000}>
                                    <img
                                      src={`${imageUrl + imgsrc.images}`}
                                      alt=""
                                      style={{
                                        height: "30rem",
                                        borderRadius: "6px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div
                                      className="property-image-overlay position-absolute"
                                      style={{
                                        height: "30rem",
                                        borderRadius: "6px",
                                        width: "100%",
                                        top: 0,
                                        background: "#0000007a",
                                      }}
                                    ></div>
                                  </Carousel.Item>
                                );
                              })}
                            </Carousel>
                            <div
                              className="imageModel-Close position-absolute"
                              style={{
                                top: "2%",
                                right: "2%",
                                zIndex: "9",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#222",
                                  textTransform: "capitalize",
                                  paddingTop: ".1rem",
                                  paddingBottom: ".1rem",
                                  fontSize: "18px",
                                  borderRadius: "20%",
                                }}
                                className="px-2"
                                onClick={() => {
                                  handleClose();
                                  setImageModelOpen(false);
                                  setModelBtnCount(0);
                                }}
                              >
                                <span style={{ cursor: "pointer" }}>X</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })
                ) : (
                  <p className="px-3 py-5 font-bold text-center w-100">
                    No Result Found
                  </p>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      </section>
    </Layout>
  );
};

export default SingleRecommended;
