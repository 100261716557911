import React from "react";
import { Button, Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import Image1 from "../../Images/Home/Image1.jpeg";
import Image2 from "../../Images/Home/Image2.jpeg";
import Image3 from "../../Images/Home/Image3.jpeg";
import Image4 from "../../Images/Home/Image4.jpeg";
import Image5 from "../../Images/Home/Image5.png";
import Image6 from "../../Images/Home/Image6.jpeg";
import { Link } from "react-router-dom";
import Localities2 from "../../Images/Home/Localities2.webp";
import Localities3 from "../../Images/Home/Localities3.webp";
import Carousel from "react-bootstrap/Carousel";

const PopularTownship = () => {
  const localitiesData = [
    {
      localityImg: Image1,
      title: "Skye luxuria 20",
      paragraph: "The best investment Opportunities",
    },
    {
      localityImg: Image2,
      title: "Apollo DB City",
      paragraph: "Explore housing socities",
    },
    {
      localityImg: Image3,
      title: "SS Infinitus",
      paragraph: "250+ Properties",
    },
    {
      localityImg: Image4,
      title: "Royal Greens",
      paragraph: "250+ Properties",
    },
    {
      localityImg: Image6,
      title: "omaxe city 1",
      paragraph: "250+ Properties",
    },
    {
      localityImg: Image5,
      title: "Highway cosmos city",
      paragraph: "250+ Properties",
    },
  ];

  return (
    <section className="popular-localities-explore py-md-5 py-0 pt-md-5 pt-5">
      <Container maxWidth="100%" className="">
        <Link to="/properties/list/all">
          <Grid
            container
            spacing={2}
            className="justify-content-between flex-lg-row flex-column flex-lg-nowrap"
          >
            <Grid
              item
              lg={12}
              className="py-lg-5 py-3 d-flex align-items-center"
            >
              <Box className="text-lg-start text-center d-flex align-items-center flex-xl-row flex-column">
                <Box className="township-tiltle-wrapper mb-lg-0 mb-4">
                  <Typography
                    variant="h5"
                    component="h4"
                    className="smallHeading_TwoHeadings mb-lg-3"
                    sx={{ color: "black" }}
                  >
                    Explore Real Estate in Popular Township
                  </Typography>
                  {/* <Typography variant="body1" component="p" className="">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Typography> */}
                </Box>
                {/* Desktop================================================ */}
                <Grid
                  container
                  spacing={1}
                  className="justify-content-md-between justify-content-center d-md-flex d-none flex-md-row flex-column flex-lg-wrap"
                >
                  {localitiesData.map((val, ind) => {
                    return (
                      <Grid
                        item
                        sm={4}
                        className="py-3 d-flex align-items-center"
                      >
                        <Grid container className="flex-nowrap">
                          <Grid item sm={3} className="me-3">
                            <img src={val.localityImg} alt="Localities" />
                          </Grid>
                          <Grid
                            item
                            sm={8}
                            className="d-flex align-items-start justify-content-center flex-column"
                          >
                            <Typography
                              variant="h5"
                              component="h6"
                              className="locality-title text-start"
                              sx={{ color: "black" }}
                            >
                              {val.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              component="p"
                              className="locality-subtitle text-start"
                              sx={{ color: "black" }}
                            >
                              {val.paragraph}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                {/* Mobile======================================================== */}
                <Grid
                  container
                  spacing={1}
                  className="justify-content-md-between justify-content-center d-md-none d-flex flex-md-row flex-column flex-lg-wrap green-carousel"
                >
                  <Carousel>
                    {localitiesData.map((val, ind) => {
                      return (
                        <Carousel.Item interval={2000} key={ind}>
                          <Grid
                            item
                            sm={4}
                            className="py-3 d-flex align-items-center justify-content-center flex-column"
                          >
                            <Grid
                              item
                              xs={8}
                              className="mb-4 township-image-wrapper"
                            >
                              <img src={val.localityImg} alt="Localities" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className="d-flex align-items-start justify-content-center flex-column"
                            >
                              <Typography
                                variant="h5"
                                component="h6"
                                className="locality-title text-start"
                                sx={{ color: "black" }}
                              >
                                {val.title}
                              </Typography>
                              <Typography
                                variant="body1"
                                component="p"
                                className="locality-subtitle text-start"
                                sx={{ color: "black" }}
                              >
                                {val.paragraph}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </Grid>
              </Box>
            </Grid>
            {/* <Grid item lg={6} className="pt-lg-5 pb-lg-5 pt-lg-5 pb-0 ms-lg-3">
            <Box className="popular-localities-explore-benifits">
              <img src={FreeConsultantImg} />
            </Box>
          </Grid> */}
          </Grid>
        </Link>
      </Container>
    </section>
  );
};

export default PopularTownship;
