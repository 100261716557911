import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import userimg from "../../Images/Universal/user.png";
import styles from "../../Css/userprofile.module.css";
import { useForm } from "react-hook-form";
import { putApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import swal from "sweetalert";

const tabs = [
  { name: "Profile Detail", path: "/user-profile" },
  { name: "Change Password", path: "/change-password", color: `#02C988` },

  // { name: "Watchlist", path: "/wishlist" },
  {
    name: "Recommended Properties",
    path: "/recommended-properties",
  },
  {
    name: "My Properties",
    path: "/my-properties",
  },
  { name: "My Visits", path: "/my-visits" },
  { name: "Track My Thing", path: "/track-my-thing" },
  // { name: "Bank Details", path: "#" },
  // { name: "Setting", path: "#" },
  { name: "Help", path: "/help" },
];
export default function ChangePasswordComponent() {
  const Navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = async (value) => {
    const id = localStorage.getItem("UserId");
    // console.log("id==>", id);
    // console.log("value==>", value);
    const response = await putApihandler(`/changeUserPassword/${id}`, value);
    // console.log("changepassword---->", response);
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Password Change Succesfully",
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
    } else {
      // console.log("Errors");
      swal("Sorry!", `${response.error.response.data.message}`, "error").then(
        (value) => {}
      );
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          // marginBottom: "2rem",
        }}
        className="flex-md-row flex-column"
      >
        <Box
          sx={{ borderRight: "solid 1px #D2D2D2" }}
          className="ps-lg-3 ps-1 userProfile_TabSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            {/* <Avatar
              alt="Remy Sharp"
              src={userimg}
              sx={{ width: 100, height: 100 }}
            /> */}
            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={
                    {
                      // borderBottom: "1px solid #E7E7E7",
                      // marginBottom: "20px",
                    }
                  }
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          <Typography className={styles.profiledetail_heading}>
            Change Password
          </Typography>
          <Typography className={styles.profiledetail_text_paragraph}>
            Let us know a little bit more about you.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
            <Grid
              container
              sx={{
                gridRowGap: "20px",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <Grid lg={7} md={7}>
                <Typography>Current Password</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="Current Password"
                  variant="outlined"
                  name="oldPassword"
                  {...register("oldPassword")}
                />
              </Grid>
              <Grid lg={7} md={7}>
                <Typography>New Password</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="New Password"
                  variant="outlined"
                  name="newPassword"
                  {...register("newPassword")}
                />
              </Grid>
              <Grid lg={7} md={7}>
                <Typography>Confirm Password</Typography>
                <TextField
                  size="small"
                  className={styles.profiledetail_textfied}
                  id="outlined-basic"
                  placeholder="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  {...register("confirmPassword")}
                />
              </Grid>
              <Grid lg={7} md={7}>
                <Button
                  className={styles.profiledetail_save}
                  variant="outlined"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
}
