import React from "react";
import Banner from "../../Components/Userprofile/banner";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import HelpAndSupport from "../../Components/Userprofile/help";

export default function HelpSupport() {
  return (
    <Layout>
      <Banner />
      <HelpAndSupport />
      <PartnersList />
    </Layout>
  );
}
