import React from "react";

const ChatWithUs = () => {
  return (
    <div>
      {(function (w, d, u) {
        var s = d.createElement("script");
        s.async = true;
        s.src = u + "?" + ((Date.now() / 60000) | 0);
        var h = d.getElementsByTagName("script")[0];
        h.parentNode.insertBefore(s, h);
      })(
        window,
        document,
        "https://cdn.bitrix24.in/b24889223/crm/site_button/loader_2_wel32a.js"
      )}
    </div>
  );
};

export default ChatWithUs;
