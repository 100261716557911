import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "../../Css/about.module.css";
import card1img from "../../Images/About/residentialcard1.jpg";
import card2img from "../../Images/About/residentialcard2.jpg";
import card3img from "../../Images/About/residentialcard3.jpg";
import card4img from "../../Images/About/residentialcard4.jpg";
import { Link } from "react-router-dom";
export default function Residential() {
  const residentialData = [
    {
      name: "Residential Apartments",
      description: "Redefining community living",
      imgurl: card1img,
    },
    {
      name: "Independent House",
      description: "Redefining community living",
      imgurl: card2img,
    },
    {
      name: "Builder Floor",
      description: "Redefining community living",
      imgurl: card3img,
    },
    {
      name: "Premium Properties",
      description: "Redefining community living",
      imgurl: card4img,
    },
  ];
  return (
    <div>
      <Grid container className={styles.residential_outerGrid}>
        <Grid lg={12} className={styles.residential_upper_outerGrid}>
          <Typography
            className={`${styles.residential_heading} text-md-start text-center`}
          >
            Residential & Commercial Collection
          </Typography>
          <Typography
            className={`${styles.residential_upper_text} text-md-start text-center`}
          >
            "Bringing Home the Best: Your Trusted Residential Collection
            Partner"
          </Typography>
        </Grid>
        <Grid
          container
          className={`${styles.residential_lower_outerGrid} px-md-0 px-5`}
        >
          {residentialData.map((val, ind) => {
            return (
              <Grid
                lg={3}
                xs={12}
                className={styles.residential_card}
                // sx={{ paddingLeft: 0 }}
              >
                <Link to={`/properties/list/rent`}>
                  <Card
                    style={{ boxShadow: "none" }}
                    className={`position-relative ${styles.residential_block}`}
                  >
                    <img src={val.imgurl} alt="cardimg" />
                    <div className={styles.residential_card_content_wrapper}>
                      <Typography className={styles.residential_card_heading}>
                        {val.name}
                      </Typography>
                      <Typography
                        className={styles.residential_card_upper_text}
                      >
                        {val.description}
                      </Typography>
                    </div>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
