import { Grid, Typography } from "@mui/material";
import React from "react";
import styles from "../../Css/about.module.css";
import imagecard from "../../Images/About/second-sectionimg.png";
export default function SecondSection() {
  return (
    <div>
      <Grid container sx={{ display: "flex", justifyContent: "space-around" }}>
        <Grid
          lg={6}
          // sx={{ paddingX: "2rem", paddingY: "4rem" }}
          className={styles.second_section_contentWrapper_outergrid}
        >
          <Typography
            className={styles.second_section_heading}
            sx={{ marginBottom: "20px" }}
          >
            Finding a home in the big city
          </Typography>
          <Typography variant="p" style={{ color: "#666666" }}>
            Our home-solutions firm, Gharaaj, was established in Indore by Akash
            Sharma,Harsha Sen, Rahul Rao & Other Founding Members. All the
            founders had experienced a common problem – finding a home in the
            big city. Young people faced different kinds of discrimination.
            Single Woman and bachelors are considered unreliable. Migrants from
            other places are viewed with suspicion as they appear as
            ‘foreigners’ in a new city. Further, many newcomers did not have the
            know how to get around, lacking access to local insights in a new
            city.
          </Typography>
          <br />
          <br />
          <Typography variant="p" style={{ color: "#666666" }}>
            We concluded that people leaving homes to relocate to another city
            needed more than just a house. They needed a place they could call
            home, a community where they would be accepted and a platform which
            allows connections to various other access points.
          </Typography>
        </Grid>
        <Grid lg={4} className={styles.second_section_img_outergrid}>
          <img
            src={imagecard}
            className={styles.second_section_img}
            alt="imag"
          />
        </Grid>
      </Grid>
    </div>
  );
}
