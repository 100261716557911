import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import ForgetPassword from "../../Images/ForgotPassword/forgot-password.jpg";
// import "../../CSS/UserSignup/index.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { putApihandler } from "../../Apihandler";
import swal from "sweetalert";

const UpdatePassword = () => {
  const [validated, setValidated] = useState(false);
  const [resetEmail, setResetEmail] = useState();
  console.log("Reset Email is - ", resetEmail);
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const Navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("activatedPage", "properties");
  }, []);

  useEffect(() => {
    setResetEmail(localStorage.getItem("reset-email"));
  }, []);

  // if (resetEmail === undefined || resetEmail == null) {
  //   Navigate("/reset-password");
  // }
  const SubmitNewPassword = async (value) => {
    // console.log("Submitted Value is - ", value);
    let result = await putApihandler(`/resetPassword/${resetEmail}`, value);
    // console.log(" true result is - ", result);

    if (result.status == 200) {
      swal({
        icon: "success",
        text: "Your Password Has Changes Successfully",
      });
      localStorage.clear();
      Navigate("/login");
    } else {
      swal({
        icon: "error",
        title: "Please Try Again",
        text: result.error.response.data.message,
      });
    }
  };

  return (
    <AdminLayout>
      <section className="frgt-pswd-wrapper pt-5 pb-lg-5 pb-0 mt-lg-0 mt-5 mb-lg-5 mb-0">
        <div
          className="forget-img-wrapper text-center mt-lg-0 mt-5"
          style={{ maxWidth: "300px", margin: "auto" }}
        >
          <img src={ForgetPassword} alt="" width="" />
        </div>
        <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
          <div className="form-field-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(SubmitNewPassword)}
              style={{ maxWidth: 300, margin: "auto" }}
            >
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder="Create New Password"
                      aria-describedby="inputGroupPrepend"
                      required
                      style={{ borderColor: "#212529" }}
                      name="password"
                      {...register("password")}
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="password"
                      placeholder="Confirm New Password"
                      aria-describedby="inputGroupPrepend"
                      required
                      style={{ borderColor: "#212529" }}
                      name="confirmPassword"
                      {...register("confirmPassword")}
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Form.Group className="text-center mb-4">
                <Button type="submit" className="bg-dark border-0">
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default UpdatePassword;
