import React, { useEffect } from "react";
import Layout from "../../LayoutWrapper/AdminLayout";
import FaqBanner from "../../Components/FAQ/faqBanner";
import { Link, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Box } from "@mui/material";
import { getApihandler } from "../../Apihandler";
import { useState } from "react";

const FaqList = () => {
  const { Type, Category } = useParams();
  // console.log("Type - ", Type, "Category -", Category);
  const [faqData, setFAQData] = useState();
  useEffect(() => {
    getFAQList();
  }, [Type, Category]);

  const getFAQList = async () => {
    const resp = await getApihandler(
      `/getFaqByTypeAndCategory/${Type}/${Category}`
    );
    // console.log("faq list - ", resp);
    setFAQData(resp.data);
  };

  return (
    <Layout>
      <FaqBanner />
      <Container maxWidth="100%" className="pt-4 mb-4">
        <Box>
          <h2 className="mt-3">{Category}</h2>
        </Box>
        <Box className="faq-list-wrapper my-5 pb-4">
          {faqData !== undefined &&
            faqData !== null &&
            faqData.map((val, ind) => {
              return (
                <li key={ind}>
                  <Link to={`/faq-details/${val._id}`}>{val.title}</Link>
                </li>
              );
            })}
        </Box>
      </Container>
    </Layout>
  );
};

export default FaqList;
