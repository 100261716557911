import React from "react";
import Layout from "../../LayoutWrapper/AdminLayout";
import FaqBanner from "../../Components/FAQ/faqBanner";
import { Box, Grid, Typography } from "@mui/material";
import Tenant from "../../Images/FAQ/tenant.jpg";
import Landlord from "../../Images/FAQ/landlord.jpg";
import { TerminalTwoTone } from "@mui/icons-material";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQ = () => {
  const propertyType = [
    {
      title: "I'm a Tenant",
      imgUrl: TerminalTwoTone,
      url: "/faq/I'm a Tenant",
    },
    { title: "I'm a Landlord", imgUrl: Landlord, url: "/faq/I'm a Landlord" },
  ];
  return (
    <Layout>
      <FaqBanner />
      <Box>
        <h2
          className="text-center mt-5"
          style={{ color: "rgba(31, 64, 105, 1)" }}
        >
          Select your help topic
        </h2>
      </Box>
      <Container maxWidth="100%" className="py-5 mb-5">
        <Grid container className="justify-content-center gap-5">
          {propertyType.map((val, ind) => {
            return (
              <Grid item lg={4} md={5} sm={8} xs={11} key={ind}>
                <Link to={val.url}>
                  <Box className={`faq-type-wrapper faq-type-box-${ind + 1}`}>
                    <span className="faq-type-title">{val.title}</span>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Layout>
  );
};

export default FAQ;
