import { Container } from "@mui/system";
import React from "react";
import Banner from "../../Components/Aboutpage/banner";
import Postproperty from "../../Components/Aboutpage/postproperty";
import Residential from "../../Components/Aboutpage/residential";
import SecondSection from "../../Components/Aboutpage/second-section";
import ThirdSection from "../../Components/Aboutpage/third-section";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function About() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "about");
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Buy or Rent Prime Commercial Spaces in Indore</title>
        <meta
          name="description"
          content="Elevate your business with strategically located commercial spaces in Indore. Explore options to buy or rent that align with your goals."
        />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
      </Helmet>
      <Container maxWidth="xxl" className="p-0">
        <Banner />
        <SecondSection />
        <ThirdSection />
        <Residential />
        <Postproperty />
        <PartnersList />
      </Container>
    </Layout>
  );
}
