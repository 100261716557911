/** @format */

import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import "./index.css";
import { Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import Layout from "../../LayoutWrapper/AdminLayout";
import Rating from "../../Images/Home/Testimonial/testimonial-rating.png";
import { useEffect } from "react";
import { getApihandler } from "../../Apihandler";
import Modal from "@mui/material/Modal";
import Styles from "../../Css/about.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";

const OwnerPlan = () => {
  const [PlansData, setPlansData] = useState([]);
  const [userId, setUserId] = React.useState();
  // console.log("userId - ", userId);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    localStorage.setItem("activatedPage", "owner-plan");
    getPlans();
  }, []);

  // Get Local Storage Data
  React.useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  const getPlans = async () => {
    const response = await getApihandler("/getOwnerPlans/All");
    console.log("getPlans response - ", response);
    setPlansData(response.data);
  };
  // Page Refresh
  function refreshPage() {
    window.location.reload();
  }
  // Handle Page Refresh-------------
  const handleRefresh = () => {
    setTimeout(() => {
      refreshPage();
    }, 200);
  };

  // const PlansData = [
  //   {
  //     planName: "Relax Plan",
  //     planPrice: "999",
  //     // planURL: "https://cosmofeed.com/vp/65250d8e042505001dc6843f",
  //     planURL: "/payment",
  //     planPoints: [
  //       "45 Days Plan Validity",
  //       "Top Slot Listing For 5x More Visibility",
  //       "Property Promotion On Website",
  //       "Relationship Manager (RM) - Super Fast Closure",
  //       "Privacy Of Your Phone",
  //       "Rental Agreement Home Delivered",
  //       "Facebook Marketing Of Your Property",
  //     ],
  //   },
  //   {
  //     planName: "Super Relax",
  //     planPrice: "3500",
  //     // planURL: "https://cosmofeed.com/vp/65250f3dcb7ef5001e93d4fd",
  //     planURL: "/payment",
  //     planPoints: [
  //       "45 Days Plan Validity",
  //       "Personal Field Assistant",
  //       "Property Promotion On Website",
  //       "Top Slot Listing For 5x More Visibility",
  //       "Showing Property On Your Behalf",
  //       "Photoshoot Of Your Property",
  //       "Relationship Manager (RM) - Super Fast Closure",
  //       "Privacy Of Your Phone",
  //       "Rental Agreement Home Delivered",
  //       "Facebook Marketing Of Your Property",
  //     ],
  //   },
  //   {
  //     planName: "MoneyBack",
  //     planPrice: "5500",
  //     // planURL: "https://cosmofeed.com/vp/652510effc8f020028f3598e",
  //     planURL: "/payment",
  //     planPoints: [
  //       "60 Days Plan Validity",
  //       "Guaranteed Tenants Or 100% Moneyback",
  //       "Top Slot Listing For 5x More Visibility",
  //       "Property Promotion On Website",
  //       "Relationship Manager (RM) - Super Fast Closure",
  //       "Privacy Of Your Phone",
  //       "Rental Agreement Home Delivered",
  //     ],
  //   },
  // ];

  return (
    <Layout>
      <section className="owner-plan-wrapper py-5 mt-5 px-lg-5 my-5">
        {/* <Container fluid className="pt-4">
         */}
        <Container maxWidth="100%" className="py-5 px-lg-5">
          <Box className="text-lg-start text-center pt-xxl-5 mt-xxl-5">
            <Typography
              variant="h5"
              component="h4"
              className="smallHeading_TwoHeadings"
              style={{ color: "#222222" }}
            >
              Owners Plan
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            className="justify-content-center mt-lg-3"
          >
            {PlansData.map((val, ind) => {
              return (
                <Grid
                  key={ind}
                  item
                  className="pt-lg-0 pb-lg-0 pt-4 pb-0 px-lg-3 owner-planboxWrapper mb-4"
                  lg={4}
                  sm={7}
                  xs={12}
                >
                  <Card
                    className="mx-auto owner-plan-boxes"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      color: "#fff !important",
                      border: "1px solid #D9D9D9",
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px",
                      padding: "0px 30px 20px",
                      borderRadius: "30px",
                    }}
                  >
                    <CardContent className=" pb-0 px-0">
                      <Typography
                        variant="subtitle1"
                        component="h5"
                        className="owner-plan-title"
                        style={{
                          fontSize: "24px",
                          fontWeight: 500,
                          color: "#1f4069",
                        }}
                      >
                        {val.planName}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="h6"
                        className="text-dark"
                      >
                        ₹{val.amount}
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        className="text-dark mb-4"
                      >
                        <strong>Validity - {val.dayValidity} Days</strong>
                      </Typography>

                      {val.facilities !== undefined &&
                        val.facilities !== null &&
                        val.facilities[0].split(",").map((points, ind) => {
                          return (
                            <Typography
                              variant="body1"
                              component="p"
                              className="text-dark d-flex align-items-start"
                              key={ind}
                            >
                              <span className="pe-2">-</span>{" "}
                              <span>{points}</span>
                            </Typography>
                          );
                        })}
                    </CardContent>
                    <Box className="pt-lg-5 pt-4 pb-2 ">
                      {/* <Link to={`/payment/ownerPayment/${val._id}`}> */}

                      <Button
                        style={{
                          marginBottom: "20px",
                          color: "#00C0FB",
                          fontWeight: 500,
                          textAlign: "center",
                          fontSize: "16px",
                          padding: "10px 15px",
                          color: "#fff",
                          background: "red",
                          borderRadius: "4px",
                          border: "none",
                        }}
                        className="w-100"
                        onClick={handleOpen}
                      >
                        <span>Terms of Service</span>
                      </Button>

                      <Link
                        to={`${
                          userId !== undefined && userId !== null
                            ? `/payment/ownerPayment/${val._id}`
                            : "/signup-login"
                        }`}
                      >
                        <Button
                          style={{
                            color: "#00C0FB",
                            fontWeight: 500,
                            textAlign: "center",
                            fontSize: "16px",
                            padding: "10px 15px",
                            color: "#fff",
                            background: "#02c988 ",
                            borderRadius: "4px",
                            border: "none",
                          }}
                          className="w-100"
                        >
                          <span>Subscribe</span>
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "auto" }}
      >
        <Fade in={open}>
          <Box className={Styles.modal_box}>
            <div style={{ textAlign: "end" }}>
              <CloseIcon onClick={handleClose} />
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              1. Brokerage Fee:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              If the property listed under the "Super Relax" package is rented
              out within 10 days from the date of listing, a brokerage fee
              equivalent to 10 days' rent shall be applicable and charged to the
              property owner.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              2. Zero Brokerage:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              If the property is rented out after the initial 10-day period from
              the date of listing, zero brokerage shall be applied.
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              3. Refund Policy:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              In the event that the property remains unrented by the end of the
              45-day validity period, the property owner must provide proof of
              the rental agreement not being executed. Upon verification, 50% of
              the package fee will be refunded to the property owner.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              4.Documentation:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The property owner is responsible for providing accurate and
              up-to-date information about the property, including necessary
              documentation required for rental agreements.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              5. Responsibility of Showing Property:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The property owner shall cooperate in showing the property to
              potential tenants as required during the validity period of the
              package.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              6. Privacy and Confidentiality:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              All personal information provided by the property owner shall be
              kept confidential and used solely for the purpose of facilitating
              the rental process.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              7. Property Promotion:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The property will be promoted on the company website and through
              Facebook marketing as part of the package benefits.
              <br />
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              8. Photoshoot:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              A professional photoshoot of the property will be conducted for
              promotional purposes, ensuring high-quality images to attract
              potential tenants.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              9. Field Assistant and Relationship Manager:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The property owner will be provided with a Personal Field
              Assistant and a Relationship Manager to ensure a smooth rental
              process and timely closure of agreements.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              10. Validity Period:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The package benefits are valid for a period of 45 days from the
              date of purchase.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              11. Terms of Service:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              By availing of the "Super Relax" package, the property owner
              agrees to abide by these terms and conditions and any other terms
              specified by the service provider.
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 1 }}
            >
              12. Modification of Terms:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              The service provider reserves the right to modify these terms and
              conditions at any time, with or without prior notice.
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
};

export default OwnerPlan;
