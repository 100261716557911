import { Container } from "@mui/system";
import React from "react";
import Banner from "../../Components/Aboutpage/banner";
import Postproperty from "../../Components/Aboutpage/postproperty";
import Residential from "../../Components/Aboutpage/residential";
import SecondSection from "../../Components/Aboutpage/second-section";
import ThirdSection from "../../Components/Aboutpage/third-section";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import AddPropertyCumponent from "../../Components/AddPropertyPage";
import "../../Pages/AddProperty/index.css";
import { useEffect } from "react";

export default function AddProperty() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "add-property");
  }, []);
  return (
    <Layout>
      <Container maxWidth="xxl" className="p-0">
        {/* <Banner /> */}
        <AddPropertyCumponent />
        <Postproperty />
        <PartnersList />
      </Container>
    </Layout>
  );
}
