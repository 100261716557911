import React from "react";
import Banner from "../../Components/Userprofile/banner";
import ProfileDetail from "../../Components/Userprofile/profiledetail";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import { useEffect } from "react";

export default function UserProfile() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "user-profile");
  }, []);
  return (
    <Layout>
      <Banner />
      <ProfileDetail />
      <PartnersList />
    </Layout>
  );
}
