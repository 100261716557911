import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import GharaajLogo from "../../Images/Universal/footer-logo.svg";
import email from "../../Images/Universal/email.svg";
import phone from "../../Images/Universal/phone.svg";
import address from "../../Images/Universal/address.svg";
import facebook from "../../Images/Universal/facebook-Icon.svg";
import linkedin from "../../Images/Universal/linkedin-Icon.svg";
import twitter from "../../Images/Universal/twitter-Icon.svg";
import instagram from "../../Images/Universal/instagram-Icon.svg";
import youtube from "../../Images/Universal/youtube-Icon.svg";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper position-relative">
        <footer className="main-footer py-5">
          <Container>
            <Grid container spacing={2}>
              <Grid item className="pb-2 w-100">
                <div className="footer-logo-wrapper">
                  <img src={GharaajLogo} alt="" />
                </div>
              </Grid>
              <Grid item lg={4} sm={10} className="pe-4">
                <Typography
                  variant="body1"
                  component="p"
                  className="text-white"
                >
                  Gharaaj is india's market leader in “Managed Home Rental
                  Properties” Attempting to provide better rental solutions via
                  design & technology. <br /> <br />
                  We help find,book rental homes of choice across Indian
                  cities.We assist with move-in,provide home main.
                </Typography>
              </Grid>
              <Grid
                item
                lg={2.66}
                sm={3}
                className="mt-lg-0 mt-4 me-lg-0 me-4"
              ></Grid>
              <Grid item lg={2.66} sm={3} className="mt-lg-0 mt-4 me-lg-0 me-4">
                <Typography
                  variant="h6"
                  component="h5"
                  className="font-bold text-white"
                >
                  Support
                </Typography>

                <Link to="/privacy-policy">
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-white pt-3"
                  >
                    Privacy
                  </Typography>
                </Link>
                <Link to="/terms-conditions">
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-white pt-3"
                  >
                    T&Cs
                  </Typography>
                </Link>
                <Link to="/faq">
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-white pt-3"
                  >
                    FAQ
                  </Typography>
                </Link>
                <Link to="/refund-and-return-policy">
                  <Typography
                    variant="body1"
                    component="p"
                    className="text-white pt-3"
                  >
                    Refund & Return Policy
                  </Typography>
                </Link>
              </Grid>
              <Grid item lg={2.66} sm={5} className="mt-lg-0 mt-4">
                <Typography
                  variant="h6"
                  component="h5"
                  className="font-bold text-white"
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className="text-white pt-3"
                >
                  <img src={address} alt="" className="footer-icon" />
                  501 Apollo Premiere,Indore
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className="text-white pt-3"
                >
                  <img src={phone} alt="" className="footer-icon" />
                  <a href="tel:+917999668576" style={{ color: "#fff" }}>
                    +91-7999668576
                  </a>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className="text-white pt-3"
                >
                  <img src={email} alt="" className="footer-icon" />{" "}
                  <a style={{ color: "#fff" }} href="mailto:info@gharaaj.com">
                    info@gharaaj.com
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </footer>
        <footer className="middle-footer py-4 w-100">
          <Container className="py-3">
            <Typography variant="body1" component="p" className="text-white">
              Bangalore | Gurgaon | Hyderabad | Noida | Pune | Delhi | Ghaziabad
              | Greater Noida | Faridabad | Navi Mumbai | Thane | Mumbai |
              Chennai | Mysore | Kota | Dehradun | Bhubaneswar | Kolkata |
              Visakhapatnam | Coimbatore | Chandigarh | Indore | Kochi |
              Ahmedabad | Jaipur | Agra | Mangalore | Vijaywada | Bhopal |
              Lucknow | Patna | Ludhiana | Dharwad | Hubballi | Belagavi |
              Thiruvananthapuram
            </Typography>
          </Container>
        </footer>
        <footer className="bottom-footer">
          <Container className="py-4">
            <Grid container spacing={2} className="justify-content-center">
              <Grid item lg={4} className="">
                <Box className="d-flex justify-content-center align-items-center mb-3">
                  <a href="https://www.facebook.com/profile.php?id=100092454518940&mibextid=LQQJ4d">
                    <img src={facebook} alt="" className="footer-icon" />
                  </a>
                  <a href="https://www.linkedin.com/in/gharaaj-dotcom-865150255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                    <img src={linkedin} alt="" className="footer-icon" />
                  </a>
                  <a href="https://x.com/Gharaaj1?t=ccBAR_IfXF1c84cX4Sws-Q&s=08">
                    <img src={twitter} alt="" className="footer-icon" />
                  </a>
                  <a href="https://www.instagram.com/gharaajproptech4/?igshid=MzRlODBiNWFlZA%3D%3D">
                    <img src={instagram} alt="" className="footer-icon" />
                  </a>
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  className="text-white text-center"
                >
                  © Gharaaj - All rights reserved Managed by{" "}
                  <a
                    href="https://aahantechnologies.com/"
                    style={{ color: "white" }}
                  >
                    Aahan Technologies{" "}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </footer>
      </div>
    </>
  );
};

export default Footer;
