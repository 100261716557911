import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import card1img from "../../Images/About/about-card1.png";
import card2img from "../../Images/About/about-card2.png";
import card3img from "../../Images/About/about-card3.png";
import card4img from "../../Images/About/about-card4.png";
import styles from "../../Css/about.module.css";

export default function ThirdSection() {
  return (
    <div className="px-md-3">
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          // px: 5,
        }}
        className="px-md-5 gap-lg-5 gap-md-4 "
      >
        <Grid lg={5.7} xs={12} className={styles.third_section_card_outergrid}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "solid 1px rgba(217, 217, 217, 1)",
              radius: "0px, 8px, 8px, 0px",
              boxShadow: "none",
            }}
          >
            <div style={{ padding: "1rem" }}>
              <Typography className={styles.third_section_card_heading}>
                Simple
              </Typography>
              <Typography
                variant="p"
                className={styles.third_section_card_text}
              >
                At Gharaaj, we want to make finding homes accessible and simple.
                We want to make living easy by providing additional services and
                furnished homes so that youth can devote their time to their
                aspirations.
              </Typography>
              <br />
              <Typography className={styles.third_section_card_secondtext}>
                View our projects
              </Typography>
            </div>
            <div className={styles.third_section_outerdiv_cardimg}>
              <img
                src={card1img}
                alt="cardimg"
                className={styles.third_section_cardimg}
              />
            </div>
          </Card>
        </Grid>
        <Grid lg={5.7} xs={12} className={styles.third_section_card_outergrid}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "solid 1px rgba(217, 217, 217, 1)",
              radius: "0px, 8px, 8px, 0px",
              boxShadow: "none",
            }}
          >
            <div style={{ padding: "1rem" }}>
              <Typography className={styles.third_section_card_heading}>
                Equitable
              </Typography>
              <Typography
                variant="p"
                className={styles.third_section_card_text}
              >
                We want to address the issues of young migrants who are
                discriminated against for various reasons. We want to bridge the
                gap between youth and the older generation, finding common
                ground and making the relationships mutually beneficial.
              </Typography>
              <br />
              <Typography className={styles.third_section_card_secondtext}>
                View our projects
              </Typography>
            </div>
            <div className={styles.third_section_outerdiv_cardimg}>
              <img
                src={card2img}
                alt="cardimg"
                className={styles.third_section_cardimg}
              />
            </div>
          </Card>
        </Grid>
        <Grid lg={5.7} xs={12} className={styles.third_section_card_outergrid}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "solid 1px rgba(217, 217, 217, 1)",
              radius: "0px, 8px, 8px, 0px",
              boxShadow: "none",
            }}
          >
            <div style={{ padding: "1rem" }}>
              <Typography className={styles.third_section_card_heading}>
                Accessible
              </Typography>
              <Typography
                variant="p"
                className={styles.third_section_card_text}
              >
                We want to make housing affordable and cater to individual
                needs, which greatly vary depending on personality. We wanted to
                remove the middle-man the broker, who often becomes a decider of
                where you will stay rather than cater to your taste.
              </Typography>
              <br />
              <Typography className={styles.third_section_card_secondtext}>
                View our projects
              </Typography>
            </div>
            <div className={styles.third_section_outerdiv_cardimg}>
              <img
                src={card3img}
                alt="cardimg"
                className={styles.third_section_cardimg}
              />
            </div>
          </Card>
        </Grid>
        <Grid lg={5.7} xs={12} className={styles.third_section_card_outergrid}>
          {/* <Card
              sx={{
                display: "flex",
                justifyContent: "space-between",
                border: "solid 1px rgba(217, 217, 217, 1)",
                radius: "0px, 8px, 8px, 0px",
                boxShadow: "none",
              }}
            >
              <div style={{ padding: "1rem" }}>
                <Typography className={styles.third_section_card_heading}>
                  Our Team
                </Typography>
                <Typography
                  variant="p"
                  className={styles.third_section_card_text}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book.
                </Typography>
                <br />
                <Typography className={styles.third_section_card_secondtext}>
                  View our projects
                </Typography>
              </div>
              <div className={styles.third_section_outerdiv_cardimg}>
                <img
                  src={card4img}
                  alt="cardimg"
                  className={styles.third_section_cardimg}
                />
              </div>
            </Card> */}
        </Grid>
      </Grid>
    </div>
  );
}
