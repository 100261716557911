import { Typography } from "@mui/material";
import React from "react";
import styles from "../../Css/about.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import bannerimg from "../../Images/About/banner-about.png";

export default function Banner() {
  return (
    <div className={styles.outer_div_banner}>
      <div className={styles.upper_text_outerdiv_banner}>
        <Typography className={styles.upper_text_banner}>
          Home
          <ChevronRightIcon />
          About
        </Typography>
      </div>
      <div className={styles.heading_text_outerdiv_banner}>
        {/* <Typography className={styles.heading_text_banner} component="h1">
          Your Dreams, Our Deals: Buy, Sell, Prosper!
        </Typography> */}
        <Typography component="h1" className="text-white">
          Your Dreams, Our Deals: Buy, Sell, Prosper!
        </Typography>
      </div>
      <div className={styles.outer_banner_div_img}>
        <img src={bannerimg} className={styles.banner_img} />
      </div>
    </div>
  );
}
