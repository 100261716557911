import { Typography } from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "../../Css/contact.module.css";
import ContactForm from "./contactform";
export default function BannerContact() {
  return (
    <>
      <div className={styles.outer_div_bannercontact}>
        <div className={styles.upper_text_outerdiv_banner}>
          <Typography className={styles.upper_text_banner}>
            Home
            <ChevronRightIcon />
            Contact
          </Typography>
        </div>
        <div className={styles.heading_text_outerdiv_banner}>
          {/* <Typography className={styles.heading_text_banner}>
            Let's Talk About Everything!
          </Typography> */}
          <Typography component="h1" className="text-white">
            Let's Talk About Everything!
          </Typography>
        </div>
        <div className={styles.paragraph_text_outerdiv_banner}>
          <Typography className={styles.paragraph_text_banner}>
            Hello there! If you'd like to ask us something, you can get in touch
            with us here! We'd love to address any and all concerns you may
            have.
          </Typography>
        </div>
        <ContactForm className={styles.paragraph_form_compo} />
      </div>
    </>
  );
}
