import React from "react";
import { Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";

import PerfectChoiceImage from "../../Images/Home/perfect-choice-module-new.svg";

const PerfectChoice = () => {
  const ChoiceData = [
    {
      title: "Zero Brokerage Homes",
      paragraph:
        "Pick a tenant plan starting from 1499 and enjoy 0 Brokerage in every property",
    },
    {
      title: "100% Verified Properties",
      paragraph:
        "With zero broker/owner interference ,every property is checked and verified by gharaaj",
    },
    {
      title: "Every Housing Problem ,Gharaaj is the Solution.",
      paragraph:
        "Rental furniture ,Packers and movers ,maintenance solutions ,EMI Solutions ,we got everything covered",
    },
  ];
  return (
    <section className="perfect-choice py-md-5 py-3">
      <Container maxWidth="xxl" className="py-md-4 py-2">
        <Box className="">
          <Typography
            variant="h5"
            component="h4"
            className="smallHeading_TwoHeadings text-center mb-lg-0  mb-4"
            style={{ color: "#222" }}
          >
            Why Gharaaj Is The Perfect Choice?
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          className="justify-content-between align-items-center  flex-xl-row flex-column flex-lg-nowrap pb-lg-0 pb-lg-5"
        >
          <Grid
            item
            lg={5}
            xs={8}
            className="py-lg-5 py-md-3 d-flex align-items-center"
          >
            <div className="perfect-choice-image pb-lg-0 pb-md-5 pt-md-0">
              <img src={PerfectChoiceImage} alt="" className="" />
            </div>
          </Grid>
          <Grid item lg={6} className="pt-lg-5 pb-lg-5 pt-4 pb-0 mx-lg-3">
            <Box className="perfect-choice-benifits">
              {ChoiceData !== undefined &&
                ChoiceData !== null &&
                ChoiceData.map((val, ind) => {
                  return (
                    <div
                      className="perfect-choice-benifit-wrapper mb-md-4 mb-2 pb-2"
                      key={ind}
                    >
                      <Grid container spacing={2} className="flex-nowrap">
                        <Grid item lg={2}>
                          <Typography
                            variant="h3"
                            component="h4"
                            className="text-center mb-md-4 perfect-choice-points-index"
                            style={{
                              fontWeight: 900,
                              color: "#02C988",
                              opacity: 0.2,
                            }}
                            // style={{ fontWeight: 900, fontSize: "32px" }}
                          >
                            0{ind + 1}
                          </Typography>
                        </Grid>
                        <Grid item lg={9}>
                          {" "}
                          <Typography
                            variant="h4"
                            component="h5"
                            className="mb-md-2 text-start perfect-choice-points-title"
                            style={{
                              fontWeight: 500,
                              fontSize: "32px",
                              color: "#1F4069",
                            }}
                          >
                            {val.title}
                          </Typography>
                          <NavDropdown.Divider
                            style={{
                              background: "#E7E7E7",
                              height: "1px",
                              marginTop: "2px",
                              marginBottom: "6px",
                              width: "90%",
                            }}
                          />
                          <Typography
                            variant="body1"
                            component="p"
                            className=""
                          >
                            {val.paragraph}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PerfectChoice;
