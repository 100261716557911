import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Backdrop,
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import userimg from "../../Images/Universal/user.png";
import styles2 from "../../Css/userprofile.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ShareIcon from "@mui/icons-material/Share";
import styles from "../../Css/property.module.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  deleteApiihandler,
  deleteParamsApiihandler,
  getApihandler,
  imageUrl,
  postApihandler,
} from "../../Apihandler";
import Carousel from "react-bootstrap/Carousel";
import { RWebShare } from "react-web-share";
import Swal from "sweetalert2";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import swal from "sweetalert";

const tabs = [
  { name: "Profile Detail", path: "/user-profile" },
  { name: "Change Password", path: "/change-password" },

  // { name: "Watchlist", path: "/wishlist", color: `#02C988` },
  {
    name: "Recommended Properties",
    path: "/recommended-properties",
  },
  {
    name: "My Properties",
    path: "/my-properties",
  },
  { name: "My Visits", path: "/my-visits" },
  { name: "Track My Thing", path: "/track-my-thing" },
  // { name: "Bank Details", path: "#" },
  // { name: "Setting", path: "#" },
  { name: "Help", path: "/help" },
];

export default function WishlistListComponent() {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userId, setUserId] = useState();
  const [removeStatus, setRemoveStatus] = useState(false);
  const [propertyId, setPropertyId] = useState();
  // console.log("userId", userId);
  // console.log("propertyId", propertyId);

  const [PropertiesList, setPropertiesList] = useState([]);
  // console.log("PropertiesList", PropertiesList);

  // Get Local Storage Data
  useEffect(() => {
    setUserId(localStorage.getItem("UserId"));
  }, []);

  useEffect(() => {
    if (userId !== undefined) {
      getProperties();
    }
  }, [userId]);

  useEffect(() => {
    if (propertyId !== undefined && removeStatus === true) {
      RemoveWishlist();
    }
  }, [propertyId, removeStatus]);

  const getProperties = async () => {
    const response = await getApihandler(`/getWishlist/userId_${userId}`);
    // console.log("Wishlist Property List", response);
    setPropertiesList(response.data);
  };

  const RemoveWishlist = async () => {
    const response = await deleteParamsApiihandler(
      `/removeWishlist/${propertyId}`
    );
    // console.log("RemoveWishlist====>", response);
    if (response.status === 200) {
      setPropertyId();
      Swal.fire({
        position: "middle-centre",
        icon: "success",
        title: "Wishlist Removed",
        showConfirmButton: false,
        timer: 2000,
      });
      getProperties();
      setRemoveStatus(false);
    } else {
      setPropertyId();
      Swal.fire({
        position: "middle-centre",
        icon: "error",
        title: response.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      getProperties();
      setRemoveStatus(false);
    }
  };

  // Visit Popup------x-------------x------------------
  const visitPopupBg = {
    background: "#00000061",
  };

  const visitPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: { lg: "50%" },
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // Check Number Digit Is Smaller Than 1
  function formatNumber(num) {
    // Convert the number to a string
    num = num.toString();

    // Check if the string has only one character
    if (num.length === 1) {
      // Add a leading '0'
      num = "0" + num;
    }

    return num;
  }

  const submitVisitShedule = async (value) => {
    if (
      value !== null &&
      value.$y !== null &&
      value.$M !== null &&
      value.$D !== null &&
      value.$H !== null &&
      value.$H !== 0 &&
      propertyId !== undefined &&
      propertyId !== null
    ) {
      const visitDate =
        value.$y + "-" + formatNumber(value.$M) + "-" + formatNumber(value.$D);
      const visitTime = formatNumber(value.$H) + ":" + formatNumber(value.$m);
      const response = await postApihandler(
        `/bookingForPropertyVisit/${userId}/${propertyId}/`,
        {
          date: visitDate,
          time: visitTime,
        }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Visit Request Has Sent Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      // console.log("catch error is - ");
      Swal.fire({
        icon: "error",
        title: "Please Select Date & Time Both",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly" }}
        className="flex-md-row flex-column"
      >
        <Box
          sx={{ borderRight: "solid 1px #D2D2D2" }}
          className="ps-lg-3 ps-1 userProfile_TabSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-31px",
            }}
          >
            {/* <Avatar
              alt="Remy Sharp"
              src={userimg}
              sx={{ width: 100, height: 100 }}
            /> */}
            <IconButton>
              <AccountCircleIcon
                style={{ color: "rgb(2, 201, 136)", fontSize: "110px" }}
              />
            </IconButton>
          </div>
          <List className="p-lg-5 p-3 d-md-block d-flex flex-wrap">
            {tabs.map((text, index) => (
              <Link
                to={text.path}
                className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab"
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={
                    {
                      // borderBottom: "1px solid #E7E7E7",
                      // marginBottom: "20px",
                    }
                  }
                  className="mb-md-4 mb-1 userProfile_tabInner"
                >
                  <ListItemButton>
                    <ListItemText
                      sx={{ color: `${text.color ? text.color : "#222222"}` }}
                      primary={text.name}
                      className="tab-text"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Link className="me-md-0 me-2 mb-md-0 mb-2 userProfile_tab">
              <ListItem
                disablePadding
                className="mb-md-4 mb-1 userProfile_tabInner"
              >
                <ListItemButton
                  onClick={() => {
                    swal({
                      title: "Are you sure?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        localStorage.clear();
                        swal({
                          icon: "success",
                          text: "You have been logged out!",
                        });
                        Navigate("/");
                        // refreshPage();
                      } else {
                        swal("Your login details are secure!", {
                          icon: "success",
                        });
                      }
                    });
                  }}
                >
                  <ListItemText
                    sx={{ color: "#222222" }}
                    primary="Logout"
                    className="tab-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className="userProfile_BodySection mb-md-5 mb-0"
        >
          <Typography className={styles2.profiledetail_heading}>
            Watchlist
          </Typography>
          <Typography className={styles2.profiledetail_text_paragraph}>
            See history of your payment plan invoice.
          </Typography>
          <Grid lg={12} xs={12} sx={{ paddingY: "2rem" }} className="px-md-2">
            {PropertiesList !== undefined &&
            (PropertiesList !== null) & (PropertiesList.length !== 0) ? (
              PropertiesList.map((val, ind) => {
                return (
                  <Card
                    key={ind}
                    sx={{
                      border: "1px solid #D2D2D2",
                      padding: "5px",
                      display: "flex",
                      boxShadow: "none",
                    }}
                    className="mb-4"
                  >
                    <Grid container>
                      <Grid lg={4} xs={12}>
                        <div className="property-image-slider-wrapper">
                          <Carousel>
                            {val.propertyImages.map((imgsrc, i) => {
                              return (
                                <Carousel.Item interval={3000 + ind * 1000}>
                                  <img
                                    src={`${imageUrl + imgsrc.images}`}
                                    alt=""
                                    style={{
                                      height: "19rem",
                                      borderRadius: "6px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    className="property-image-overlay position-absolute"
                                    style={{
                                      height: "19rem",
                                      borderRadius: "6px",
                                      width: "100%",
                                      top: 0,
                                      background: "#0000007a",
                                    }}
                                  ></div>
                                  <div
                                    className="property-type position-absolute"
                                    style={{
                                      top: "2%",
                                      left: "2%",
                                      zIndex: "1",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#fff",
                                        color: "#222",
                                        textTransform: "capitalize",
                                        paddingTop: ".3rem",
                                        paddingBottom: ".3rem",
                                        fontSize: "16px",
                                      }}
                                      className="px-2"
                                    >
                                      {val.available_for[0]}
                                    </Button>
                                  </div>
                                  <div
                                    className={`premiumPropertyCarousel position-absolute d-${
                                      val.price > 50000 ? "block" : "none"
                                    }`}
                                    style={{
                                      top: "2%",
                                      right: "2%",
                                      zIndex: "1",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#EA3A91",
                                        color: "#fff",
                                        textTransform: "capitalize",
                                        paddingTop: ".3rem",
                                        paddingBottom: ".3rem",
                                        fontSize: "16px",
                                      }}
                                      className="px-2"
                                    >
                                      Premium
                                    </Button>
                                  </div>
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        </div>
                      </Grid>
                      <Grid lg={8} xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "1rem",
                          }}
                          className="flex-md-row flex-column  pt-md-0 pt-4 pb-4 px-md-2"
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "flex-end",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "24px", fontWeight: 500 }}
                            >
                              <CurrencyRupeeIcon sx={{ marginTop: "-5px" }} />
                              {val.price}/-
                            </Typography>
                            {/* <Typography
                                  sx={{ color: "#000", marginRight: "5px" }}
                                >
                                  bed
                                </Typography> */}
                            <Typography
                              sx={{
                                color: "rgba(2, 201, 136, 1)",
                                textDecoration: "underline",
                              }}
                            >
                              agreement details
                            </Typography>
                          </div>
                          <div className="mt-md-0 mt-4">
                            <RWebShare
                              data={{
                                imageUrl:
                                  "https://www.istockphoto.com/photo/beautiful-sunrise-bursting-through-the-eucalyptus-trees-as-it-rises-over-a-mountain-gm1455965102-491142197?utm_source=pixabay&utm_medium=affiliate&utm_campaign=SRP_image_sponsored&utm_content=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Fnature%2F&utm_term=nature",
                                text: "Web Share - Share Property",
                                url: `http://gharaaj.com/#/property-details/${val.propertyId}`,
                                title: "Share Property",
                              }}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <IconButton sx={{ mr: 4 }}>
                                <ShareIcon
                                  sx={{
                                    color: "#666666",
                                    cursor: "pointer",
                                  }}
                                />
                              </IconButton>
                            </RWebShare>
                            <IconButton
                              onClick={() => {
                                setPropertyId(val._id);
                                setRemoveStatus(true);
                              }}
                            >
                              <FavoriteIcon
                                sx={{
                                  color: "rgba(2, 201, 136, 1)",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </div>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "500",
                              lineHeight: "28.9px",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Link
                              to={`/property-details/${val.propertyId}`}
                              style={{ color: "#222222" }}
                            >
                              {val.propertyName}
                            </Link>
                          </Typography>
                        </div>
                        <div>
                          <Grid
                            container
                            className="py-2 mx-3"
                            sx={{ borderBottom: "1px solid #D2D2D2" }}
                          >
                            <Grid sm={4} xs={6} className="mb-md-0 mb-2">
                              <Card
                                sx={{
                                  padding: "15px",
                                  textAlign: "center",
                                  boxShadow: "none",
                                  borderRight: "1px solid #DEDEDE",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: 500 }}
                                  className="text-md-center text-start"
                                >
                                  Location
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "16px" }}
                                  className="text-md-center text-start"
                                >
                                  {val.address}, {val.city}
                                </Typography>
                              </Card>
                            </Grid>
                            {/* <Grid sm={3} xs={6} className="mb-md-0 mb-2">
                                  <Card
                                    sx={{
                                      padding: "15px",
                                      textAlign: "center",
                                      boxShadow: "none",
                                      borderRight: "1px solid #DEDEDE",
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: "16px" }}
                                      className="text-md-center text-start"
                                    >
                                      Deposite
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: "16px" }}
                                      className="text-md-center text-start"
                                    >
                                      {val.deposite_money}
                                    </Typography>
                                  </Card>
                                </Grid> */}
                            <Grid sm={4} xs={6} className="mb-md-0 mb-2">
                              <Card
                                sx={{
                                  padding: "15px",
                                  textAlign: "center",
                                  boxShadow: "none",
                                  borderRight: "1px solid #DEDEDE",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: 500 }}
                                  className="text-md-center text-start"
                                >
                                  Property Type
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "16px" }}
                                  className="text-md-center text-start"
                                >
                                  {val.propertyType}
                                </Typography>
                              </Card>
                            </Grid>
                            <Grid sm={4} xs={6} className="mb-md-0 mb-2">
                              <Card
                                sx={{
                                  padding: "15px",
                                  textAlign: "center",
                                  boxShadow: "none",
                                  borderRight: "1px solid #DEDEDE",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: 500 }}
                                  className="text-md-center text-start"
                                >
                                  BHK
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "16px" }}
                                  className="text-md-center text-start"
                                >
                                  {val.bhk_of_house}
                                </Typography>
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid container className="py-2">
                            <Grid lg={7} xs={12} sx={{ padding: "1rem" }}>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                  mb: 3,
                                }}
                              >
                                <span
                                  style={{
                                    border: "1px solid #D2D2D2",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  Managed By Gharaaj
                                </span>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                }}
                              >
                                House ID: GA32444
                              </Typography>
                            </Grid>
                            <Grid lg={5} xs={6} sx={{ padding: "1rem" }}>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "rgba(2, 201, 136, 1)",
                                  color: "#FFF",
                                  textTransform: "capitalize",
                                  paddingTop: ".6rem",
                                  paddingBottom: ".6rem",
                                }}
                                className="px-md-5 px-3"
                                onClick={() => {
                                  if (userId !== undefined && userId !== null) {
                                    handleOpen();
                                    setPropertyId(val.propertyId);
                                  } else {
                                    handleClose();
                                    Swal.fire({
                                      icon: "error",
                                      title: "Please Login Your Account",
                                      showConfirmButton: false,
                                      timer: 2000,
                                    });
                                  }
                                }}
                              >
                                Visit For{" "}
                                <span
                                  style={{
                                    color: "rgba(31, 64, 105, 1)",
                                    fontSize: "18px",
                                  }}
                                  className="different-font"
                                >
                                  {" "}
                                  Free
                                </span>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
            ) : (
              <p className="px-3 py-5 font-bold text-center w-100">
                No Result Found
              </p>
            )}
          </Grid>
        </Box>
      </Box>

      {/* Visit Popup */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={visitPopupBg}
      >
        <Fade in={open}>
          <Box sx={visitPopupStyle}>
            <div className="model-header wrapper d-flex justify-content-between align-content-center">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Book Visit Schedule
              </Typography>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h4"
                className="closeModel"
              >
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                  X
                </span>
              </Typography>
            </div>
            <Typography id="transition-modal-description">
              Pick a time for a Free assisted house visit
            </Typography>
            <div className="d-md-block d-none">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateTimePicker
                  orientation="landscape"
                  onAccept={(value) => {
                    submitVisitShedule(value);
                    handleClose();
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="d-md-none d-block">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["StaticDateTimePicker"]}>
                  <DemoItem label="Static variant">
                    <StaticDateTimePicker
                      // defaultValue={dayjs("2022-04-17T15:30")}
                      onAccept={(value) => {
                        submitVisitShedule(value);
                        handleClose();
                        // console.log("Mobile Visit Time Submitted", value);
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
