import React, { useContext } from "react";
import Banner from "../../Components/Properties/banner";
import BedCard from "../../Components/Properties/BedCard";
import Layout from "../../LayoutWrapper/AdminLayout";
import PartnersList from "../../Components/Aboutpage/PartnersList";
import Postproperty from "../../Components/Aboutpage/postproperty";
import HeaderContext from "../../LayoutWrapper/ContextWrapper/context";
import { useEffect } from "react";

export default function Property() {
  useEffect(() => {
    localStorage.setItem("activatedPage", "properties");
  }, []);
  return (
    <Layout>
      <Banner />
      <BedCard />
      <Postproperty />
      <PartnersList />
    </Layout>
  );
}
