import React from "react";
import FaqBanner from "../../Components/FAQ/faqBanner";
import Layout from "../../LayoutWrapper/AdminLayout";
import Find from "../../Images/FAQ/find-property.svg";
import Visits from "../../Images/FAQ/visits.svg";
import Proposals from "../../Images/FAQ/proposals.svg";
import Documentation from "../../Images/FAQ/documentation.svg";
import Payment from "../../Images/FAQ/payment.svg";
import { Box, Grid } from "@mui/material";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const TenantFAQ = () => {
  const TenantCategpries = [
    {
      categoryIcon: Find,
      categoryTitle: "Find a Property",
      categpryDesc:
        "How it works, Getting Started, Finding your perfect property",
      url: "/faq-list/I'm a Tenant/Find a Property",
    },
    {
      categoryIcon: Visits,
      categoryTitle: "Visits",
      categpryDesc:
        "How to visit your selected properties & everything involved",
      url: "/faq-list/I'm a Tenant/Visits",
    },
    {
      categoryIcon: Proposals,
      categoryTitle: "Proposals",
      categpryDesc: "Proposal updates, Lease related queries, Negotiations",
      url: "/faq-list/I'm a Tenant/Proposals",
    },
    {
      categoryIcon: Documentation,
      categoryTitle: "Documentation",
      categpryDesc: "Rental agreement, Biometrics registation",
      url: "/faq-list/I'm a Tenant/Documentation",
    },
    {
      categoryIcon: Payment,
      categoryTitle: "Bill Payment",
      categpryDesc: "Payments, Rent financials related queries",
      url: "/faq-list/I'm a Tenant/Bill Payment",
    },
  ];
  return (
    <>
      <Layout>
        <FaqBanner />
        <Container maxWidth="100%" className="pt-4 mb-4">
          <Box>
            <h2 className=" mt-3">For Tenants</h2>
            <h5 style={{ fontWeight: 400 }}>
              Learn how to browse properties, schedule a visit and move-in.
            </h5>
          </Box>
        </Container>
        <Container maxWidth="100%" className="py-5 mb-5">
          <Grid
            container
            className="justify-content-md-start justify-content-center gap-5"
          >
            {TenantCategpries.map((val, ind) => {
              return (
                <Grid item lg={3.6} sm={5.5} xs={11} key={ind}>
                  <Link to={val.url}>
                    <Box className={`faq-category-wrapper text-center`}>
                      <Box className="faq-category-icon-wrapper mb-5 mx-auto">
                        <img src={val.categoryIcon} alt="category" />
                      </Box>
                      <h3 className="faq-category-title mb-3">
                        {val.categoryTitle}
                      </h3>
                      <h5
                        style={{ fontWeight: 400 }}
                        className="faq-category-desc"
                      >
                        {val.categpryDesc}
                      </h5>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

export default TenantFAQ;
