import React from "react";
import { Button, Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import { Box, Grid, Typography } from "@mui/material";
import FreeConsultantImg from "../../Images/Home/refer-earn-module-new.svg";
import { Link } from "react-router-dom";

const ReferEarn = () => {
  return (
    <section className="refer-earn py-lg-5 py-md-4 py-2 ">
      <Container maxWidth="100%" className="py-md-4 py-2">
        <Grid
          container
          spacing={2}
          className="justify-content-lg-start justify-content-center flex-lg-nowrap"
        >
          <Grid
            item
            sm={6}
            xs={7}
            className="py-0 d-flex align-items-center  pe-lg-5 mx-lg-0 mx-md-auto"
          >
            <Box className="text-lg-start text-center">
              <Typography
                variant="h5"
                component="h4"
                className="smallHeading_TwoHeadings mb-2 text-white"
              >
                Refer and Earn
              </Typography>
              {/* <Typography
                variant="body1"
                component="p"
                className="pb-2 text-white"
                accordion
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Typography> */}
              <Box className="refer-earn-btn-wrapper mt-4 d-flex align-items-center justify-content-xl-start justify-content-md-center flex-wrap">
                <Link to="/refer-earn">
                  <Button className="refer-earn-btn d-flex align-items-center border-0">
                    Know More
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xs={5}
            className="pt-lg-0 pt-md-5 pb-0 ms-lg-5 m-lg-0 m-auto"
          >
            <Box className="refer-earn-benifits">
              <img src={FreeConsultantImg} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ReferEarn;
