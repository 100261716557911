import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import ForgetPassword from "../../Images/ForgotPassword/forgot-password.jpg";
// import "../../CSS/UserSignup/index.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postApihandler } from "../../Apihandler";
import swal from "sweetalert";
import { useEffect } from "react";

const ResetPassword = () => {
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const Navigate = useNavigate();

  const SubmitEmail = async (value) => {
    // console.log("Submitted Value is - ", value);
    const { userEmail } = value;

    let result = await postApihandler("/sendResetPasswordMail", value);
    // console.log(" true result is - ", result);

    if (result.status == 200) {
      localStorage.setItem("reset-email", userEmail);
      swal({
        icon: "success",
        text: "Please Check Your Mail!",
      });
      // Navigate("/update-password");
    } else {
      swal({
        icon: "error",
        title: "Please Try Again",
        text: result.error.response.data.message,
      });
    }
  };
  useEffect(() => {
    localStorage.setItem("activatedPage", "properties");
  }, []);

  return (
    <AdminLayout>
      <section className="frgt-pswd-wrapper pt-5 pb-lg-5 pb-0 mt-lg-0 mt-5 mb-lg-5 mb-0">
        <div
          className="forget-img-wrapper text-center mt-lg-0 mt-5"
          style={{ maxWidth: "300px", margin: "auto" }}
        >
          <img src={ForgetPassword} alt="" width="" />
        </div>
        <p className="paragraph text-center">
          No worries, Just fill in your email & we’ll send you a link to reset
          your password
        </p>
        <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
          <div className="form-field-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(SubmitEmail)}
              style={{ maxWidth: 300, margin: "auto" }}
            >
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      aria-describedby="inputGroupPrepend"
                      required
                      style={{ borderColor: "#212529" }}
                      name="userEmail"
                      // onChange={(e) => {
                      //   setEmailData(e.target - value);
                      // }}
                      {...register("userEmail")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill a email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Form.Group className="text-center mb-4">
                <Button type="submit" className="bg-dark border-0">
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default ResetPassword;
