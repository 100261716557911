import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyARSnKNGXf1-KfBVvBfW-bcECNCmaJL2Bg",
  authDomain: "gharaaj-abef7.firebaseapp.com",
  projectId: "gharaaj-abef7",
  storageBucket: "gharaaj-abef7.appspot.com",
  messagingSenderId: "136271646849",
  appId: "1:136271646849:web:23326bf2f093d615effd80",
  measurementId: "G-2FCWV1W9XY",
};

// Initialize Firebase
const fbAuthProvider = new FacebookAuthProvider();
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider };
export const FacebookAuth = async () => {
  const fbAuth = signInWithPopup(auth, fbAuthProvider);
  return fbAuth;
};
