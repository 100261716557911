// header.jsx
import React, { useState, createContext, useContext, useEffect } from "react";
import HeaderContext from "./context";
import { Box, Button, IconButton, MenuItem, Select } from "@mui/material";
import { getApihandler } from "../../Apihandler";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import Service from "../../Images/Universal/customer-service.svg";
import HomeImg from "../../Images/Universal/home.svg";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import { Link } from "react-router-dom";

const HeaderWrapper = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [citiesData, setCitiesData] = useState([]);
  // console.log("citiesData - ", citiesData);

  useEffect(() => {
    if (selectedValue !== "" && selectedValue !== null) {
      localStorage.setItem("cityName", selectedValue);
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(localStorage.getItem("cityName"));
  }, []);

  useEffect(() => {
    getCities();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getCities = async () => {
    const response = await getApihandler(`/getAllCities`);
    // console.log("Wishlist Property List", response);
    setCitiesData(response.data);
  };

  // const citiesData = ["Indore", "Bhopal", "Khargone"];

  return (
    <HeaderContext.Provider value={{ selectedValue }}>
      <Navbar className="above-header px-md-5 bg-dark py-0 fixed-top">
        <Container fluid>
          <Box className="city-selector d-flex align-items-center">
            <IconButton className="p-0">
              <Image src={HomeImg} />
            </IconButton>
            <Select
              labelId="custom-select-label"
              id="custom-select"
              value={selectedValue}
              // onChange={props.onchange}
              onChange={(e) => handleSelectChange(e)}
              displayEmpty
              renderValue={(value) => (value !== null ? value : "Select City")}
              className="selectCity"
              style={{
                color: "#fff",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {/* <MenuItem>
            <em>City...</em>
          </MenuItem> */}
              {citiesData !== undefined &&
                citiesData !== null &&
                citiesData.map((val, ind) => {
                  return (
                    <MenuItem value={val} key={ind}>
                      {val}
                    </MenuItem>
                  );
                })}
            </Select>
          </Box>
          <Nav className="d-lg-flex flex-row align-items-center above-header-details">
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <IconButton className="p-0" {...bindTrigger(popupState)}>
                    <img src={Service} alt="" style={{ maxWidth: "24px" }} />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {" "}
                    <Typography className="p-3 fs-6 fw-semibold">
                      Contact Us
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "1rem",
                      }}
                    >
                      <div>
                        <CallIcon />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <p className="fw-light mb-0">Call us | 9 AM to 8 PM </p>
                        <Typography>+91-9429691949</Typography>
                      </div>
                    </div>
                    <div className="px-5 py-3">
                      <Nav.Link href="https://b24-por2xe.bitrix24.site/crm_form_mc6mm/">
                        <Button
                          variant="outlined"
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "700",
                          }}
                        >
                          <PhoneCallbackIcon /> &nbsp;&nbsp;Request a Call Back
                        </Button>
                      </Nav.Link>
                    </div>
                  </Popover>
                </div>
              )}
            </PopupState>

            {/* hover popup contact */}

            <Nav className="nav-link d-block text-white">
              <a href="tel:+919429691949" style={{ color: "#fff" }}>
                +91-9429691949
              </a>{" "}
              |{" "}
              <a style={{ color: "#02C988" }} href="mailto:info@gharaaj.com">
                info@gharaaj.com
              </a>
            </Nav>
          </Nav>
        </Container>
      </Navbar>
      <Box className="body-contents">{props.children}</Box>
    </HeaderContext.Provider>
  );
};
export default HeaderWrapper;
