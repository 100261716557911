import React from "react";
import { Button, Container, NavDropdown } from "react-bootstrap";
import "../../Pages/Home/home.css";
import styles from "../../Css/about.module.css";

import { Box, Grid, Typography } from "@mui/material";
import AudioConsultant from "../../Images/Home/audio-consultant.svg";
import VideoConsultant from "../../Images/Home/video-consultant.svg";
import MeetConsultant from "../../Images/Home/meet-consultant.svg";
import { Link } from "react-router-dom";

const NoBroker = () => {
  const BuyServices = [
    "Gharaaj Services",
    "Zero Broke",
    "Interiors",
    "Sale Agreement",
    "NoBroker For NRI’s",
    "New Builder",
    "New Builder Project",
    "Home Loan",
    "Paper Transfer",
    "Buy/Sell Property Question",
    "Apply Home loan",
    "Pre Approved Home Loan",
  ];
  const RentServices = [
    "Gharaaj Services",
    "Zero Broke",
    "Interiors",
    "Sale Agreement",
    "NoBroker For NRI’s",
    "New Builder",
    "New Builder Project",
    "Home Loan",
    "Paper Transfer",
    "Buy/Sell Property Question",
    "Apply Home loan",
    "Pre Approved Home Loan",
  ];
  return (
    <section className="no-broker py-md-5 py-3">
      <Container maxWidth="100%" className="">
        <Grid
          container
          spacing={2}
          className="justify-content-center flex-lg-row flex-column flex-lg-nowrap pe-lg-5"
        >
          <Grid item lg={12} className=" pe-lg-5">
            <Box className="text-lg-start text-center  mt-xxl-4">
              <Typography
                variant="h5"
                component="h4"
                className="smallHeading_TwoHeadings"
                style={{ color: "#222222" }}
              >
                No Broker Services
              </Typography>
              {/* <Typography
                variant="body1"
                component="p"
                className="pb-4"
                accordion
                style={{ color: "#222222" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography> */}
            </Box>
            <Grid container className="mt-2">
              <Grid item lg={6}>
                <Typography
                  variant="body1"
                  component="h6"
                  className="mb-3"
                  style={{ color: "#222222" }}
                >
                  Buy
                </Typography>
                <Box className="services-wrapper d-flex align-items-center justify-content-start flex-wrap">
                  {BuyServices.map((val, ind) => {
                    return (
                      <Typography
                        key={ind}
                        variant="body1"
                        component="p"
                        className="p-2 m-2 ms-0 mt-0"
                        style={{
                          color: "#222222",
                          background: "#F1F1F1",
                          borderRadius: "3px",
                        }}
                      >
                        {val}
                      </Typography>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item lg={6} className="mt-lg-0 mt-4">
                <Typography
                  variant="body1"
                  component="h6"
                  className="mb-3"
                  style={{ color: "#222222" }}
                >
                  Rent
                </Typography>
                <Box className="services-wrapper d-flex align-items-center justify-content-start flex-wrap">
                  {RentServices.map((val, ind) => {
                    return (
                      <Typography
                        key={ind}
                        variant="body1"
                        component="p"
                        className="p-2 m-2 ms-0 mt-0"
                        style={{
                          color: "#222222",
                          background: "#F1F1F1",
                          borderRadius: "3px",
                        }}
                      >
                        {val}
                      </Typography>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default NoBroker;
