import React from "react";
import { Helmet } from "react-helmet";
// import PropertyImg from "../../Images/About/banner-about.png";

import { imageUrl } from "../../Apihandler";

const Test = () => {
  const pageTitle = "My Property";
  const imageUrl =
    "https://gharaaj.com/static/media/banner-about.023b4de26afec5a658ae.png";
  const pageUrl =
    "https://gharaaj.com/#/property-details/6586a7df7a5f6015c377413f";

  //   const handleWhatsAppShare = () => {
  //     const shareText = `Check out this property: ${document.title}`;
  //     const shareUrl = `https://wa.me/?text=${encodeURIComponent(
  //       shareText + "\n" + PropertyImg
  //     )}`;
  //     window.location.href = shareUrl;
  //   };
  const handleWhatsAppShare = () => {
    const shareText = `Check out this property: ${document.title}`;
    // const shareUrl = `https://wa.me?text=${encodeURIComponent(
    //   shareText + "\n" + pageUrl
    // )}`;

    const shareUrl = `https://wa.me?text=${encodeURIComponent(
      shareText + "\n" + pageUrl
    )}%20image:image=${encodeURIComponent(imageUrl)}`;
    window.location.href = shareUrl;
  };

  return (
    <div className="mt-5">
      <Helmet>
        <title>{pageTitle}</title>
        <meta charset="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="title" content={pageTitle} />
        <meta name="description" content={""} />
        <link rel="canonical" href={"https://gharaaj.com"} />
        {/* OpenGraph tags */}
        <meta property="og:url" content={"https://gharaaj.com"} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={""} />
        <meta property="og:image" content={imageUrl} />
        <img src={imageUrl} alt="Your Image" />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content={imageUrl} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={""} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <h1>{pageTitle}</h1>
      <img src={imageUrl} alt="Property" />
      <button onClick={handleWhatsAppShare}>Share on WhatsApp</button>
    </div>
  );
};

export default Test;
